import React, { useCallback, useEffect, useState } from 'react';
import ModalNormal from 'components/common/modal';

const ModalUserManual: React.FunctionComponent = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const handleClick = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);
  useEffect(() => {
    const userManual = document.querySelector('.user-manual');
    if (userManual) {
      userManual.addEventListener('click', handleClick);
    }
    return () => {
      userManual?.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
  return (
    <ModalNormal visible={visible} setVisible={setVisible} title="">
      <div>Component</div>
    </ModalNormal>
  );
};

export default ModalUserManual;
