const validateMaxLength = (max: number, value: string) => {
  if (value.length === 0) return 'createPost.required';

  if (max && value.length > max) return value.length > max ? 'createGroup.errorOverMax' : '';

  return '';
};

const validateRegex = (value: string, regex?: RegExp) => {
  if (value?.length > 0 && !regex?.test(value)) return 'admin.validateError';
  return '';
};

export { validateMaxLength, validateRegex };
