/* eslint-disable react-hooks/exhaustive-deps */
import { Col, FormInstance, Row } from 'antd';
import { sendGet } from 'api/axios';
import { images } from 'assets/images-base';
import { isEmpty } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export interface ICategorySelected {
  id: number;
  name: string;
  parentId?: number;
  parent_id?: number;
}
interface IParamsCategory {
  page: number;
  per_page: number;
  top?: number;
  group_id?: number;
}

interface ICategoryItem {
  created_at: Date;
  id: number;
  name: string;
  sub_category_quantity: number;
}

interface IProps {
  form: FormInstance<any>;
  informationData?: any;
  categoriesIds?: { categoriesIds: number[]; onSetCategoriesIds: (value: number[]) => void };
}

interface ISubcategoryItem {
  created_at?: Date;
  id: number;
  name: string;
}
const handleSetMultiSubCategoryState = async (categories: ICategorySelected[], cb: (value: any) => void) => {
  const data = await Promise.all(
    categories?.map(async (item: ICategorySelected) => {
      const result = await sendGet(`/category/${item?.id}`);
      return { name: item?.name, sub_categories: result?.data?.sub_categories, id: item?.id };
    })
  );

  cb(data);
};

const FilterByCategory: React.ForwardRefRenderFunction<React.ReactNode, IProps> = ({ form, categoriesIds }) => {
  const param = useParams();
  const { t } = useTranslation();
  const allCategory = { id: 0, name: t('home.All') };
  // const topTopic =
  //   location.pathname.includes('/user-manage-group') ||
  //   location.pathname.includes('/group-joining') ||
  //   location.pathname.includes('/group/classification')
  //     ? ETopCategory.TOP_IN_GROUP
  //     : location.pathname.includes('/group')
  //     ? ETopCategory.TOP_IN_ALL_GROUPS
  //     : ETopCategory.TOP_IN_ALL_POSTS;
  const [categorySelected, setCategorySelected] = useState<ICategorySelected[]>([allCategory]);
  const [subCategorySelected, setSubCategorySelected] = useState<ICategorySelected[]>([]);
  const [params, setParams] = useState<IParamsCategory>({
    page: 1,
    per_page: 20,
    top: 1,
    group_id: param?.id ? Number(param?.id) : undefined,
  });

  const [paramsSub, setParamsSub] = useState<IParamsCategory>({ page: 1, per_page: 1000 });
  const [categoryIdSelected, setCategoryIdSelected] = useState<ICategoryItem | null>(null);
  const refListItemCategory = useRef<HTMLDivElement>(null);
  const refItemSubCategory = useRef<HTMLDivElement>(null);
  const refListSubItemCategory = useRef<HTMLDivElement>(null);
  const [listCategories, setListCategories] = useState<any[]>([]);

  const [subCategoriesState, setSubCategoriesState] = useState<
    { sub_categories: ISubcategoryItem[]; name: string | undefined; id: number | undefined }[]
  >([]);

  const { data } = useQuery(
    ['list-category', params],
    async () => {
      const res = await sendGet('/category/list', params);
      return res?.data;
    },
    { cacheTime: 720000000 }
  );
  const { categories: listCategory, total } = data || { categories: null, total: 0 };
  const { data: listSubCategory } = useQuery(
    [`list-sub-category-${categoryIdSelected?.id}`, paramsSub],
    async () => {
      const res = await sendGet(`/category/${categoryIdSelected?.id}`, paramsSub);
      return res?.data;
    },
    { enabled: Boolean(categoryIdSelected) }
  );

  useEffect(() => {
    if (!isEmpty(listSubCategory)) {
      setSubCategoriesState((_data) => [
        ..._data,
        { name: categoryIdSelected?.name, sub_categories: listSubCategory?.sub_categories, id: categoryIdSelected?.id },
      ]);
      // setSubCategorySelected([...listSubCategory?.sub_categories, ...subCategorySelected]);
      // form.setFieldsValue({
      //   category_id: [...categorySelected, ...listSubCategory?.sub_categories, ...subCategorySelected],
      // });
    }
  }, [listSubCategory, categoryIdSelected]);

  const { totalSub } = listSubCategory || { sub_categories: null, total: 0 };
  const handleSelectedCategory = useCallback(
    (item, _categorySelected, _subCategorySelected, _subCategoriesState) => {
      if (item?.id === allCategory.id) {
        setCategorySelected([item]);
        setSubCategorySelected([]);
        setSubCategoriesState([]);
        form.setFieldsValue({ category_id: [item] });
        return;
      }
      const itemNotExists = !_categorySelected.find((_item: ICategorySelected) => _item.id === item.id);
      if (itemNotExists) {
        _categorySelected.push(item);
        _categorySelected = _categorySelected?.filter((_item: ICategorySelected) => _item?.id !== allCategory.id);
        setCategoryIdSelected(item);
      } else {
        const index: number = _categorySelected.findIndex((_item: ICategorySelected) => _item.id === item.id);

        if (index !== -1) {
          const indexSub: number = _subCategoriesState.findIndex(
            (_item: { sub_categories: ISubcategoryItem[]; name: string | undefined; id: number | undefined }) =>
              _item?.id === _categorySelected[index]?.id
          );

          if (indexSub !== -1) {
            const listId: number[] = _subCategoriesState[indexSub].sub_categories?.map(
              (_item: ISubcategoryItem) => _item?.id
            );
            const newSubCategorySelected = _subCategorySelected.filter(
              (_item: ICategorySelected) => listId.indexOf(_item.id) === -1
            );
            setSubCategorySelected(newSubCategorySelected);
          }
        }

        setSubCategoriesState((_data) => _data.filter((_item: any) => _item.id !== item.id));
        _categorySelected.splice(index, 1);
        setCategoryIdSelected(null);
      }
      if (_categorySelected.length === 0) {
        _categorySelected = [allCategory];
        form.setFields([{ name: 'category_id' }]);
      }
      setCategorySelected([..._categorySelected]);
      form.setFieldsValue({ category_id: [..._categorySelected] });
    },
    [form]
  );

  const handleSelectedSubCategory = useCallback(
    (item, _subCategorySelected) => {
      const itemNotExists = !_subCategorySelected.find((_item: ICategorySelected) => _item.id === item.id);
      if (itemNotExists) {
        _subCategorySelected.push({ ...item, parentId: categoryIdSelected?.id });
      } else {
        const index: number = _subCategorySelected.findIndex((_item: ICategorySelected) => _item.id === item.id);
        _subCategorySelected.splice(index, 1);
      }
      setSubCategorySelected([..._subCategorySelected]);
      form.setFieldsValue({ category_id: [...categorySelected, ..._subCategorySelected] });
    },
    [form, categorySelected, categoryIdSelected]
  );

  const activeSelectedCategory = useCallback((item: ICategorySelected, _categorySelected) => {
    return typeof _categorySelected.find((_item: ICategorySelected) => _item.id === item.id) === 'object';
  }, []);

  useEffect(() => {
    if (refListItemCategory.current) {
      const itemCategory: any = document.querySelector('.modal-main-topic-list-item-parent[data-index="7"]');
      if (itemCategory) {
        refListItemCategory.current.style.maxHeight =
          (itemCategory?.offsetTop || 0) + (itemCategory?.scrollHeight || 0) + 'px';
      }
    }

    if (listCategory) {
      setListCategories((dataCategories) => [...dataCategories, ...listCategory]);
    }
  }, [listCategory]);

  useEffect(() => {
    if (refItemSubCategory.current && refListSubItemCategory.current) {
      const itemSubCategory: any = document.querySelector('.modal-main-sub-topic-list-item-parent[data-index="7"]');
      if (itemSubCategory) {
        refListSubItemCategory.current.style.maxHeight =
          (itemSubCategory?.offsetTop || 0) + (itemSubCategory?.scrollHeight || 0) + 'px';
      }
    }
  }, [listSubCategory]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      total > (params?.page || 0) * (params?.per_page || 0)
    ) {
      setParams({ ...params, page: (params.page || 0) + 1 });
    }
  };

  const handleScrollSubCategory = (e: any) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      totalSub > (params?.page || 0) * (params?.per_page || 0)
    ) {
      setParamsSub({ ...paramsSub, page: (params.page || 0) + 1 });
    }
  };

  useEffect(() => {
    if (listCategory?.length > 0) {
      if (categoriesIds?.categoriesIds?.includes(allCategory?.id)) {
        setCategorySelected([allCategory]);
        setSubCategorySelected([]);
        setSubCategoriesState([]);
        return;
      }
      const selectedCategory = [allCategory, ...(listCategory || [])]?.filter((item: ICategorySelected) =>
        categoriesIds?.categoriesIds?.includes(item?.id)
      );

      handleSetMultiSubCategoryState(selectedCategory, setSubCategoriesState);

      form?.setFieldsValue({ category_id: selectedCategory });

      setCategorySelected(selectedCategory);
    }
  }, [listCategory, categoriesIds?.categoriesIds]);

  useEffect(() => {
    if (subCategorySelected?.length > 0) {
      const parentCategoriesObject = subCategorySelected?.reduce((parentsObject: any, item: ICategorySelected) => {
        if (item?.parent_id) {
          parentsObject[item?.parent_id || 0] = [...(parentsObject[item?.parent_id || 0] || []), item];
          return parentsObject;
        }
        return parentsObject;
      }, {});

      form.setFieldsValue({ sub_category_id: parentCategoriesObject });
    }
  }, [subCategorySelected]);

  return (
    <StyledCategory className="w-100">
      <Row gutter={15} className="w-100">
        <Col className="gutter-row" span={12}>
          <div className="title-topic">
            <span className="title-category">{t('group.mainCategory')}</span>
          </div>
          <Col span={24} className="modal-main-topic-list pl-0" ref={refListItemCategory} onScroll={handleScroll}>
            {[allCategory, ...(listCategories || [])]?.map((item: ICategoryItem, index: number) => {
              return (
                <div
                  className={`modal-main-topic-list-item-parent ${
                    activeSelectedCategory(item, categorySelected) ? 'modal-main-topic-list-item-parent-active' : ''
                  }`}
                  key={index}
                  data-index={index}
                  onClick={() =>
                    handleSelectedCategory(item, categorySelected, subCategorySelected, subCategoriesState)
                  }
                >
                  <div className="modal-main-topic-list-item d-flex align-items-center justify-content-between">
                    <div className="content d-flex align-items-center">
                      <div className="mr-10">
                        <img
                          src={images.checkedSelected}
                          className={activeSelectedCategory(item, categorySelected) ? '' : 'opacity-0'}
                          alt="dropdown"
                        />
                      </div>
                      <span>{item.name}</span>
                    </div>
                    {item.sub_category_quantity > 0 && (
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            activeSelectedCategory(item, categorySelected)
                              ? images.arrowRightSelected
                              : images.arrowRight
                          }
                          alt="dropdown"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Col>
        </Col>
        <Col className="gutter-row" span={12}>
          {!!subCategoriesState && (
            <div className="title-topic">
              <span className="title-category">{t('createPost.subTopic')}</span>
            </div>
          )}

          <Col
            span={24}
            className="modal-main-topic-list"
            ref={refListSubItemCategory}
            onScroll={handleScrollSubCategory}
          >
            {subCategoriesState?.map(
              (item: { sub_categories: ISubcategoryItem[]; name: string | undefined; id: number | undefined }) => {
                return (
                  <div>
                    <span className="topic-main-name">{item?.name}</span>
                    {item?.sub_categories.map((_item: any, index: number) => {
                      return (
                        <div
                          ref={refItemSubCategory}
                          className={`modal-main-sub-topic-list-item-parent ${
                            activeSelectedCategory(_item, subCategorySelected)
                              ? 'modal-main-topic-list-item-parent-active'
                              : ''
                          }`}
                          key={index}
                          data-index={index}
                          onClick={handleSelectedSubCategory.bind(this, _item, subCategorySelected)}
                        >
                          <div className="modal-main-topic-list-item d-flex align-items-center justify-content-between">
                            <div className="content d-flex align-items-center">
                              <div className="mr-10">
                                <img
                                  src={images.checkedSelected}
                                  className={activeSelectedCategory(_item, subCategorySelected) ? '' : 'opacity-0'}
                                  alt="dropdown"
                                />
                              </div>
                              <span>{_item.name}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            )}
          </Col>
        </Col>
      </Row>
    </StyledCategory>
  );
};

export default forwardRef(FilterByCategory);

const StyledCategory = styled.div`
  &:before {
    background: #fbfbfb;
    position: absolute;
    width: 125%;
    height: 100%;
    content: '';
    left: -30px;
    top: 0;
    margin: 0px -24px 20px -24px;
  }
  padding: 20px 24px 0px 24px;
  .pl-0 {
    padding-left: 0px;
  }
  .title-topic {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-topic {
    margin-bottom: 10px;
    .title-category {
      color: #909090;
      font-size: 16px;
      display: block;
    }
  }

  .modal-main-topic-list {
    overflow-y: scroll;
    max-height: 357px;
    .topic-main-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #909090;
      padding-bottom: 9px;
    }
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
    .modal-main-topic-list-item {
      margin-bottom: 3px;
      cursor: pointer;
      padding: 10px 5px;
    }
  }

  .modal-main-topic-list-item-parent-active {
    background: #fff6e9;
    border-radius: 5px;
  }
`;
