import React, { FunctionComponent } from 'react';
import { ButtonSaveWrapper } from './styled';
import { images } from 'assets/images-base';

interface TableMasterProps {
  label?: string;
  click?: (e?: any) => void;
  icon?: string;
  className?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
  isLoading?: boolean;
  type: 'button' | 'submit' | 'reset' | undefined;
}

const ButtonSave: FunctionComponent<TableMasterProps> = ({
  label,
  click,
  icon,
  width,
  height,
  className,
  disabled,
  type,
  isLoading,
}) => {
  return (
    <ButtonSaveWrapper
      width={String(width)}
      height={String(height)}
      className={className}
      onClick={click}
      disabled={disabled || isLoading}
      type={type}
    >
      <img src={isLoading ? images.masterdata.loading : icon} alt="" className={`${isLoading ? 'loading' : null}`} />
      <span>{label}</span>
    </ButtonSaveWrapper>
  );
};

export default ButtonSave;
