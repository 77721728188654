import {
  InfoStyle,
  OptionItemStyle,
  SelectMemberStyle,
  TagIconStyle,
  TagItemStyle,
  TagNameStyle,
  TextStyle,
} from './selectMemberStyle';
import React, { useEffect, useRef, useState } from 'react';
import SelectCustom from 'components/common/select/SelectCustom';
import { Avatar, Select } from 'antd';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import images from 'assets/images-base';
import { AuthorAvatar, AvatarDefault } from 'pages/Home/TopRanking/AuthorRanking/AuthorRankingStyle';
import { isEmpty } from 'lodash';
import { DefaultOptionType } from 'antd/lib/select';

interface IStyle {
  width?: string;
  height?: string;
}

interface IProps {
  style?: IStyle;
  placeHolder?: string;
  onChange?: (value: any, _option: DefaultOptionType | DefaultOptionType[]) => void;
  data?: IOptionProp[];
  idLeave?: number[];
  value?: any;
  isShowIcon?: boolean;
}

interface IOptionProp {
  id: number;
  avatar?: string;
  name?: string;
  email?: string;
  position_name?: string;
}

const { Option } = Select;

const TagRender: React.FC<CustomTagProps> = (props) => {
  return (
    <TagItemStyle>
      <TagNameStyle>{props?.label}</TagNameStyle>
      <TagIconStyle onClick={props?.onClose} src={images.userManageGroup.TagMemberIcon} />
    </TagItemStyle>
  );
};

export const OptionItem: React.FC<IOptionProp> = (props) => {
  return (
    <OptionItemStyle>
      <AuthorAvatar isTop={false}>
        <Avatar
          size={36}
          src={props?.avatar || images.profile.avatarDefault}
          icon={<AvatarDefault alt="" src={images.profile.avatarDefault} />}
        />
      </AuthorAvatar>

      <InfoStyle>
        <TextStyle>{props?.name}</TextStyle>
        <TextStyle>{props?.email + ' - ' + props?.position_name}</TextStyle>
      </InfoStyle>
    </OptionItemStyle>
  );
};

const SelectMember: React.FC<IProps> = ({ onChange, value, isShowIcon = true, ...props }) => {
  // const [data, setData] = useState<IOptionProp[]>([]);

  // const [selectItemId, setSelectItemId] = useState<number[]>([]);

  const selectItemId = useRef<number[]>([]);

  const [realData, setRealData] = useState<IOptionProp[]>([]);

  useEffect(() => {
    if (!isEmpty(props?.data)) {
      handleFilterList(props.data || [], selectItemId.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, props?.idLeave]);

  const handleChange = (value: any, _option: DefaultOptionType | DefaultOptionType[]) => {
    handleFilterList(props?.data || [], value || [], true);
    selectItemId.current = value;
    onChange && onChange(value, _option);
  };

  const handleFilterList = (_data: IOptionProp[], selectId: number[], realTime?: boolean) => {
    realTime &&
      setTimeout(() => {
        setRealData(
          _data?.filter((_item: IOptionProp) => ![...selectId, ...(props?.idLeave || [])].includes(_item?.id))
        );
      }, 0);

    !realTime &&
      setRealData(_data?.filter((_item: IOptionProp) => ![...selectId, ...(props?.idLeave || [])].includes(_item?.id)));

    // return _data?.filter((_item: IOptionProp) => ![...selectItemId, ...(props?.idLeave || [])].includes(_item?.id));
  };

  const resetAllValue = () => {
    handleChange([], []);
  };

  return (
    <SelectMemberStyle>
      <SelectCustom
        onChange={handleChange}
        optionLabelProp="label"
        mode="multiple"
        value={value}
        // optionFilterProp="label"
        showArrow={isShowIcon}
        tagRender={(value) => <TagRender {...value} />}
        filterOption={(inputValue: any, option: any) => {
          return (
            option.props.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
            option.props.id.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          );
        }}
        {...props}
        showSearch={true}
        resetValue={resetAllValue}
      >
        {realData?.map((item: IOptionProp) => (
          <Option
            // className={[...selectItemId, ...(props?.idLeave || [])]?.includes(item?.id) ? 'hide-option' : ''}
            label={item?.name}
            value={item?.id}
            key={item?.id}
            id={item?.email}
          >
            <OptionItem {...item} />
          </Option>
        ))}
      </SelectCustom>
    </SelectMemberStyle>
  );
};

export default SelectMember;
