import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledUpload, { WrapperImageAvatarCrop, PreviewAvatarFake } from './style';
import UploadCropImage from 'components/common/uploadCropImage/UploadCropImage';
import { showConfirm } from 'helper/modal-confirm';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';

interface ImagePostProps {
  selectedAvatar: string | undefined;
  setSelectedAvatar: (info: string) => void;
}

interface IUploadError {
  avatar: string;
  cover: string;
}

const UploadImages: FunctionComponent<ImagePostProps> = ({ selectedAvatar, setSelectedAvatar }) => {
  const { t } = useTranslation();

  const [uploadError /* setUpLoadError */] = useState<IUploadError>({ avatar: '', cover: '' });

  const createButtonChangeAvatar = () => {
    return (
      <WrapperImageAvatarCrop>
        <UploadCropImage
          previewComponent={<PreviewAvatarFake id="PreviewAvatar" />}
          aspect={1}
          minWidth={90}
          cropTitle={t('common.cropImage')}
          onUploadSuccess={(url) => {
            setSelectedAvatar(url);
          }}
          upDateErrorText={(text: string | null) => {
            if (text !== null)
              showConfirm({
                hideCancel: true,
                content: text || '',
              });
          }}
        />
      </WrapperImageAvatarCrop>
    );
  };

  return (
    <StyledUpload
      theme={{
        avatarBg: selectedAvatar,
      }}
    >
      <SpaceStyle padding="50px 0" />
      {/* <Form.Item
        name="avatar_image"
        className="avatar-img"
        rules={[{ required: true, message: t('createGroup.requiredAvatar') }]}
      > */}
      <div className="label-input">
        <div className="icon-camera" />
        {createButtonChangeAvatar()}
        {selectedAvatar ? <div className="selected-image" /> : <div className="default-avatar" />}
        <div className="bg" />
      </div>
      {uploadError.avatar.length > 0 && (
        <div className="error upload-error-avatar">
          <p>{uploadError.avatar}</p>
        </div>
      )}
      {/* </Form.Item> */}
    </StyledUpload>
  );
};

export default UploadImages;
