import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  myInfo: {},
  isAdminSystem: false,
  permissionCheck: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeSetMyInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.myInfo = payload;
    },
    storeSetIsAdminSystem: (state: any, { payload }: PayloadAction<any>) => {
      state.isAdminSystem = payload;
    },
    storeSetPermission: (state: any, { payload }: PayloadAction<any>) => {
      state.permissionCheck = payload;
    },
  },
});

export const { storeSetMyInfo, storeSetIsAdminSystem, storeSetPermission } = authSlice.actions;

export default authSlice.reducer;
