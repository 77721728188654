import { sendGet } from 'api/axiosNotifications';
import images from 'assets/images-base';
import { ESystemId, EViewNotice } from 'common';
import configs from 'config';
import { useQuery } from 'react-query';

export interface INoticeParams {
  user_id: 52;
}

export interface IResSystem {
  system_id: ESystemId.ME | ESystemId.BO | ESystemId.RECRUIT | ESystemId.WORKFLOW | ESystemId.WIKI | ESystemId.PROJECT;
  name: string;
  desc: string;
  avatar: string;
  url: string | undefined;
  total: number | null;
  is_viewed: number;
}
const listSystem: IResSystem[] = [
  {
    system_id: ESystemId.ME,
    name: 'me',
    desc: 'descMe',
    avatar: images.notification.acmsMe,
    url: configs.DOMAIN_AMS_ME,
    total: null,
    is_viewed: EViewNotice.VIEW,
  },
  {
    system_id: ESystemId.BO,
    name: 'bo',
    desc: 'descBo',
    avatar: images.notification.acmsBo,
    url: configs.DOMAIN_AMS_BO,
    total: null,
    is_viewed: EViewNotice.VIEW,
  },
  {
    system_id: ESystemId.WORKFLOW,
    name: 'workFlow',
    desc: 'descWorkFlow',
    avatar: images.notification.acmsWorkFlow,
    url: configs.DOMAIN_AMS_WORKFLOW,
    total: null,
    is_viewed: EViewNotice.VIEW,
  },
  {
    system_id: ESystemId.WIKI,
    name: 'wiki',
    desc: 'descWiki',
    avatar: images.notification.acmsWiki,
    url: configs.DOMAIN_AMS_WIKI,
    total: null,
    is_viewed: EViewNotice.VIEW,
  },
  {
    system_id: ESystemId.RECRUIT,
    name: 'recruit',
    desc: 'descRecruit',
    avatar: images.notification.acmsRecruit,
    url: configs.DOMAIN_AMS_RECRUIT,
    total: null,
    is_viewed: EViewNotice.VIEW,
  },
  {
    system_id: ESystemId.PROJECT,
    name: 'project',
    desc: 'descProject',
    avatar: images.notification.acmsProject,
    url: configs.DOMAIN_AMS_PROJECT,
    total: null,
    is_viewed: EViewNotice.VIEW,
  },
];

const   useListSystem = (params: INoticeParams) =>
  useQuery(
    ['get_list_system', params],
    async () => {
      const res = await sendGet('/total/notifications', params);
      const dataSystem: IResSystem[] = listSystem.map((element: IResSystem) => {
        const system: any = res?.find((resElement: any) => element.system_id === resElement.system_id) || null;
        return {
          ...element,
          total: system?.total || null,
          is_viewed: system?.is_viewed || system?.is_viewed === EViewNotice.NOT_VIEW ? system?.is_viewed : 1,
        };
      });
      return dataSystem;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export default useListSystem;
