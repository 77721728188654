import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { colors } from 'common/styled-base';

export const AuthorRankingWrapper = styled.div``;
export const ListAuthorWrapper = styled.div`
  padding: 20px 5px 10px 20px;
  border: 2px solid ${colors.mainColor100};
  border-radius: 10px;
  margin-top: 10px;
  min-height: 318px;
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-weight: 500;
    font-size: 20px;
    color: #fea628;
    margin-left: 5px;
  }
`;
export const AuthorElement = styled.div`
  margin: 8px 0px;
  display: flex;
  flex-direction: row;
`;
export const AuthorAvatar = styled.div<any>`
  width: ${(props) => (props?.size ? props?.size + 'px' : '40px')};
  height: ${(props) => (props?.size ? props?.size + 'px' : '40px')};
  border-radius: 20px;
  background-image: ${(props) =>
    props?.isTop
      ? 'linear-gradient(to bottom, #fea628 0%, #ffdaa5 100%)'
      : 'linear-gradient(to bottom, #63A4FF 0%, #83EAF1 100%)'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props?.isPreview ? 'default' : 'pointer')};
  img {
    background: white;
  }
`;
export const AuthorStyle = styled.div<any>`
  cursor: pointer;
  font-weight: ${(props) => props?.style?.fontWeight || 500};
  color: ${(props) => props?.style?.color || '#223354'};

  display: block;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props?.limitLine || 'unset'}; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  a {
    color: ${(props) => props?.style?.color || '#223354'};
  }
`;
export const AuthorRightAvatar = styled.div`
  margin-left: 10px;
  /* flex: ${isMobile ? 1 : 'unset'}; */
  flex: 1;
`;

interface IAuthor {
  formHome?: boolean;
  fromSearch?: boolean;
}
export const DescAuthorWrapper = styled.div<IAuthor>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (isMobile && !props?.formHome ? 'space-between' : 'unset')};
`;
export const DescAuthorStyle = styled.div<IAuthor>`
  margin-top: 2px;
  font-size: ${(props) => (props?.fromSearch ? '14px' : '12px')};
  line-height: ${(props) => (props?.fromSearch ? '21px' : '16px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 90px;
  div:nth-child(1) {
    color: ${colors.textCaption};
  }
  div:nth-child(2) {
    color: #464646;
    margin-left: 5px;
    font-weight: ${(props) => (props?.fromSearch ? '500' : 'unset')};
    padding-right: ${(props) => (props?.fromSearch ? '16px' : 'unset')};
  }
`;
export const EmptyData = styled.div`
  text-align: center;
  width: 100% !important;
  color: #909090;
`;
export const AvatarDefault = styled.img`
  background-color: ${colors.grey3};
`;
