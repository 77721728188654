import { colors } from 'common/styled-base';
import styled from 'styled-components';

export const IconOpenSub = styled.img`
  height: fit-content;
  margin: auto 0px;
  cursor: pointer;
`;
const CategoryFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 500;
    color: ${colors.textCaption};
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .checkbox-container > div {
    label {
      span:nth-child(2) {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        /* display: inline-block; */
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .ant-image {
      width: 40px;
      img {
        cursor: pointer;
        width: 16px;
        height: auto;
      }
    }
    display: flex;
    flex-direction: row;
  }
  .checkbox-container {
    .tooltip {
      visibility: hidden;
      width: fit-content;
      background-color: ${colors.gray2};
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 10px;
      margin-top: 25px;
      font-size: 12px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }
    .sub-tooltip {
      visibility: hidden;
      width: fit-content;
      background-color: ${colors.gray2};
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 10px;
      margin-top: 25px;
      font-size: 12px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }
    margin-top: 16px;
    .checkbox {
      margin-right: 16px;
      :hover {
        .tooltip {
          visibility: visible;
        }
      }
    }
    .title-active-btn {
      transform: rotate(180deg);
      transition: all 0.3s ease-in-out;
    }
    .title-un-active-btn {
      transition: all 0.3s ease-out;
    }
    .sub-checkbox-container {
      margin-top: 5px;
      margin-bottom: 5px;
      .sub-checkbox {
        margin-top: 14px;
        margin-left: 25px;
        :hover {
          .sub-tooltip {
            visibility: visible;
          }
        }
      }
    }
  }
`;

export const updatePreventSelect = (listCategoryMain: any[], listCategoryIdSelected: number[]) => {
  const result = [...listCategoryMain];
  const listMainSelectedId = listCategoryMain
    .filter((item: any) => listCategoryIdSelected.includes(item.id))
    .map((item: any) => item.id);

  const countMain = listMainSelectedId.length;

  for (const item of result) {
    let preventSelectMain = countMain >= 2;
    if (listMainSelectedId.includes(item.id)) preventSelectMain = false;
    item.preventSelected = preventSelectMain;

    if (!item.subLabel) continue;
    for (const subItem of item.subLabel) {
      let preventSelectSub = preventSelectMain;
      if (!preventSelectMain && item?.subSelectedId) {
        preventSelectSub = item?.subSelectedId !== subItem?.id;
      }
      subItem.preventSelected = preventSelectSub;
    }
  }
  return result;
};
export default CategoryFilterWrapper;
