import { colors } from 'common/styled-base';
import styled from 'styled-components';

export const PostQuickSearchWrapper = styled.div`
  margin-left: 10px;
  .bg-gray {
    background-color: ${colors.grey3};
  }
`;
export const Title = styled.div`
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #464646;
`;
export const PostWrapper = styled.div`
  padding: 6px 15px;
  div {
    cursor: pointer;
    font-weight: 500;
    color: #223354;
  }
  span {
    cursor: pointer;
    font-size: 12px;
    color: #2c8ce4;
  }
`;
