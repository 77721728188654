import { PlaceHolderStyle, SelectCustomStyle, WrapperStyle } from './selectCustomStyle';
import React, { useEffect, useRef, useState } from 'react';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { isEmpty } from 'lodash';
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { DefaultOptionType } from 'antd/lib/select';
interface IStyle {
  width?: string;
  height?: string;
}
interface IProps {
  style?: IStyle;
  placeHolder?: string;
  onChange?: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
  options?: DefaultOptionType[];
  tagRender?: (value: CustomTagProps) => void;
  mode?: 'multiple' | 'tags';
  showArrow?: boolean;
  optionLabelProp?: string;
  searchValue?: string;
  optionFilterProp?: string;
  value?: any;
  onSelect?: (value: any, option: any) => void;
  showSearch?: boolean;
  autoClearSearchValue?: boolean;
  resetValue?: () => void;
  filterOption?: (inputValue: any, option: any) => void;
}

const SelectCustom: React.FC<IProps> = ({ children, placeHolder, onChange, style, value, ...props }) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isHaveValue, setIsHaveValue] = useState<boolean>(false);

  const selectRef = useRef<any>();

  useEffect(() => {
    setIsHaveValue(!isEmpty(value));
  }, [value]);

  const checkToAddProperty = () => {
    if (!isMobile) {
      return Object.assign(
        { ...props },
        {
          open: isFocus,
          suffixIcon: <DownOutlined onClick={() => setIsFocus(!isFocus)} />,
        }
      );
    } else {
      return { ...props };
    }
  };

  const checkToHaveResetProperty = () => {
    if (props?.resetValue && isMobile) {
      return { onClick: () => props?.resetValue && props.resetValue() };
    } else return;
  };

  if (value) {
    return (
      <WrapperStyle>
        <SelectCustomStyle
          onBlur={() => setIsFocus(false)}
          isFocus={isFocus}
          onClick={() => setIsFocus(true)}
          // onFocus={() => setIsFocus(true)}
          onKeyPress={() => !isFocus && setIsFocus(true)}
          onChange={(_value: any, option: DefaultOptionType | DefaultOptionType[]) => {
            !isEmpty(_value) && setIsHaveValue(true);
            if (isEmpty(_value)) {
              setIsHaveValue(false);
              setIsFocus(false);
            }
            onChange && onChange(_value, option);
          }}
          onSelect={props?.onSelect}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          tagRender={props?.tagRender}
          allowClear={true}
          options={props?.options}
          clearIcon={<CloseCircleOutlined {...checkToHaveResetProperty()} />}
          value={value}
          {...style}
          {...checkToAddProperty()}
          ref={selectRef}
          suffixIcon={
            <DownOutlined
              onClick={() => {
                setIsFocus(!isFocus);
              }}
            />
          }
        >
          {children}
        </SelectCustomStyle>

        {placeHolder && (
          <PlaceHolderStyle isHaveValue={isHaveValue} isFocus={isFocus}>
            {placeHolder}
          </PlaceHolderStyle>
        )}
      </WrapperStyle>
    );
  }

  return (
    <WrapperStyle>
      <SelectCustomStyle
        onBlur={() => setIsFocus(false)}
        isFocus={isFocus}
        onClick={() => setIsFocus(true)}
        // onFocus={() => setIsFocus(true)}
        onKeyPress={() => !isFocus && setIsFocus(true)}
        onChange={(_value: any, option: DefaultOptionType | DefaultOptionType[]) => {
          !isEmpty(_value) && setIsHaveValue(true);
          if (isEmpty(_value)) {
            setIsHaveValue(false);
            setIsFocus(false);
          }
          onChange && onChange(_value, option);
        }}
        dropdownStyle={{ maxHeight: '210px' }}
        getPopupContainer={(trigger: any) => trigger.parentNode}
        tagRender={props?.tagRender}
        allowClear={true}
        options={props?.options}
        {...style}
        {...checkToAddProperty()}
        ref={selectRef}
      >
        {children}
      </SelectCustomStyle>

      {placeHolder && (
        <PlaceHolderStyle isHaveValue={isHaveValue} isFocus={isFocus}>
          {placeHolder}
        </PlaceHolderStyle>
      )}
    </WrapperStyle>
  );
};

export default SelectCustom;
