import { imageSecond } from './imagesSecond';
import checked from './images/checked.svg';
import unCheck from './images/uncheck.svg';
import bgTemplate from './images/bg-template.svg';
import closeModal from './images/close-circle.svg';
import bannerPost from './images/create/banner.svg';
import thumnailDf from './images/create/thumnail-default.svg';
import iconCamera from './images/create/icon-camera.svg';
import bannerDefault from './images/create/banner-default.svg';
import bannerCreate from './images/create/banner-create.svg';
import file from './images/create/file.svg';

import iconSearchWhite from './images/Home/iconSearchWhite.svg';
import iconEditOrange from './images/Home/iconEditOrange.svg';
import iconEditWhite from './images/Home/iconEditWhite.svg';
import MedalImage from './images/Home/medal-star.svg';
import DocumentText from './images/document-text.svg';
import Heart from './images/heart.svg';
import ArrowUp from './images/arrow-up.svg';
import GroupSvg from './images/Group.svg';
import FrameSvg from './images/Frame.svg';
import VectorImg from './images/Vector.svg';
import HomeBG from './images/Home/HomeBG.jpg';
import PostTop from './images/PostTop.png';
import Hottag from './images/hashtag.svg';
import Global from './images/global.svg';
import AvatarDefault from './images/avatar.svg';

import phay from './images/phay.png';
import BGTopGroup from './images/bg-post-top-group.svg';
import Filter from './images/filter.png';
import TopCreateGroup from './images/top-create.png';
import CaretUp from './images/caret-up.png';
import CaretUpDisable from './images/Group/caret-up-disable.svg';
import CaretUpSearch from './images/Group/caret-up.svg';
import amelaBanner from './images/amela.svg';
import editTable from './images/table/edit.svg';
import trashTable from './images/table/trash.svg';
import backTable from './images/table/back.svg';
import nextTable from './images/table/next.svg';
import dropDown from './images/table/drop-down.svg';
import bgTable from './images/table/bg-table.svg';
import addTable from './images/table/add.svg';
import tickCircleTable from './images/table/tick-circle.svg';
import search from './images/table/search-normal.svg';
import polygon from './images/masterdata/polygon.svg';
import triangle from './images/masterdata/triangle.svg';
import grayTriangle from './images/masterdata/grayTriangle.svg';
import Edit from './images/edit-2.png';
import UserGroup from './images/user-group.png';
import Setting from './images/setting.png';
import GlobalWhite from './images/global-white.png';
import circleQuestion from './images/circle-question.svg';
import draft from './images/draft.svg';
import preview from './images/preview.svg';
import post from './images/post.svg';
import arrowRight from './images/arrow-right.svg';
import arrowRightSelected from './images/arrow-right-selected.svg';
import checkedSelected from './images/checkedSelected.svg';
import calendar from './images/calendar.png';
import NewPost from './images/Home/new-post.svg';
import remarkable from './images/remarkable.png';
import trendUp from './images/trend-up.png';
import arrowBottom from './images/masterdata/arrow-bottom.svg';
import plus from './images/plus.svg';
import calendarBlack from './images/iconCalendar.svg';
import Crown from './images/Crown.png';
import ProfileAdd from './images/profile-add.png';
import circleDisable from './images/masterdata/tick-circle-disable.svg';
import SaveYellow from './images/save-yellow.png';
import tickIcon from './images/permission/tick-circle.svg';
import iconDropdown from './images/permission/icon-dropdown.svg';
import backTableDisable from './images/table/back-disable.svg';
import nextTableDisable from './images/table/next-disable.svg';
import loading from './images/masterdata/loading.svg';
import Right from './images/right.png';

import bgModal from './images/Group/add-group.svg';
import cameraAvatar from './images/Group/camera.svg';
import profileAvatar from './images/Group/profile-circle.svg';
import iconUploadBanner from './images/Group/import.svg';
import iconButtonCreate from './images/Group/icon-create-group.svg';
import iconDelete from './images/Group/icon-close.svg';
import searchNormal from './images/search-normal.png';

import sad from './images/detail/sad.svg';
import like from './images/detail/like.svg';
import angry from './images/detail/angry.svg';
import heart from './images/detail/heart.svg';
import happy from './images/detail/happy.svg';
import saved from './images/detail/saved.svg';
import soaked from './images/detail/soaked.svg';
import report from './images/detail/report.svg';
import avatar from './images/detail/avatar.svg';
import coffee from './images/detail/coffee.svg';
import smiling from './images/detail/smiling.svg';
import gallery from './images/detail/gallery.svg';
import warning from './images/detail/warning-circle.svg';
import ayeIcon from './images/detail/eye.svg';
import canSend from './images/detail/can-send.svg';
import bgDetail from './images/detail/bg-detail.svg';
import follower from './images/detail/follower.svg';
import following from './images/detail/following.svg';
import reactionBg from './images/detail/reaction-bg.svg';
import messagesCm from './images/detail/messages-cm.svg';
import fileAuthor from './images/detail/file.svg';
import editDetail from './images/detail/edit-detail.svg';
import heartSearch from './images/detail/heart-search.svg';
import heartAuthor from './images/detail/heart-author.svg';
import trashDetail from './images/detail/trash-detail.svg';
import sendComment from './images/detail/send.svg';
import closeCircle from './images/detail/close-circle.svg';
import profileTick from './images/detail/profile-tick.svg';
import relationship from './images/detail/relationship.svg';
import fileDownload from './images/detail/file_download.svg';
import systemAvatar from './images/detail/system-avatar.svg';
import profileAuthor from './images/detail/profile-add.svg';
import messagesDetail from './images/detail/messages.svg';
import questionDetail from './images/detail/question-bg.svg';
import messagesActive from './images/detail/messages-active.svg';
import duplicatePublic from './images/detail/duplicate-public.svg';
import iconCopy from './images/detail/iconCopy.svg';
import iconSeriesDetail from './images/detail/icon-series.svg';
import editCategory from './images/detail/edit.svg';
import detailComment from './images/detail/comment.svg';
import detailEdit from './images/detail/detail_edit.svg';
import detailTrash from './images/detail/detail-trash.svg';
import detailTick from './images/detail/tick-circle.svg';
import detailClose from './images/detail/close-detail.svg';
import closeReport from './images/detail/close-report.svg';
import add from './images/detail/add.svg';
import minusCircle from './images/detail/minus-cirlce.svg';
import waiting from './images/detail/waiting.svg';
import closeIcon from './images/detail/close.png';

import BgListMember from './images/userManageGroup/BgListMembers.png';
import trashGroup from './images/userManageGroup/trash.jpg';
import BgGroupRule from './images/userManageGroup/BgGroupRule.png';
import ticketCircle from './images/GroupJoining/tick-circle.jpg';
import CmsLockGroup from './images/cms/group/lock.jpg';
import bannerGroup from './images/Group/banner-group.png';
import iconScrollUp from './images/Group/arrow-up.svg';

import tickCircle from './images/userManageGroup/tick-circle.jpg';
import closeCircleGroup from './images/userManageGroup/close-circle.svg';
import unSave from './images/detail/unsave.svg';
import iconReset from './images/Group/icon-reset.svg';
import iconFilter from './images/Group/icon-search.svg';
import groupTagChecked from './images/Group/checked.svg';
import modalClose from './images/Group/close.svg';
import followReact from './images/detail/follow-react.svg';
import followedReact from './images/detail/followed-react.svg';
import closeModalCr from './images/detail/close-modal.svg';

import members from './images/search/members.svg';
import bgSearch from './images/search/bg-search.svg';
import searchIcon from './images/search/search.svg';
import documentText from './images/search/document-text.svg';
import closeCircleSearch from './images/search/close-circle.svg';
import calendarIcon from './images/search/calendar.svg';
import noDataSearch from './images/search/noData.svg';
import spinner from './images/search/Spinner.svg';

import bannerFollowList from './images/modalList/banner.jpg';
import bannerFollowListMobile from './images/modalList/banner-mobile.svg';
import closeModalList from './images/modalList/close-icon.svg';
import isFriendIcon from './images/modalList/isFriendIcon.svg';
import isNotFriendIcon from './images/modalList/isNotFriendIcon.svg';
import blogCountIcon from './images/modalList/blogCountIcon.svg';
import postIcon from './images/profile/post-icon.svg';
import heartIcon from './images/profile/heart.svg';
import userIcon from './images/profile/user-tick.svg';
import followIcon from './images/profile/follow.svg';
import editIcon from './images/profile/edit-bio.svg';
import bgTopPost from './images/profile/bgTopPosts.png';
import groupIcon from './images/profile/global.svg';
import avatarDefault from './images/profile/avatar-default.svg';

import ProfileAddWhite from './images/GroupJoining/profile-add-white.svg';

import ArchiveBook from './images/createPost/archive-book.svg';
import CreatePost from './images/createPost/document-upload.svg';
import DefaultTopPostBlue from './images/Home/default-top-post-blue.jpg';
import DefaultPostThumbnail from './images/Home/default-post-thumbnail.jpg';
import DefaultTopPostRed from './images/Home/default-top-post-red.jpg';
import Messages from './images/Home/messages-2.svg';
import HotQuestionBg from './images/Home/HotQuestion.jpg';
import Saly from './images/Home/Saly-1.jpg';
import searchHeader from './images/Home/search-normal.svg';
import BgHotQuestion2 from './images/Home/BGHotQuestion2.svg';
import iconHashtagOrange from './images/hashtagHot/iconHashtag.svg';
import HashTagBannerMobile from './images/hashtagHot/bannerMobile.svg';
import EyeCreatePost from './images/createPost/eye.svg';
import ArchiveBookYellow from './images/createPost/archive-book-yellow.svg';

import iconFollow from './images/profile/profile-add.svg';
import iconFollowing from './images/profile/profile-tick.svg';
import HomeMenu from './images/Home/menu.svg';

import bgPrivateGroup from './images/dashboard/bgPrivateGroup.png';
import bgPublicGroup from './images/dashboard/bgPublicGroup.png';
import bgTopCategory from './images/dashboard/bgTopCategory.svg';
import bgTopGroup from './images/dashboard/bgTopGroup.svg';
import bgTopPostByMonth from './images/dashboard/bgTopPostByMonth.svg';
import bgTotalGroup from './images/dashboard/bgTotalGroup.png';
import bgTotalPost from './images/dashboard/bgTotalPost.png';
import bgTotalQuestionPost from './images/dashboard/bgTotalQuestionPost.svg';
import bgTotalReported from './images/dashboard/bgTotalReported.png';
import bgTotalSharePost from './images/dashboard/bgTotalSharePost.png';
import iconEdit from './images/dashboard/icon-dashboard.svg';
import iconFlag from './images/dashboard/flag.svg';
import iconHashtag from './images/dashboard/hashtag.svg';
import iconGrayGroup from './images/dashboard/iconGrayGroup.svg';
import iconYellowGroup from './images/dashboard/iconYellowGroup.svg';
import iconPost from './images/dashboard/iconPost.svg';
import iconPublicGroup from './images/dashboard/iconPublicGroup.svg';
import iconReport from './images/dashboard/iconReport.svg';
import iconTopPost from './images/dashboard/iconTopPost.svg';
import iconTotalQuestionPost from './images/dashboard/iconTotalQuestionPost.svg';
import iconTotalSharePost from './images/dashboard/iconTotalSharePost.svg';
import iconReact from './images/dashboard/react.svg';
import iconEyeGray from './images/dashboard/iconEyeGray.svg';
import iconMessagesGray from './images/dashboard/iconMessagesGray.svg';
import iconPostGray from './images/dashboard/iconPostGray.svg';
import iconAdminGray from './images/dashboard/iconAdminGray.svg';
import iconPublicGroup2 from './images/dashboard/publicGroupIcon.svg';
import iconPrivateGroup from './images/dashboard/privateGroupIcon.svg';
import TypePostSeri from './images/component/ButtonCreatePost/type-post-seri.png';
import TypePostNormal from './images/component/ButtonCreatePost/type-post-normal.png';
import CreatePostDisbled from './images/component/ButtonCreatePost/create-post-disabled.svg';
import BgNotJoin from './images/component/ButtonCreatePost/bg-not-join.png';
import WaitApprove from './images/GroupJoining/waitApprove.svg';
import IconEditPost from './images/createPost/edit-2.svg';
import ResetPost from './images/createPost/refresh-2.svg';
import iconPrivateGroupDetail from './images/Group/lock.svg';

import addCircle from './images/series/add-circle.svg';
import noteRemove from './images/series/note-remove.svg';
import profileNotAdd from './images/series/profile-add.svg';
import permissionAdd from './images/series/permission-add.svg';
import profileDelete from './images/series/profile-delete.svg';
import seriesPenIcon from './images/series/series-pen-icon.svg';
import seriesIconDetail from './images/series/seriesIconDetail.svg';
import seriesCreateIcon from './images/series/series-create-icon.svg';
import iconTrash from './images/trash.svg';
import iconAddCircle from './images/addCircle.svg';

import iconSeries from './images/profile/series-icon.svg';
import iconDeleteSeries from './images/profile/delete-series.svg';
import iconEditSeries from './images/profile/edit-series.svg';
import BannerListSeri from './images/listSeries/banner-list-seri.jpg';
import AmelaListSeri from './images/listSeries/amela.svg';
import PostListSeri from './images/listSeries/document-text.svg';
import UserListSeri from './images/listSeries/Two-user.svg';
import DotListSeri from './images/listSeries/dot.svg';
import Duplicate from './images/listSeries/duplicate.svg';
import resetTemplateBlue from './images/createPost/refresh-blue.svg';

import acmsBo from './images/notification/acms_bo.svg';
import acmsMe from './images/notification/acms_me.svg';
import acmsWiki from './images/notification/acms_wiki.svg';
import acmsWorkFlow from './images/notification/acms_workflow.svg';
import acmsProject from './images/notification/acms-project.svg';
import AmelaIcon from './images/Home/amela.svg';
import acmsRecruit from './images/Home/acms_recruit.svg';

import Post from './images/component/Post/post.svg';
import SavePost from './images/component/Post/save.svg';
import ViewPost from './images/component/Post/eye.svg';

import bannerHome from './images/Home/banner-home.svg';
import amelaWiki from './images/Home/amela-wiki.svg';
import amelaWikiMobile from './images/Home/amela-wiki-mobile.svg';
import trendUpHome from './images/Home/trend-up.svg';
import pen from './images/Home/pen.svg';
import medalStarYellow from './images/Home/medal-star-yellow.svg';
import bannerSetting from './images/Home/banner-setting.svg';
import fileTitle from './images/Home/file.svg';
import closeNewModal from './images/Home/close-circle-cr.svg';
import bannerMobile from './images/Home/banner-home.png';

// chatbot
import TextLoading from './images/text-loading.svg'
import ChatBotAva from './images/mask.svg'

// userManageGroup
import PostTopGroup from './images/userManageGroup/postTopTitle.svg';
import NewFeedIcon from './images/userManageGroup/newfeed.svg';
import WaitApproveIcon from './images/userManageGroup/wait-approve.svg';
import ReportIcon from './images/userManageGroup/report.svg';
import TopCreateIcon from './images/userManageGroup/topCreate.svg';
import ListRequestIcon from './images/userManageGroup/listRequest.svg';
import PublicGroupIcon from './images/userManageGroup/publicGroup.svg';
import PrivateGroupIcon from './images/userManageGroup/privateGroup.svg';
import AddMemberIcon from './images/userManageGroup/add.svg';
import SettingGroupIcon from './images/userManageGroup/settingGroupIcon.svg';
import OutGroupIcon from './images/userManageGroup/outGroup.svg';
import WaitApproveBannerIcon from './images/userManageGroup/waitApprove.svg';
import TagMemberIcon from './images/userManageGroup/tagMember.svg';
import Trash from './images/userManageGroup/trash.svg';
import TickCircleWhite from './images/userManageGroup/tick-circle-white.svg';

// General Group
import MyGroupIcon from './images/Group/my-group.svg';
import PostsIcon from './images/Group/posts.svg';
import NoDataGroup from './images/Group/no-data-group.svg';
import PostGroupIcon from './images/Group/post-group.svg';
import GroupGroupIcon from './images/Group/group-group.svg';
import PostGroupDisabledIcon from './images/Group/post-group-disabled.svg';
import GroupGroupEnableIcon from './images/Group/group-group-enable.svg';

// create group
import CancelIcon from './images/create/close-circle.svg';

// profile
import MinusIcon from './images/profile/minus-cirlce.svg';
import RejectPost from './images/profile/rejectPost.svg';
import WaitApproveProfile from './images/profile/waitApprove.svg';
import AdminAmelaIcon from './images/profile/amela-admin.svg';

// GroupJoining
import JoinGroupBg from './images/GroupJoining/join-group.svg';

// FindPostByCategory
import TitleIcon from './images/findPostByCategory/title.svg';

// Header
import RingIcon from './images/ring.svg';

// createPost
import pinFileIcon from './images/createPost/pinFile.svg';

// table
import emptyTableIcon from './images/table/empty-table.svg';

// series

import PostSeriesIcon from './images/series/post-series.svg';
import CreateSeriesIcon from './images/series/create-series.svg';
import deleteIcon from './images/series/delete.svg';
import dropDownIcon from './images/series/drop-down.svg';
import dropDownIconBlack from './images/series/drop-down-black.svg';
import addSeries from './images/series/add-series.svg';
import logout from './images/series/logout.svg';
import changePermissionIcon from './images/series/setting.svg';

// tool editor
import AlignCenter from './images/align-center.svg';
import AlignRight from './images/align-right.svg';
import AlignLeft from './images/align-left.svg';

const header = {
  RingIcon,
};

const series = {
  iconTrash,
  iconAddCircle,
  addCircle,
  noteRemove,
  listSeri: {
    BannerListSeri,
    AmelaListSeri,
    PostListSeri,
    UserListSeri,
    DotListSeri,
    Duplicate,
  },
  profileNotAdd,
  permissionAdd,
  profileDelete,
  seriesPenIcon,
  seriesCreateIcon,
  seriesIconDetail,
  PostSeriesIcon,
  createAndEdit: {
    CreateSeriesIcon,
  },
  deleteIcon,
  dropDownIcon,
  dropDownIconBlack,
  addSeries,
  logout,
  changePermissionIcon,
};

const notification = {
  acmsBo,
  acmsMe,
  acmsWiki,
  acmsWorkFlow,
  acmsRecruit,
  acmsProject,
};

const dashBoard = {
  bgPrivateGroup,
  bgPublicGroup,
  bgTopCategory,
  bgTopGroup,
  bgTopPostByMonth,
  bgTotalGroup,
  bgTotalPost,
  bgTotalQuestionPost,
  bgTotalReported,
  bgTotalSharePost,
  iconEdit,
  iconFlag,
  iconHashtag,
  iconGrayGroup,
  iconPost,
  iconPublicGroup,
  iconReport,
  iconTopPost,
  iconTotalQuestionPost,
  iconTotalSharePost,
  iconReact,
  iconEyeGray,
  iconMessagesGray,
  iconPostGray,
  iconAdminGray,
  iconPublicGroup2,
  iconPrivateGroup,
  iconYellowGroup,
};

const editorPlugin = {
  AlignCenter,
  AlignLeft,
  AlignRight,
};

export const images = {
  layout: {
    bgTemplate,
    closeModal,
    checked,
    unCheck,
    bannerPost,
    SaveYellow,
    Right,
    bannerFollowList,
    bannerFollowListMobile,
    closeModalList,
    closeNewModal,
  },
  home: {
    MedalImage,
    DocumentText,
    Heart,
    ArrowUp,
    GroupSvg,
    FrameSvg,
    VectorImg,
    HomeBG,
    PostTop,
    Hottag,
    Global,
    NewPost,
    remarkable,
    trendUp,
    searchNormal,
    AvatarDefault,
    DefaultTopPostBlue,
    DefaultTopPostRed,
    Messages,
    HotQuestionBg,
    Saly,
    searchHeader,
    BgHotQuestion2,
    HomeMenu,
    DefaultPostThumbnail,
    bannerHome,
    amelaWiki,
    trendUpHome,
    pen,
    medalStarYellow,
    bannerSetting,
    fileTitle,
    iconSearchWhite,
    iconEditOrange,
    iconEditWhite,
    AmelaIcon,
    bannerMobile,
    amelaWikiMobile,
  },
  group: {
    phay,
    BGTopGroup,
    Filter,
    TopCreateGroup,
    CaretUp,
    Edit,
    UserGroup,
    Setting,
    GlobalWhite,
    Crown,
    ProfileAdd,
    BgListMember,
    trashGroup,
    BgGroupRule,
    bannerGroup,
    iconScrollUp,
    tickCircle,
    closeCircleGroup,
    iconReset,
    iconFilter,
    groupTagChecked,
    modalClose,
    CaretUpDisable,
    CaretUpSearch,
    iconPrivateGroupDetail,
    MyGroupIcon,
    PostsIcon,
    NoDataGroup,
    PostGroupIcon,
    GroupGroupIcon,
    PostGroupDisabledIcon,
    GroupGroupEnableIcon,
  },
  template: {},
  create: {
    thumnailDf,
    iconCamera,
    bannerDefault,
    file,
    bannerCreate,
    amelaBanner,
  },
  table: {
    editTable,
    trashTable,
    backTable,
    nextTable,
    dropDown,
    bgTable,
    addTable,
    tickCircleTable,
    search,
    backTableDisable,
    nextTableDisable,
    emptyTableIcon,
  },
  masterdata: { polygon, arrowBottom, circleDisable, loading, triangle, grayTriangle },
  adminPost: {
    calendar,
  },
  adminGroup: {
    calendarBlack,
  },
  arrowRight,
  arrowRightSelected,
  checkedSelected,
  circleQuestion,
  draft,
  post,
  preview,
  plus,
  permission: { tickIcon, iconDropdown },
  createGroup: {
    bgModal,
    cameraAvatar,
    profileAvatar,
    iconUploadBanner,
    iconButtonCreate,
    iconDelete,
    CancelIcon,
  },
  detail: {
    bgDetail,
    ayeIcon,
    messagesDetail,
    relationship,
    like,
    heartSearch,
    angry,
    smiling,
    sad,
    soaked,
    report,
    gallery,
    avatar,
    heart,
    reactionBg,
    fileAuthor,
    heartAuthor,
    profileAuthor,
    coffee,
    follower,
    questionDetail,
    editDetail,
    trashDetail,
    closeCircle,
    following,
    fileDownload,
    warning,
    happy,
    messagesCm,
    profileTick,
    saved,
    unSave,
    messagesActive,
    followReact,
    followedReact,
    closeModalCr,
    isFriendIcon,
    isNotFriendIcon,
    blogCountIcon,
    sendComment,
    canSend,
    duplicatePublic,
    iconCopy,
    systemAvatar,
    iconSeriesDetail,
    tableOfContent: imageSecond.iconTableOfContent,
    editCategory,
    detailComment,
    detailEdit,
    detailTrash,
    detailTick,
    detailClose,
    closeReport,
    add,
    minusCircle,
    waiting,
    closeIcon,
  },
  groupJoining: {
    ticketCircle,
    ProfileAddWhite,
    WaitApprove,
    JoinGroupBg,
  },
  cmsGroup: {
    CmsLockGroup,
  },
  search: {
    bgSearch,
    documentText,
    members,
    closeCircleSearch,
    searchIcon,
    calendarIcon,
    noDataSearch,
    spinner,
  },
  createPost: {
    ArchiveBook,
    CreatePost,
    EyeCreatePost,
    ArchiveBookYellow,
    IconEditPost,
    ResetPost,
    resetTemplateBlue,
    iconEditBorder: imageSecond.iconEditBorder,
    pinFileIcon,
  },

  profile: {
    heartIcon,
    userIcon,
    postIcon,
    followIcon,
    editIcon,
    bgTopPost,
    groupIcon,
    avatarDefault,
    iconFollow,
    iconFollowing,
    iconSeries,
    iconDeleteSeries,
    iconEditSeries,
    MinusIcon,
    RejectPost,
    WaitApproveProfile,
    AdminAmelaIcon,
  },

  hastTagHot: {
    iconHashtagOrange,
    HashTagBannerMobile,
  },
  dashBoard,
  component: {
    TypePostSeri,
    TypePostNormal,
    CreatePostDisbled,
    BgNotJoin,
    Post,
    SavePost,
    ViewPost,
  },
  series,
  notification,
  userManageGroup: {
    PostTopGroup,
    NewFeedIcon,
    ReportIcon,
    WaitApproveIcon,
    TopCreateIcon,
    ListRequestIcon,
    PublicGroupIcon,
    PrivateGroupIcon,
    AddMemberIcon,
    SettingGroupIcon,
    OutGroupIcon,
    WaitApproveBannerIcon,
    TagMemberIcon,
    Trash,
    TickCircleWhite,
  },
  findPostByCategory: {
    TitleIcon,
  },
  header,
  editorPlugin,
  chatbot: {
    TextLoading,
    ChatBotAva,
  },
};

export default images;
