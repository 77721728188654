import { FilterCategoryMobileStyle } from './filterCategoryMobileStyle';
import React, { useEffect, useRef } from 'react';
import useAllCategoryList from 'data/GroupGeneral/useGetAllCategory';
import { Form, FormInstance } from 'antd';
import TreeSelectCustom, { IDataDefault } from 'components/common/TreeSelect/TreeSelectCustom';
import { useTranslation } from 'react-i18next';

interface IFilterCateGoryMobile {
  form: FormInstance<any>;
  valueParent?: number[];
}
interface ICategoryDataApi {
  id: number;
  name: string;
  sub_categories?: ICategoryDataApi[];
}
const FilterCategoryMobile: React.FC<IFilterCateGoryMobile> = ({ form, valueParent }) => {
  const { data: categories } = useAllCategoryList({ top: 1 });
  const { t } = useTranslation();
  const subCategoriesRef = useRef<any>({});
  const handleFormatDataTreeSelect = (data: ICategoryDataApi[]) => {
    const result: ICategoryDataApi[] = data?.map((item: ICategoryDataApi) => {
      return {
        id: item?.id,
        name: item?.name,
        children: item?.sub_categories,
      };
    });
    return result;
  };

  useEffect(() => {
    if (categories) {
      form.setFieldsValue({
        category_id: categories?.filter((item: ICategoryDataApi) => valueParent?.includes(item?.id)),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, valueParent, categories]);

  const handlePickChild = (value: IDataDefault) => {
    const exitRecord = subCategoriesRef.current[value?.parentId || 0]?.filter((item: any) => item?.id === value?.id);

    if (exitRecord?.length > 0) {
      subCategoriesRef.current[value?.parentId || 0] = subCategoriesRef.current[value?.parentId || 0]?.filter(
        (item: any) => item?.id === value?.id
      );
    } else subCategoriesRef.current[value?.parentId || 0] = [...subCategoriesRef.current[value?.parentId || 0], value];

    form.setFieldsValue({ sub_category_id: subCategoriesRef.current });
  };
  return (
    <FilterCategoryMobileStyle>
      {/* <div className="title">{t('createPost.topic')}</div> */}
      <Form.Item name="category_id">
        {categories && (
          <TreeSelectCustom
            dropdownStyle={{ maxHeight: 315, overflow: 'auto' }}
            data={handleFormatDataTreeSelect(categories)}
            form={form}
            name="category_id"
            placeHolder={t('createPost.chooseCategory')}
            height={50}
            width="100%"
            required={false}
            valueParent={valueParent}
            childProperty={{ onPickChild: handlePickChild }}
          />
        )}
      </Form.Item>
    </FilterCategoryMobileStyle>
  );
};

export default FilterCategoryMobile;
