import styled from 'styled-components';

interface Props {
  isMobile?: boolean;
  buttonMobile?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  color?: string;
}
const ButtonCreatePostStyle = styled.div<Props>`
  height: 100%;
`;
export const PenIcon = styled.img<Props>`
  margin-right: 10px;
`;

export const ButtonStyle = styled.div<Props>`
  height: 100%;
  .new-post-button {
    background: ${(props) => props?.backgroundColor || '#fea628'};
    width: 100%;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    border-radius: 5px;
    border: none;
    font-weight: 500;
    height: ${(props) => (props?.isMobile ? '52px' : '100%')};
    font-size: 14px;
    overflow: hidden;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props?.color || 'white'};
    overflow: hidden;
    position: relative;
    :hover {
      background: ${(props) => props?.backgroundColor || '#fea628'};
      color: ${(props) => props?.color || 'white'};
    }
    .new-post-button-icon {
      position: absolute;
      width: ${(props) => (props?.isMobile || props.buttonMobile ? '58px' : '4.25vw')};
      height: ${(props) => (props?.isMobile || props.buttonMobile ? '58px' : '4.25vw')};
      top: -24%;
      right: -3%;
    }
  }
`;

export const IconEdit = styled.img`
  width: 18px;
  margin-right: 12px;
`;

export default ButtonCreatePostStyle;
