import { images } from 'assets/images-base';
import { colors } from 'common/styled-base';
import devicesBreakpoint from 'helper/devices-breakpoint';
import 'helper/i18n';
import { createGlobalStyle } from 'styled-components';
import { isMobile } from 'react-device-detect';

const StyleGlobal = createGlobalStyle<any>`
  html,
  body, html {
      --antd-wave-shadow-color:none;
      /* font-family: Roboto, "Segoe UI"; */
      font-family: Roboto, sans-serif, "Segoe UI";
      color: ${colors.second1};
      strong{
        font-weight: 500;
      }
      .ant-input {
        box-shadow: none !important;
      }
      .ant-mentions{
        box-shadow: none !important;
      }
      .ant-input-affix-wrapper-focused{
        box-shadow: none !important;
      }
      .ant-select-selector {
        border-color: ${colors.gray2} !important;
        box-shadow: none !important;
      }
      .ant-input-clear-icon{
        margin-top:-5px ;
      }
      .ant-input-clear-icon > span > svg {
        visibility: hidden;
      }
      .ant-input-clear-icon > span {
        width: 22px;
        height: 22px;
        background-image: url(${images.search.closeCircleSearch});
        background-size: cover;
      }
      .bg-admin{
        background-size: cover;
          .ant-layout {
            background: none !important;
          }
      }
      .cursor-wait { 
        cursor: wait !important;
      }

      .title-black{
        .ant-modal-title{
          color: ${colors.second1} !important;
        }
      }
      @media ${devicesBreakpoint.DEVICE_BREAKPOINTS_MAX.tabletS_640} {
        .hidden-mobile{
          display: none;
        }

      }
  }
  iframe:first-of-type {
      display: none;
  }
  .ant-drawer-body {
    overflow-x: hidden;
  }
  .opacity-0{
    opacity: 0;
  }
  .w-100{
    width: 100%;
  }
  .mr-10{
    margin-right: 10px;
  }
  .d-flex{
    display:flex;
  }
  .justify-content-center{
    justify-content: center;
  }
  .align-items-center{
    align-items: center;
  }
  .justify-content-between{
    justify-content:space-between;
  }
  .justify-content-start{
    justify-content:flex-start;
  }
  .justify-content-end{
    justify-content:flex-end;
  }
  .disabled-upload{
    .button-type-image{
      pointer-events: none;
    }
  }
  .selected-topic{
      width: ${isMobile ? '100%' : '229px'};
    }
  .ant-modal-wrap{
    overflow-y: scroll;
  }
  .confirmCustom {
      
      position: absolute;
      left: -50%;
      right: -50%;
      top: 35%;
      .ant-modal-body {
          padding: 20px;
          margin-right: 0;
      }
      .ant-modal-content {
          border-radius: 5px;
          .ant-modal-confirm-body-wrapper {
              margin-top: 0px;
              width: 100%;
              display: flex;
              height: fit-content;
              flex-direction: column;
              .ant-modal-confirm-content{
                color:#464646 !important;
                margin-top: 0;
              }
              .ant-modal-confirm-body {
                /* min-height: 40px; */
                height: fit-content;
                color:#464646 !important;
                margin-bottom: 27px;
              }
              .anticon-exclamation-circle {
                  display: none;
              }
              .ant-modal-confirm-title {
                  color:#464646 !important;
                  font-weight: 400;
                  font-size: 14px;
              }
              .ant-modal-confirm-btns {
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 0;
                  .ant-btn-default {
                      color: #bdbdbd;
                      border: none;
                      text-transform: uppercase;
                      font-size: 14px;
                      outline: none;
                      font-weight: 500;
                  }
                  .ant-btn-primary {
                      background: none;
                      border: none;
                      text-shadow: none;
                      box-shadow: none;
                      color: #fea628;
                      font-size: 14px;
                      font-weight: 500;
                  }
                  button:focus {
                      outline: none;
                  }
                  button:focus {
                      outline: none;
                  }
              }
          }
      }
  }
  .ant-empty-description {
    visibility: hidden;
  }
  .ant-empty-description:after {
    content: '${(props) => props?.noDataTable || 'noData'}';
    visibility: visible;
    display: block;
    margin-top: -25px;
    font-size: 12px;
  }
  .ant-picker-time-panel{
    display: none;
  }
  .system-modal{
    .ant-popover-inner {
      margin-right: -10px !important;
    }
  }
  .ant-popover-placement-bottom{
    position: fixed;
    .ant-popover-arrow {
      left: 87%;
      margin-right: -15px;
      
    }
    .ant-popover-inner {
      margin-right: 8px;
      .ant-popover-inner-content {
        padding: 0;
        .ant-popover-message {
          padding: 0;
          .anticon-exclamation-circle {
            display: none;
          }
          .ant-popover-message-title {
            padding-left: 0;
          }
        }
        .ant-popover-buttons {
          display: none;
        }
      }
    }
  }
 .ant-popover-placement-bottomRight {
  left: ${isMobile ? '5vw !important' : 'unset'};
  position: fixed;
  .ant-popover-arrow {
    margin-right: ${isMobile ? '72px' : '-15px'};
  }
  .ant-popover-inner {
    margin-right: ${isMobile ? 'unset' : '-75px'};
    .ant-popover-inner-content {
      padding: 0;
      .ant-popover-message {
        padding: 0;
        .anticon-exclamation-circle {
          display: none;
        }
        .ant-popover-message-title {
          padding-left: 0;
        }
      }
      .ant-popover-buttons {
        display: none;
      }
    }
  }
 }
  .table-custom {
    &.table-custom-hidden-nodata {
      .ant-empty {
          display: none;
      }
        
    }
      .ant-table-tbody > tr > td {
        border-bottom: none;
        color:${colors.second1};
      }
      .ant-empty-description {
      visibility: hidden;
      }
      .ant-empty-description:after {
        content: '${(props) => props?.noDataTable || 'noData'}';
        visibility: visible;
        display: block;
        margin-top: -25px;
        margin-left: 25px;
      }
      .ant-empty {
        .ant-empty-image {
          height: 80px;
          .ant-empty-img-simple {
            display: none;
          }
          ::after {
            content: url(${images.table.emptyTableIcon});
          }
        }
      }
      .table-striped-rows tr:nth-child(2n) td {
        background: #fbfbfb;
        height: 57px;
      }

      .ant-table-thead > tr > th {
        color:${colors.second1};
        background: #e8f0f9;
          ::before{
            display: none;
        }
      }
      table tr:nth-child(2n) td {
        background-color: #f3f3f4;
      }
      .action-row {
        display: flex;
        justify-content: flex-end;
        img {
          margin-right: 10px;
          cursor: pointer;
        }
      }
      .ant-pagination{
        align-items: center;
        a {
          color:${colors.textCaption} ;
          width: 23px;
          height: 25px;
          margin-right: 0;
          line-height: 24px;
        }
        .ant-pagination-item{
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 23px;
          width: 23px;
          height: 25px;
        }
      }
      .ant-pagination-prev {
        min-width: 20px;
        height: 20px;
        .ant-pagination-item-link {
          background-image: url(${images.table.backTable});
          background-size: cover;
          border: none;
          &:disabled{
            background-image: url(${images.table.backTableDisable});
            cursor: default;
          }
          span {
            display: none;
          }
        }
      }
      .ant-pagination-next {
        min-width: 20px;
        height: 20px;
        .ant-pagination-item-link {
          background-image: url(${images.table.nextTable});
          background-size: cover;
          border: none;
          &:disabled{
            background-image: url(${images.table.nextTableDisable});
            cursor: default;
          }
          span {
            display: none;
          }
        }
        margin-right: 22px;
      }
      .ant-pagination-item-active {
        background: #fff6e9 !important;
        border-radius: 5px;

        border: none;
        a {
          color: ${colors.mainColor2};
          font-size: 12px;
        }
      }
      .ant-pagination-item {
        background: none;
        font-weight: 500;
        border: none;
        font-size: 12px;
        min-width: 30px;
        height: 30px;
      }
      .ant-select-selection-item {
        color: ${colors.textCaption};
        outline: none;
        border: none;
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 0;
      }
      .title-pagination {
        color: ${colors.textCaption};
        font-size: 14px;
        order: -1;
      }
      .ant-pagination-options {
        order: 0;
        margin: 0;
        padding: 0;
        .ant-select-selection-search {
          background-image: url(${images.table.dropDown});
          background-size: cover;
          width: 14px;
          height: 8px;
          margin-top: 13px;
          margin-left: 15px;
          input {
            display: none;
          }
        }
        .ant-select-selector {
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 0;
          border: none;
          outline: none;
          margin-right: 32px;
          background: none;
        }
        .ant-select-arrow {
          display: none;
        }
      }
      li:not(li:first-child):not(li:last-child) {
        order: 3;
      }
      .position-absolute {
        position: absolute;
      }
      .left-20 {
        left: 20px;
      }
    }

    .modal-main-topic {
      .modal-main-topic-search {
        padding: 15px;
      }
      .ant-input {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        width: 100%;
        height: 42px;
        :hover {
          outline: none;
          border: 1px solid #d9d9d9;
        }
      }
      .ant-modal-body {
        padding: 0;
      }
      .ant-input-search-button {
        background: #c4c4c4 !important;
        width: 52px;
        height: 42px;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
      .modal-main-topic-clear-all {
        margin-top: 29px;
        text-align: end;

        padding: 0 15px 15px 15px;
        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #2C8CE4;
          cursor: pointer;
        }
      }
      .modal-main-topic-list {
        max-height: 470px;
        overflow: auto;
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }

        ::-webkit-scrollbar {
          width: 1px;
          background-color: #f5f5f5;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #555;
        }
        .modal-main-topic-list-item-parent-active {
          background-color: #FFF6E9;
        }
        .modal-main-topic-list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px dashed #E0E0E0;
          padding: 19px 0 12px 0;
          margin: 0 15px;
          .anticon-check {
            color: #FEA628
          }
        }
      }
      .modal-main-topic-button {
        margin: 42px 0 30px 0;
        padding: 0 15px;
        button {
          width: 100%;
          background: #FEA628;
          border-radius: 5px;
          color: white;
          :hover {
            outline: none;
            border: 1px solid #d9d9d9;
          }
        }
      }

    }

    .modal-button {
          margin: 42px 0 0 0;
          padding: 0 15px;
          button {
            width: 100%;
            background: #FEA628;
            border-radius: 5px;
            color: white;
            :hover {
              outline: none;
              border: 1px solid #d9d9d9;
            }
          }
      }

    .search-input {
        border-radius: 5px !important;
        .ant-input {
          border-radius: 5px 0 0 5px;
        }
        button {
          border-radius: 0 5px 5px 0 !important;
        }

        .ant-input-search-button {
          background-color: ${colors.gray2};
          border: 1px solid ${colors.gray2};
          background-image: url(${images.table.search});
          background-repeat: no-repeat;
          background-position: center;
          svg {
            /* color: white; */
            display: none;
          }
        }
    }


    .admin-post-modal-group {
      .modal-search {
        padding: 15px;
      }
      .ant-input {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        width: 100%;
        height: 42px;
        :hover {
          outline: none;
          border: 1px solid #d9d9d9;
        }
      }
      .ant-modal-body {
        padding: 0;
      }
      .ant-input-search-button {
        background: #c4c4c4 !important;
        width: 52px;
        height: 42px;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }

      .modal-clear-all {
        margin-top: 29px;
        text-align: end;

        padding: 0 15px 15px 15px;
        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #2C8CE4;
          cursor: pointer;
        }
      }
      .modal-list {
        max-height: 470px;
        overflow: auto;
        margin-top: 20px;
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }

        ::-webkit-scrollbar {
          width: 1px;
          background-color: #f5f5f5;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #555;
        }
        .modal-list-item-parent-active {
          background-color:  #FFF8EE;
        }
        .modal-list-item-parent {
          cursor: pointer;
        }
        .modal-list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px dashed #E0E0E0;
          padding: 12px 0 14px 0;
          margin: 0 15px;
          .anticon-check {
            color: #FEA628
          }
        }
      }
      .modal-button {
        margin: 42px 0 30px 0;
        padding: 0 15px;
        button {
          width: 100%;
          background: #FEA628;
          border-radius: 5px;
          color: white;
          :hover {
            outline: none;
            border: 1px solid #d9d9d9;
          }
        }
      }

    }

    /* .ant-switch-checked .ant-switch-handle::before {
      background: #fea628;
    }
    .ant-switch-checked {
      background: #fff6e9;
    } */
    .alert-comfirm-modal {
      .ant-btn-default {
        display: none;
      }
    }
    .modal-preview-template {
    .ant-modal-title {
      font-weight: 500 !important;
      font-size: 32px !important;
      line-height: 38px !important;
      text-align: center !important;
      color: #F9FBFC !important;
    }
  }
  .user-manage-group-modal-list-member {
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-header {
      .ant-modal-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #F9FBFC;
      }
    }
    
  }
  .modal-list-user-seri {
    .ant-modal-title {
      margin-left: 66px;
    }
  }
  .modal-custom-close {
    .ant-modal-content {
      border-radius: 5px;
      button {
        background: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin: 20px;
      }
    }
  }
  .modal-no-header{
    .ant-modal-content{
      border-radius: 10px;
      .ant-modal-footer{
        display: none;
      }
      .ant-modal-close{
        background-image: url(${images.detail.closeModalCr});
        width: 30px;
        height: 30px;
        margin-top: 5px;
        margin-right: 5px;
        span{
          display: none;
        }
      }
      .ant-modal-body{
        padding: 0 20px 20px 20px;
        border-radius: 10px;
      }
    }
  }
  .custom-drawer {
    .ant-drawer-content {
      overflow-x: hidden;
      .ant-drawer-wrapper-body {
        height: fit-content;
      }
    }
  } 
  .tooltip-dashboard-group {
    .ant-tooltip-inner {
      background: white;
    }
    .ant-tooltip-arrow {
      display: none;
    }
  } 
  .hide-option {
    display: none;
  }
  .mention-hashtag-create-post {
    /* &.mention-hashtag-normal-post {
      top: ${isMobile ? '580px !important' : '880px !important'};
    }
    &.mention-hashtag-series-post {
      top: ${isMobile ? '580px !important' : 'unset'};
    } */
    .ant-mentions-dropdown-menu  {

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #C4C4C4;
        border-radius: 5px;
        background-color: #f5f5f5;
      }
  
      ::-webkit-scrollbar {
        width: 2px;
        height: 0px;
        background-color: #f5f5f5;
      }
  
      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px #C4C4C4;
        background-color: #555;
      }
    }
    .ant-mentions-dropdown-menu  {
      max-width: 350px;
      max-height: 160px;
    }
  }
  .modal-reason {
    .ant-modal-body {
      padding: 24px 20px;
    }
  }
  .text-align-center {
    text-align: center;
  }
`;
export default StyleGlobal;
