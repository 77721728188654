import images from 'assets/images-base';
import ModalNormal from 'components/common/modal';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CategoryFilterHome from '../CategoryFilterHome/CategoryFilterHome';
import { CategoryButton, CategoryWrapper, ContainerModal, FilterMobileWrapper, Nodata } from './filterMobileStyle';

interface IFilter {
  created_at: string;
  hiddenSub: boolean;
  id: number;
  isChecked: boolean;
  name: string;
  subLabel: ISubFilter[];
  sub_category_quantity: number;
}
interface ISubFilter {
  created_at: string;
  hiddenSub: boolean;
  id: number;
  isChecked: boolean;
  name: string;
}

interface IPropFilter {
  dataFilter: IFilter[];
  setDataFilter: (value: any) => void;
  getSubCategory: ({ idParent, callback }: { idParent: number; callback: (res: any) => void }) => void;
}

const FilterMobile = ({ dataFilter, setDataFilter, getSubCategory }: IPropFilter) => {
  const { t } = useTranslation();
  const [isShow, setIsShown] = useState<boolean>(false);
  const CategoryChecked = useMemo(() => {
    const arr: string[] = [];
    dataFilter.forEach((element: IFilter) => {
      if (element.isChecked) {
        arr.push(` ${element.name}`);
      }
      element?.subLabel?.forEach((elementSub: ISubFilter) => {
        if (elementSub.isChecked) {
          arr.push(` ${elementSub.name}`);
        }
      });
    });
    return arr;
  }, [dataFilter]);

  return (
    <FilterMobileWrapper>
      <CategoryButton onClick={() => setIsShown(!isShow)} borderYellow={CategoryChecked?.length > 0}>
        {CategoryChecked?.length > 0 ? (
          <CategoryWrapper>{CategoryChecked.join()}</CategoryWrapper>
        ) : (
          <Nodata>{t('createPost.category.placeHolder')}</Nodata>
        )}
        <img src={images.masterdata.arrowBottom} alt={t('createPost.category.placeHolder')} />
      </CategoryButton>
      <ModalNormal visible={isShow} setVisible={setIsShown} width={643} title={t('home.category')} padding="0">
        <ContainerModal>
          <CategoryFilterHome
            setDataFilter={setDataFilter}
            dataFilter={dataFilter}
            getSubCategory={getSubCategory}
            disabled={false}
          />
        </ContainerModal>
      </ModalNormal>
    </FilterMobileWrapper>
  );
};

export default FilterMobile;
