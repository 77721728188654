import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Space = styled.div`
  flex: 1;
`;

const ButtonHome = styled(Button)`
  font-size: 2em;
  font-weight: 700;
  height: 2em;
  border-radius: 0.2em;
`;

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Space />
      <h1>{t('notFound.error404')}</h1>
      <ButtonHome type="primary" onClick={() => navigate('/')}>
        {t('notFound.backToHome')}
      </ButtonHome>
      <Space />
    </Wrapper>
  );
};

export default NotFound;
