import AvatarText from 'components/AvatarText';
import { isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { colors } from 'common/styled-base';
import { Col, Divider, Image } from 'antd';
import { Link } from 'react-router-dom';

const PostWrapper = styled.div<any>`
  background-color: white;
  /* padding-top: 15px; */
  .ant-divider-horizontal {
    margin: 5px 0;
  }
  .cursor {
    cursor: pointer;
  }
  display: flex;
  align-items: flex-start;
  margin-bottom: ${(props) => (props?.isHiddenAvatar ? '20px' : '30px')};
`;

export const PostCreatedAtStyle = styled(AvatarText)<any>`
  margin-top: ${isMobile ? '7px !important' : '3px'};
  margin: ${isMobile ? '0' : '4px 10px'};
  margin-bottom: ${isMobile ? '5px' : '4px'};
  .text-avatar {
    color: rgba(144, 144, 144, 1);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-left: ${isMobile ? '0' : '7px'};
  }
  &.hidden-dot {
    margin-left: 0 !important;
  }
`;

export const PostAuthorStyle = styled(AvatarText)`
  max-width: 270px;
  order: ${isMobile ? '2' : 'unset'};
  .text-avatar {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    color: #2c8ce4;
    flex: 1;

    word-break: break-all;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const DividerStyle = styled(Divider)`
  margin-bottom: 0;
`;

export const FooterPostStyle = styled(Col)`
  margin-top: ${isMobile ? '10px' : 'unset'};
`;

export const ContentFooterStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterPostInfoStyle = styled.div`
  display: flex;
  justify-content: ${isMobile ? 'unset' : 'flex-start'};
  flex-direction: ${isMobile ? 'column-reverse' : 'unset'};
  .group-name {
    cursor: pointer;
  }
  .profile {
    cursor: pointer;
  }
  .profile-name-disabled {
    color: rgba(144, 144, 144, 1);
  }
`;

export const InfoDateStyle = styled.div`
  display: flex;
  align-items: center;
  order: ${isMobile ? '3' : 'unset'};
`;

export const SeriesStyle = styled(AvatarText)`
  .title-series {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #909090;
  }
  .series-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #223354;
    cursor: pointer;
  }
`;

export const FooterPostActionStyle = styled.div`
  display: flex;
  align-items: center;
  text-align: end;
  justify-content: flex-end;
  .post-pin {
    min-width: 108px;
  }
`;

const PostActionSavePostStyle = styled.div`
  width: 18px;
  cursor: pointer;
`;

export const AnswerStyle = styled(Col)<any>`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  margin-top: 10px;
  /* padding-left: 50px; */
`;

export const AnswerBoxStyle = styled.div`
  overflow: hidden;
  height: 100%;
  transition: all 0.2s linear;
  .answer-component {
    padding: 10px 0;
  }
  &.answers-box-active {
    height: 0;
  }
`;

export const AnswerTotalRecordStyle = styled.div`
  cursor: pointer;
  span {
    margin-right: 10px;
    font-size: 12px;
    line-height: 16px;
    color: #464646;
  }
  .answer-total-record-active-btn {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }
  .answer-total-record-btn {
    transition: all 0.3s ease-out;
  }
`;

export const TextCategoryMobileStyle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  color: #919191 !important;
  display: ${isMobile ? 'block' : 'none'};
`;

export const TextCategoryPcStyle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: ${isMobile ? 'none' : 'flex'};
  align-items: center;
  color: #919191;
`;
export const CategoryOneStyle = styled.span`
  margin-right: 10px;
`;

export const CategoryTwoStyle = styled(AvatarText)`
  margin-top: 1px;
  .ant-avatar-circle {
    margin-top: 1px;
  }
`;
export const TextTitleStyle = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.homeGroupTextBody};

  @media (max-width: 768px) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ContentPostStyle = styled(Col)`
  display: ${isMobile ? 'none' : 'unset'};
`;

export const TextContentStyle = styled.div`
  margin-top: 0;

  img {
    display: none;
  }
  /* .custom-html-style { */
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.text};

  display: -webkit-box !important;
  -webkit-line-clamp: 3; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* } */
`;

export const ThumbnailImageStyle = styled(Col)`
  text-align: end;
`;

export const PostCommentImageStyle = styled(Image)`
  width: ${isMobile ? '141px' : '202px'};
  height: ${isMobile ? '87px' : '120px'};
  border-radius: 5px;
  object-fit: cover;
`;

const UserNameStyle = styled.div<any>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (!props?.disabled && !props?.errorUser ? '#2c8ce4' : 'rgba(144, 144, 144, 1)')};
  cursor: pointer;

  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AvatarStyle = styled(AvatarText)`
  cursor: pointer;
  img {
    border: #2c8ce4 1px solid;
    border-radius: 50%;
    width: ${isMobile ? '18px' : '40px'};
    height: ${isMobile ? '18px' : '40px'};
    object-fit: cover;
  }
`;

const DateStyle = styled.div``;

const GroupNameStyle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2c8ce4;
  font-weight: 500;
  max-width: 100px;
  cursor: pointer;

  word-break: break-all;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LeftContentStyle = styled.div`
  flex: 1;
  padding: 0 10px 0 3px;
  .search-word {
    font-weight: bold;
    color: #2c8ce4;
  }
`;

const HeaderContentStyle = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const HeaderTextStyle = styled.div`
  display: flex;
  align-items: center;
  max-width: ${isMobile ? 'unset' : '256px'};
  width: ${isMobile ? '100%' : 'unset'};
`;

const TrongStyle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0 4px;
  font-weight: 500;
`;

const QuestionTypeStyle = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${isMobile ? '10px' : 'unset'};
`;

const QuestionAndAnswerStyle = styled.div`
  background: #fea628;
  border-radius: 29px;
  padding: 2px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  color: #223354;
`;

const AnsweredStyle = styled.div`
  background: #2ecc71;
  border-radius: 29px;
  padding: 2px 10px;
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f9fbfc;
`;

const TitleStyle = styled(Link)`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #223354;
  cursor: pointer;
  width: fit-content;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  :hover {
    color: #223354;
  }
`;

const ContentStyle = styled.div`
  display: flex;
  align-items: ${isMobile ? 'flex-start' : 'center'};
  flex-direction: ${isMobile ? 'column' : 'unset'};
`;

const StatisticalStyle = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  /* max-width: 97px; */
`;

const StatisticalTextStyle = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: #919191;
`;

const CategoryStyle = styled.div`
  margin-left: ${isMobile ? '0' : '10px'};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #223354;
  flex: 1;
  margin-top: ${isMobile ? '10px' : 'unset'};
  height: 16px;

  word-break: break-all;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListTagStyle = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TagItemStyle = styled.div<any>`
  margin-top: 5px;
  padding: 2px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #464646;
  background: #e5e6ec;
  border-radius: 5px;
  margin-right: ${(props) => props?.marginRight || '5px'};

  :hover {
    background-color: #c4c4c4;
    cursor: pointer;
  }
`;

const ContentPost = styled.div`
  .search-word {
    font-weight: bold;
    color: #2c8ce4;
  }
`;

const SaveCountStyle = styled(AvatarText)`
  .ant-image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const IconViewStyle = styled(AvatarText)`
  .ant-avatar {
    img {
      margin-bottom: 1.5px;
    }
  }
`;

const SeriesFrame = styled.div<any>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (isMobile && props?.isParentSeries && !isTablet ? '5px' : 0)};
  margin-top: ${isTablet ? '1px' : 'unset'};
`;

const SeriesNameFrame = styled(AvatarText)``;

const SeriesName = styled.div`
  color: rgba(144, 144, 144, 1);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
  display: flex;
  align-items: center;
  span {
    color: rgba(44, 140, 228, 1);
    max-width: 100px;
    cursor: pointer;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
`;

const StickerSeries = styled.div`
  padding: 2px 10px;
  background: #2c8ce4;
  border-radius: 29px;
  display: flex;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #f9fbfc;
  }
  img {
    height: 12.5px;
    width: 10px;
  }
`;

const LineCategory = styled.span`
  padding: 0 5px;
`;

const CategoryText = styled.span`
  cursor: pointer;
`;

const FrameAvatar = styled.div`
  display: fle;
  img {
    height: 40px !important;
    width: 40px !important;
    object-fit: cover;
  }
`;

export {
  UserNameStyle,
  AvatarStyle,
  DateStyle,
  GroupNameStyle,
  LeftContentStyle,
  HeaderContentStyle,
  HeaderTextStyle,
  TrongStyle,
  QuestionTypeStyle,
  QuestionAndAnswerStyle,
  AnsweredStyle,
  TitleStyle,
  StatisticalStyle,
  ContentStyle,
  StatisticalTextStyle,
  CategoryStyle,
  PostActionSavePostStyle,
  ListTagStyle,
  TagItemStyle,
  ContentPost,
  SaveCountStyle,
  IconViewStyle,
  SeriesFrame,
  SeriesName,
  SeriesNameFrame,
  StickerSeries,
  LineCategory,
  CategoryText,
  FrameAvatar,
};
export default PostWrapper;

export function getIndicesOf(searchStr: string, str: string, caseSensitive?: any) {
  const searchStrLen = searchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  const indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while (str.indexOf(searchStr, startIndex) > -1) {
    indices.push(str.indexOf(searchStr, startIndex));
    startIndex = str.indexOf(searchStr, startIndex) + searchStrLen;
  }
  return indices;
}
