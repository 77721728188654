import styled from 'styled-components';

const SelectMemberStyle = styled.div`
  width: 100%;
`;

const TagItemStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 4.5px 8.5px 4.5px 12px;
  background: #e5e6ec;
  border-radius: 16px;
  margin: 5px 10px 5px 0;
`;

const TagNameStyle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #464646;
  margin-right: 9px;
`;

const TagIconStyle = styled.img`
  width: 18px;
  height: 18px;
  object-fit: cover;
  cursor: pointer;
`;

const OptionItemStyle = styled.div`
  display: flex;
  align-items: center;
`;

const InfoStyle = styled.div`
  margin-left: 9px;
`;

const TextStyle = styled.div`
  font-weight: 400;
`;

export { SelectMemberStyle, TagItemStyle, TagNameStyle, TagIconStyle, OptionItemStyle, InfoStyle, TextStyle };
