import { isMobile } from 'react-device-detect';
import AvatarText from 'components/AvatarText';
import { colors } from 'common/styled-base';
import styled from 'styled-components';

const StyledGeneralGroup = styled.div`
  .post-view-more {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 30px;
    .scroll {
      cursor: pointer;
    }
  }
  .button-view {
    height: 36px;
    width: 664px;
    border-radius: 5px;
    color: ${colors.textCaption};
    background: #f3f3f4;
    margin: auto;
    :focus,
    :hover {
      color: ${colors.textCaption};
      border-color: #f3f3f4;
    }
  }
`;

const TabPostGroupStyle = styled.div`
  .ant-tabs-nav {
    width: ${isMobile ? '100vw' : 'calc(268px + 50vw)'};
    margin-left: ${isMobile ? '-20px' : 'unset'};
    border-top: 1px solid #e0e0e0;
    .ant-tabs-nav-list {
      height: 44px;
      /* width: 100%; */
      padding: ${isMobile ? '0 20px' : 'unset'};
      .tab-col-name {
        font-size: 14px;
      }
    }
  }
  .ant-tabs-content-holder {
    div {
      :focus {
        outline: none;
      }
    }
  }
`;

const TitleStyle = styled(AvatarText)``;

export { TabPostGroupStyle, TitleStyle };

export default StyledGeneralGroup;
