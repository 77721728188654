import { Tabs } from 'antd';
import { images } from 'assets/images-base';
import { colors } from 'common/styled-base';
import ButtonSave from 'components/Cms/Save';
import ImageError from 'components/common/image-error/ImageError';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import useGetListGroupJoin from 'data/GroupGeneral/useGetGroupJoin';
import useGetListGroupManage from 'data/GroupGeneral/useGetManageGroupList';
import CreateGroup from 'pages/CreateGroup';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IValues, NoDataStyle } from './SideBar';

interface IProps {
  classNameSideBar?: string;
  person_id?: number;
  isShowCreateGroup?: boolean;
}

enum ETab {
  MANAGE = '1',
  JOIN = '2',
}

const { TabPane } = Tabs;
const SideBarMobile: React.FC<IProps> = ({ person_id, isShowCreateGroup = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [params, setParams] = useState({ page: 1, per_page: 10, person_id });
  const [loadingManage, setLoadingManage] = useState<any>([]);
  const [loadingJoin, setLoadingJoin] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState<ETab>(ETab.MANAGE);

  const { data: listGroupManage } = useGetListGroupManage(params);
  const { data: listGroupJoin } = useGetListGroupJoin(params);

  const totalGroup = useRef<{ manage: number; join: number }>({ manage: 0, join: 0 });

  const handleShowModalAddGroup = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (listGroupManage && listGroupManage?.data?.manage_groups.length > 0) {
      totalGroup.current.manage = listGroupManage?.data?.total;
      setLoadingManage([...listGroupManage?.data?.manage_groups]);
    }
  }, [listGroupManage]);

  useEffect(() => {
    if (listGroupJoin && listGroupJoin?.data?.joining_groups.length > 0) {
      totalGroup.current.join = listGroupJoin?.data?.total;
      setLoadingJoin([...listGroupJoin?.data?.joining_groups]);
    }
  }, [listGroupJoin]);

  useEffect(() => {
    if (person_id) {
      setParams({ ...params, person_id });
      setCurrentTab(ETab.MANAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person_id]);

  const listGroup = useRef<any>(null);

  useEffect(() => {
    const groupItem = document.querySelector('.group-item');
    const offsetGroupItem = 10;
    if (groupItem && listGroup.current) {
      listGroup.current.style.maxWidth = `${(groupItem.scrollWidth + offsetGroupItem) * 5}px`;
    }
  }, [listGroupManage]);

  const handleScroll = (e: any) => {
    const boxOffsetLeft = listGroup.current?.scrollWidth;
    if (
      Math.floor(e.target.offsetWidth + e.target.scrollLeft) === boxOffsetLeft &&
      listGroupManage?.data?.total > params.page * params.per_page
    ) {
      setParams({ ...params, per_page: params.per_page + 20 });
    }
  };

  useEffect(() => {
    const groupItem = document.querySelector('.group-item');
    const offsetGroupItem = 10;
    if (groupItem && listGroup.current) {
      listGroup.current.style.maxWidth = `${(groupItem.scrollWidth + offsetGroupItem) * 5}px`;
    }
  }, [listGroupJoin]);

  const handleScrollJ = (e: any) => {
    const boxOffsetLeft = listGroup.current?.scrollWidth;
    if (
      Math.floor(e.target.offsetWidth + e.target.scrollLeft) === boxOffsetLeft &&
      listGroupJoin?.data?.total > params.page * params.per_page
    ) {
      setParams({ ...params, per_page: params.per_page + 20 });
    }
  };

  return (
    <StyledSideBar>
      {listGroupManage?.data?.total === 0 && listGroupJoin?.data?.total === 0 ? (
        <NoDataStyle>
          <span>{t('group.noDataGroupManage')}</span>
        </NoDataStyle>
      ) : (
        <CustomTabs
          activeKey={currentTab}
          onChange={(tab: string) => {
            setCurrentTab(tab === ETab.MANAGE ? ETab.MANAGE : ETab.JOIN);
          }}
        >
          {listGroupManage?.data?.total > 0 && (
            <CustomTabPane
              tab={[
                <span className="title-group">{t('group.groupManage')}</span>,
                <span className="total-group">{totalGroup.current.manage ?? 0}</span>,
              ]}
              key="1"
            >
              <div className="group-manage" ref={listGroup} onScroll={handleScroll}>
                {listGroupManage?.data?.manage_groups?.length !== 0 ? (
                  loadingManage?.map((item: IValues) => (
                    <div
                      className="group-item"
                      key={item.id}
                      onClick={() => navigate(`/group/user-manage-group/${item?.id}`)}
                    >
                      <ImageError src={item.avatar_image} height={64} width={64} />
                      <span className="name-group">{item.name}</span>
                    </div>
                  ))
                ) : (
                  <NoDataStyle>
                    <span>{t('group.noDataGroupManage')}</span>
                  </NoDataStyle>
                )}
              </div>
            </CustomTabPane>
          )}

          {listGroupJoin?.data?.total > 0 && (
            <CustomTabPane
              tab={[
                <span className="title-group">{t('group.groupJoin')}</span>,
                <span className="total-group">{listGroupJoin?.data?.total ?? 0}</span>,
              ]}
              key="2"
            >
              <div className="group-join" ref={listGroup} onScroll={handleScrollJ}>
                {listGroupJoin?.data?.joining_groups?.length !== 0 ? (
                  loadingJoin?.map((item: IValues) => (
                    <div
                      className="group-item"
                      key={item.id}
                      onClick={() => navigate(`/group/group-joining/${item?.id}`)}
                    >
                      <ImageError src={item.avatar_image} height={64} width={64} />
                      <span className="name-group">{item.name}</span>
                    </div>
                  ))
                ) : (
                  <NoDataStyle>
                    <span>{t('group.noDataGroupJoin')}</span>
                  </NoDataStyle>
                )}
              </div>
            </CustomTabPane>
          )}
        </CustomTabs>
      )}

      {isShowCreateGroup && (
        <div className="button">
          <ButtonSave
            type="submit"
            label={t('group.addGroup')}
            icon={images.plus}
            height={45}
            click={handleShowModalAddGroup}
            className="button-add"
          />
        </div>
      )}

      <SpaceStyle padding="15px" />
      {openModal && <CreateGroup openModal={openModal} setOpenModal={setOpenModal} />}
    </StyledSideBar>
  );
};
export { SideBarMobile };

const CustomTabs = styled(Tabs)`
  font-size: 0.9em;
  .ant-tabs-nav {
    height: 100%;
    display: flex;
    justify-content: center;
    .ant-tabs-nav-wrap {
      background: none;
      flex-direction: column;
      justify-content: center;
    }
  }
  .ant-tabs-nav-list {
    justify-content: space-around;
  }

  .ant-tabs-tab {
    font-size: 1.15em;
    color: ${colors.textCaption};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.colorText};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #000000;
    pointer-events: none;
  }
  .ant-tabs-tab-active {
    .title-group {
      color: ${colors.colorText} !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
    }
  }

  .title-group {
    color: #909090;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
  .total-group {
    padding: 3px 8px;
    background-color: ${colors.second2};
    border-radius: 5px;
    margin-left: 5px;
  }
`;

const CustomTabPane = styled(TabPane)`
  background-color: white;
`;

const StyledSideBar = styled.div`
  .avatar {
    height: 64px;
    width: 64px;
    border-radius: 100%;
  }
  .group-manage,
  .group-join {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100% !important;
  }
  .group-manage::-webkit-scrollbar-track,
  .group-join::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    width: 0px;
    height: 0px;
    display: none;
  }

  .group-manage::-webkit-scrollbar,
  .group-join::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #f5f5f5;
    display: none;
  }

  .group-manage::-webkit-scrollbar-thumb,
  .group-join::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    width: 0px;
    height: 0px;
  }

  .group-manage::-webkit-scrollbar-corner {
    width: 0px;
    height: 0px;
  }
  .group-item {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 75px;
  }
  .name-group {
    font-size: 12px;
    width: 100%;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: ${colors.colorText};
    word-break: break-word;
    margin-top: 16.78px;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .button-add {
    width: 100%;
  }
  .ant-tabs-nav-list {
    margin-right: auto;
  }
`;
