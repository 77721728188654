import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import images from 'assets/images-base';
import { ETopHotTag } from 'common';
import defaultConst from 'common/default';
import ButtonCancel from 'components/Cms/Cancel';
import ButtonSave from 'components/Cms/Save';
import { listHashtag } from 'data/useCreatePost';
import useWindowSize from 'hooks/useWindow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CategoryFilterHome from './CategoryFilterHome/CategoryFilterHome';
import {
  ButtonWrapper,
  Container,
  FilterContentWrapper,
  RangeContainer,
  RangeWrapper,
  Title,
  TopicFilterContainer,
  TopicWrapper,
} from './filterContentStyle';
import FilterRangeHome from './FilterHome/FilterRangeHome';
import FilterMobile from './FilterMobile/FilterMobile';
import TagCustomHome from './TagCustomHome';
import TimeFilter from './TimeFilter/TimeFilter';

export interface IDateFilter {
  start_date: string | null;
  end_date: string | null;
}

interface IPropFilter {
  dataFilter: any;
  rangeList: CheckboxValueType[];
  dateFilter: IDateFilter;
  selectedTags: number[];
  onFilter: () => void;
  resetData: () => void;
  setRangeList: (value: CheckboxValueType[]) => void;
  setDataFilter: (value: any) => void;
  setDateFilter: (value: IDateFilter) => void;
  getSubCategory: ({ idParent, callback }: { idParent: number; callback: (res: any) => void }) => void;
  setSelectedTags: (value: number[]) => void;
}

const FilterContent = ({
  rangeList,
  dataFilter,
  dateFilter,
  selectedTags,
  onFilter,
  resetData,
  setRangeList,
  setDataFilter,
  setDateFilter,
  getSubCategory,
  setSelectedTags,
}: IPropFilter) => {
  const { t } = useTranslation();
  const { data: hashtag } = listHashtag({ top: ETopHotTag.TOP_IN_ALL_POSTS, page: 1, per_page: 20 });
  const defaultRangeList = defaultConst.RANGE_LIST;
  const { isMobile } = useWindowSize();

  return (
    <FilterContentWrapper>
      <Container isMobile={isMobile}>
        <TopicWrapper isMobile={isMobile}>
          <Title>{t('home.category')}</Title>
          {isMobile && (
            <FilterMobile setDataFilter={setDataFilter} dataFilter={dataFilter} getSubCategory={getSubCategory} />
          )}
          {!isMobile && (
            <TopicFilterContainer>
              <CategoryFilterHome
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
                getSubCategory={getSubCategory}
                disabled={false}
              />
            </TopicFilterContainer>
          )}
        </TopicWrapper>
        <RangeWrapper isMobile={isMobile}>
          <Title>{t('home.range')}</Title>
          <RangeContainer>
            <FilterRangeHome
              title={t('search.range')}
              typeTopic={defaultRangeList}
              checkedList={rangeList}
              setCheckedList={setRangeList}
            />
            <TimeFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
            <TagCustomHome
              title="Hashtag"
              fromHome
              data={hashtag?.hashtags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </RangeContainer>
        </RangeWrapper>
      </Container>
      <ButtonWrapper isMobile={isMobile}>
        <ButtonCancel
          type="reset"
          icon={images.group.iconReset}
          width={220}
          height={45}
          label={t('group.reset')}
          className="right-20"
          noBg
          click={resetData}
        />
        <ButtonSave
          type="submit"
          icon={images.table.tickCircleTable}
          width={220}
          height={45}
          label={t('home.apply')}
          className="left-20"
          click={onFilter}
        />
      </ButtonWrapper>
    </FilterContentWrapper>
  );
};

export default FilterContent;
