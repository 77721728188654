import { colors } from 'common/styled-base';
import styled from 'styled-components';

const TabCustomStyle = styled.div<any>`
  font-size: 0.9em;
  /* width: 100vw; */
  .ant-tabs-nav {
    height: ${(props) => props?.style?.height || '100%'};
  }

  .ant-tabs-tab {
    font-size: 1.15em;
    color: ${colors.textCaption};
    padding: 8px 14px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.second1};
    font-weight: 500;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #000000;
    pointer-events: none;
  }

  .tab-badge-count {
    span {
      background-color: #e8f0f9;
      padding: 3px 8px 3px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.second1};
      border-radius: 5px;
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
`;

const TabRowStyle = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
  }
`;

const TabColNameStyle = styled.div`
  /* margin-right: 5px; */
  font-size: 14px;
`;

export { TabRowStyle, TabColNameStyle };

export default TabCustomStyle;
