import { colors } from 'common/styled-base';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const HomePageWrapper = styled.div`
  /* width: 100%;
  overflow: auto; */
  .image-fixed-home-page {
    background-image: url(${(props) => props.theme});
    height: 1000px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
  }
  .home-page-main-body {
    margin-top: ${(props) => (props.theme?.isMobile ? '40px' : '29px')};
    .ant-tabs-nav {
      width: ${isMobile ? '100vw' : 'unset'};
      margin-left: ${isMobile ? '-20px' : 'unset'};
      border-top: 1px solid #e0e0e0;
      .ant-tabs-nav-wrap {
        margin: 0 20px;
      }
    }
    .ant-tabs-content-holder {
      div {
        :focus {
          outline: none;
        }
      }
    }
  }
`;

export const BackToTopStyle = styled.div`
  text-align: end;
  width: 100%;
  margin: auto;
  margin-top: ${isMobile ? '15px' : 'unset'};
  background-color: white;

  img {
    position: fixed;
    bottom: 0;
    right: 60px;
    margin-bottom: 20px;

    background: #f3f3f4;
    border-radius: 5px;
    z-index: 10;
    cursor: pointer;
    margin-right: ${isMobile ? '10px' : '15px'};
  }
`;
export const TitleListPost = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 13px;
    font-weight: 500;
    color: ${colors.mainColor2};
    font-size: 20px;
  }
`;

export const FrameTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 24px;
`;

export const LinkToListPost = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2c8ce4;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default HomePageWrapper;
