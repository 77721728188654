import images from 'assets/images-base';
import * as React from 'react';
import { PluginComponent } from 'react-markdown-editor-lite';
import { AlignIcon } from './commonStyle';

export default class AlignLeft extends PluginComponent {
  // 这里定义插件名称，注意不能重复
  static pluginName = 'align-left';
  // 定义按钮被防止在哪个位置，默认为左侧，还可以放置在右侧（right）
  static align = 'left';
  // 如果需要的话，可以在这里定义默认选项
  static defaultConfig = {
    start: 0,
  };

  constructor(props: any) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.editor.insertText(`<left>${this.editor.getSelection()?.text}</left>`, true);
  }

  render() {
    return (
      <AlignIcon
        style={{ margin: '0 0 0 10px' }}
        onClick={() => this.handleClick()}
        src={images.editorPlugin.AlignLeft}
        alt=""
      />
    );
  }
}
