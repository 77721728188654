import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  allComment: [],
  isLoading: false,
};

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    storeSetComment: (state: any, { payload }: PayloadAction<any>) => {
      state.allComment = payload;
    },
    storeSetIsLoading: (state: any, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;
    },
  },
});

export const { storeSetComment, storeSetIsLoading } = commentSlice.actions;

export default commentSlice.reducer;
