import images from 'assets/images-base';
import useWindowSize from 'hooks/useWindow';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonCreatePostStyle from './buttonCreatePostStyle';
import BorderButton from 'components/header/BorderButton';
import { DISABLE_SERIES } from 'config';

interface IModalTypePost {
  setVisible: (value: boolean) => void;
}

interface IBorderButtonStyle {
  height?: string;
}
interface Props {
  onClickNormal?: () => void;
  onClickSeries?: () => void;
  buttonMobile?: boolean;
  className?: string;
  disabled?: boolean;
  backgroundColor?: string;
  color?: string;
  groupId?: number | null;
  borderButtonStyle?: IBorderButtonStyle;
}
const ButtonCreatePost: React.FC<Props> = ({ onClickNormal, onClickSeries, className, backgroundColor, ...props }) => {
  const { t } = useTranslation();
  const modalTypePostRef = useRef<IModalTypePost>();

  const { isMobile } = useWindowSize();

  return (
    <ButtonCreatePostStyle isMobile={isMobile} className={className}>
      {/* <ModalTypePost
        ref={modalTypePostRef}
        onClickNormal={() => {
          toCreateNormal();
        }}
        onClickSeries={() => {
          toCreateSeries();
        }}
      /> */}
      <BorderButton
        text={t('header.createPost')}
        iconUrl={images.home.iconEditOrange}
        colorHover={isMobile ? 'unset' : '#e19223'}
        color={isMobile ? 'unset' : '#fea628'}
        onClick={() => {
          if (DISABLE_SERIES) {
            if (onClickNormal) onClickNormal();
          } else {
            modalTypePostRef.current?.setVisible(true);
          }
        }}
        style={props?.borderButtonStyle}
      />
    </ButtonCreatePostStyle>
  );
};

export default ButtonCreatePost;
