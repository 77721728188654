import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import ListQuickSearch from '../widget/listQuickSearch';
import ListQuickSearchGroups from '../widget/listQuickSearchGroup';
import { CustomBackGroundHeaderSearch, TitleQuickSearch } from './styled';
import PostQuickSearch from '../widget/PostQuickSearch/PostQuickSearch';

export interface IPostQuickSearch {
  title: string;
  user: {
    name: string;
    id: number;
  };
  id: number;
  group_id: number;
}
interface Props {
  active: number | null;
  totalQuickLength: number;
  keyWord: string;
  selectedSearchTags: number[];
  setSelectedSearchTags: (param: number[]) => void;
  userQuick: any;
  groupsQuick: any;
  idSearch?: string | null;
  postList: IPostQuickSearch[];
  isLoading: boolean;
}

const FastSearch = (props: Props) => {
  const { t } = useTranslation();
  const total = useMemo(() => {
    const userLength = props.userQuick?.users?.length;
    const groupLength = props.groupsQuick?.groups?.length;
    const postLength = props?.postList?.length;
    return userLength + groupLength + postLength;
  }, [props]);

  return (
    <CustomBackGroundHeaderSearch>
      {props?.isLoading ? (
        <div className="not-data">
          <Spin />
        </div>
      ) : (
        <>
          <TitleQuickSearch>{t('search.suggestions')}</TitleQuickSearch>
          {total === 0 && <div className="not-data">{t('search.noData')}</div>}
          {total !== 0 && (
            <>
              {props?.postList?.length > 0 && (
                <PostQuickSearch title={t('group.post')} postList={props.postList} active={props.active || null} />
              )}
              {props.userQuick?.users?.length > 0 && (
                <ListQuickSearch
                  title={t('search.user')}
                  className="styleHotSearch"
                  listApi={props.userQuick?.users}
                  active={props.active ? props.active - props.postList.length : null}
                />
              )}
              {!Number(props.idSearch) && props.groupsQuick?.groups?.length > 0 && (
                <ListQuickSearchGroups
                  title={t('search.group')}
                  className="styleHotSearch"
                  listApi={props.groupsQuick?.groups}
                  active={props.active ? props.active - props.userQuick?.users?.length - props.postList.length : null}
                />
              )}
            </>
          )}
        </>
      )}
    </CustomBackGroundHeaderSearch>
  );
};

export default FastSearch;
