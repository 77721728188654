import React from 'react';
import { Col, Row, Tag } from 'antd';
import HotTagWrapper, { ListHashTagStyle, TextTag } from './styled';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Title } from '../filterContentStyle';

interface IValue {
  id: number;
  name: string;
}
interface Props {
  data?: IValue[];
  className?: string;
  onClickTag?: (tagName: number) => void;
  showTitle?: boolean;
  preventClick?: boolean;
  title?: string;
  selectedTags: number[];
  fromHome?: boolean;
  setSelectedTags: (value: number[]) => void;
}

const TagCustomHome: React.FC<Props> = ({
  data,
  className,
  onClickTag,
  showTitle = true,
  title = '',
  preventClick = false,
  selectedTags,
  fromHome,
  setSelectedTags,
}) => {
  const { t } = useTranslation();
  const handleClick = (id: number, name: string) => {
    if (selectedTags?.includes(id)) {
      selectedTags.splice(selectedTags.indexOf(id), 1);
    } else {
      selectedTags.push(id);
    }
    if (onClickTag) {
      onClickTag(id);
      return;
    }
    setSelectedTags([...selectedTags]);
  };
  return (
    <HotTagWrapper className={className || ''}>
      <Row>
        {showTitle && (
          <Title>
            <div>{title}</div>
          </Title>
        )}

        <Col span={24} className="list-item">
          <ListHashTagStyle fromHome={fromHome}>
            {!isEmpty(data) ? (
              data?.map((item: IValue, index: number) => {
                return (
                  <div className="" onClick={() => !preventClick && handleClick(item.id, item.name)} key={item.id}>
                    <Tag
                      className="tags"
                      color={onClickTag === undefined && selectedTags.includes(item.id) ? '#c4c4c4' : '#e5e6ec'}
                    >
                      <TextTag fromHome={fromHome}>{item.name}</TextTag>
                    </Tag>
                  </div>
                );
              })
            ) : (
              <Col span={24} className="empty-data">
                {t('common.noData')}
              </Col>
            )}
          </ListHashTagStyle>
        </Col>
      </Row>
    </HotTagWrapper>
  );
};

export default TagCustomHome;
