import { sendPost } from 'api/axios';
import { useQuery } from 'react-query';

export interface IParamPostTopKeyWord {
  per_page?: number;
  page?: number;
  day_ago?: number;
}

const bodyHostSearch = {
  size: 0,
  query: {
    range: {
      created_at: {
        gte: 'now-30d',
        lte: 'now',
      },
    },
  },
  aggs: {
    group_by_quantity: {
      terms: {
        field: 'value.keyword',
        size: 100000000,
      },
      aggs: {
        total_cnt: {
          sum: {
            field: 'quantity',
          },
        },
        sales_bucket_sort: {
          bucket_sort: {
            sort: [{ total_cnt: { order: 'desc' } }],
            size: 10,
          },
        },
      },
    },
  },
  track_total_hits: true,
};
const useSearchTopKeyWord = () =>
  useQuery(
    ['get_list_search_top_keyword'],
    async () => {
      const res = await sendPost(`/top-keyword`, bodyHostSearch);
      return res?.aggregations?.group_by_quantity?.buckets;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export default useSearchTopKeyWord;
