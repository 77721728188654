import styled from 'styled-components';

const DatePickerOneWrapper = styled.div<any>`
  .ant-picker {
    width: 205px;
    height: ${(props) => props?.style?.height || '44px'};
    border-radius: 5px;
    .ant-picker-range-separator {
      padding: 0;
    }
  }
  .ant-picker-input {
    z-index: 9;
    .ant-picker-suffix {
      .ant-avatar {
        border-radius: 0 !important;
      }
    }
    input {
      ::placeholder {
        color: #909090;
      }
    }
  }
  &.date-picker-custom {
    position: relative;
    .date-picker-body-active {
      border: #fea628 1px solid;
    }
    .ant-picker-focused {
      box-shadow: none;
    }
    .floating-label {
      font-size: 14px;
      color: #909090;
      position: absolute;
      pointer-events: none;
      top: 9px;
      background: white;
      padding: 4px;
      left: 12px;
      transition: all 0.2s ease;
      z-index: -1;
    }
    .floating-label-active {
      top: -15px !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #fea628;
    }
  }
`;

export default DatePickerOneWrapper;
