import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

interface IEditorContext {
  modalPreview: boolean;
  setModalPreview: Dispatch<SetStateAction<boolean>>;
}

export const EditorContext = createContext<IEditorContext>({ modalPreview: false, setModalPreview: () => {} });

const EditorProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [modalPreview, setModalPreview] = useState(false);
  return <EditorContext.Provider value={{ modalPreview, setModalPreview }}>{children}</EditorContext.Provider>;
};

export default EditorProvider;
