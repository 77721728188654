/* eslint-disable react-hooks/exhaustive-deps */
import { IPostQuickSearch } from 'components/SearchUI/component/FastSearch';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PostQuickSearchWrapper, PostWrapper, Title } from './postQuickSearchStyle';

interface IPropPostQuickSearch {
  title: string;
  active: number | null;
  postList: IPostQuickSearch[];
}

const PostQuickSearch = ({ title, active, postList }: IPropPostQuickSearch) => {
  const navigate = useNavigate();

  const toDetailPost = (detailData: IPostQuickSearch) => {
    if (!detailData?.group_id) {
      navigate(`/home/detail-post/${detailData.id}`);
    } else {
      navigate(`/group/detail-group/${detailData.group_id}/${detailData.id}`);
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        const findElement: any = active ? postList[active - 1] : null;
        if (findElement && active) {
          toDetailPost(findElement);
        }
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [active]);

  function PostItem({ title, name, postElement }: { title: string; name: string; postElement: IPostQuickSearch }) {
    const toProfile = () => {
      navigate(`/home/my-page/${postElement.user.id}`);
    };
    return (
      <PostWrapper>
        <div onClick={() => toDetailPost(postElement)}>{title}</div>
        <span onClick={toProfile}>{name}</span>
      </PostWrapper>
    );
  }
  return (
    <PostQuickSearchWrapper>
      <Title>{title}</Title>
      {postList?.map((postElement: IPostQuickSearch, index: number) => (
        <div className={`${active && active === index + 1 ? 'bg-gray' : ''}`} key={String(index)}>
          <PostItem title={postElement?.title} name={postElement.user.name} postElement={postElement} />
        </div>
      ))}
    </PostQuickSearchWrapper>
  );
};

export default PostQuickSearch;
