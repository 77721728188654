import { Avatar, Button } from 'antd';
import styled from 'styled-components';

interface Props {
  width?: string | number;
  height?: string | number;
  backGroundColor?: string;
  borderRadius?: number | string;
  color?: string;
  showIcon?: boolean;
  padding?: number[];
  isLoading?: boolean;
  margin?: string;
  flex?: number;
}
const ButtonIconStyle = styled.div<Props>`
  width: ${(props) => (typeof props?.width === 'number' ? `${props?.width}px` : props?.width)};
  height: ${(props) => (typeof props?.height === 'number' ? `${props?.height}px` : props?.height)};
  flex: ${(props) => props?.flex || 'unset'};
  button:hover {
    border: none;
  }
  margin: ${(props) => (props?.margin ? props?.margin : 'unset')};
  /* .disabled {
    background: #c4c4c4 !important;
    border: none !important;
    :hover {
      color: white;
    }
  } */
`;

const ButtonParentStyle = styled(Button)<any>`
  width: 100%;
  height: 100%;
  border-radius: ${(props) =>
    typeof props?.borderRadius === 'number' ? `${props?.borderRadius}px` : props?.borderRadius};
  color: ${(props) => props?.color};
  background-color: ${(props) => props?.backGroundColor};
  padding: ${(props) => (props?.padding ? props?.padding?.map((item: number) => item + 'px').join(' ') : 'unset')};
  border: ${(props) => props?.border || 'unset'} !important;
  display: flex !important;
  align-items: center !important;
  .ant-btn-loading-icon {
    margin-left: ${(props) => (props.isLoading ? 'auto' : 'unset')};
  }
  &.ant-btn[disabled] {
    background: #c4c4c4;
    border: none !important;
  }

  :hover,
  :focus {
    background-color: ${(props) => props?.backGroundColor};
    border: ${(props) => props?.border || 'unset'} !important;
  }
`;

const AvatarStyle = styled(Avatar)<any>`
  margin-left: ${(props) => (props.isLoading ? '7.5px' : 'auto')};
  margin-right: 7.5px;
`;

const TextStyle = styled.span<any>`
  margin-right: auto;
  font-weight: ${(props) => props?.fontWeight || '400'};
  margin-left: ${(props) => (props?.showIcon ? 'unset' : props.isLoading ? '7.5px' : 'auto')};
  color: ${(props) => (props?.disabled ? '#909090 !important' : props?.color)};
  font-size: ${(props) => (props?.fontSize ? props?.fontSize : '14px')};
`;

export { AvatarStyle, TextStyle, ButtonParentStyle };

export default ButtonIconStyle;
