import { Avatar } from 'antd';
import { sendPut } from 'api/axiosNotifications';
import { ESystemId, EViewNotice } from 'common';
import defaultConst from 'common/default';
import { IResSystem } from 'data/notification/useListSystem';
import { INoticeParams, IResNotice } from 'data/notification/useNotice';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import useWindowSize from 'hooks/useWindow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  CountNotification,
  LeftItem,
  MobileCount,
  MobileWrapperSystem,
  SystemItem,
  SystemRightAvatar,
  SystemWrapper,
  Title,
} from './SystemStyle';

interface ISystem {
  count: number | null;
  systemId: ESystemId.ME | ESystemId.BO | ESystemId.RECRUIT | ESystemId.WORKFLOW | ESystemId.WIKI | ESystemId.PROJECT;
  isView: number;
}

interface ISystemProps {
  listSystem: IResSystem[] | undefined;
  paramsNotice: INoticeParams;
  setParamsNotice: (params: INoticeParams) => void;
  setListNoticeState: (list: IResNotice[] | null) => void;
}
function System({ listSystem, paramsNotice, setParamsNotice, setListNoticeState }: ISystemProps) {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const QueryClient = useQueryClient();
  const userId = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.user_id;

  const selectSystem = (id: ESystemId.ME | ESystemId.BO | ESystemId.RECRUIT | ESystemId.WORKFLOW | ESystemId.WIKI | ESystemId.PROJECT) => {
    if (id === paramsNotice?.system_id) {
      return;
    }
    setListNoticeState(null);
    setParamsNotice({
      ...paramsNotice,
      system_id: id,
      page: 1,
      size: 20,
    });
  };

  const Item = ({ count, systemId, isView }: ISystem) => {
    const systemObject = defaultConst.SYSTEM_SELECT[systemId];

    const { mutate: viewSystem } = useMutation(
      async () => {
        return sendPut('/update-view', {
          system_id: systemId,
          user_id: userId,
        });
      },
      {
        onSuccess: async () => {
          QueryClient.refetchQueries(['get_list_system']);
        },
        onError: () => {
          /* TODO document why this method 'onError' is empty */
        },
      }
    );

    const checkView = () => {
      if (isView === EViewNotice.NOT_VIEW && count && count > 0) {
        viewSystem();
      }
    };

    return systemObject ? (
      <SystemItem isSelect={systemId === paramsNotice.system_id} onClick={checkView}>
        <LeftItem>
          <Avatar size={40} src={systemObject.avatar} />
          {count && isMobile ? <MobileCount>{40}</MobileCount> : <div />}
          {!isMobile && (
            <SystemRightAvatar>
              <div>{t(`notification.${systemObject.name}`)}</div>
              <div>{t(`notification.${systemObject.desc}`)}</div>
            </SystemRightAvatar>
          )}
        </LeftItem>
        {count && !isMobile ? <CountNotification>{count || ''}</CountNotification> : <div />}
      </SystemItem>
    ) : null;
  };

  return (
    <SystemWrapper>
      <Title>{t('notification.system')}</Title>
      <MobileWrapperSystem>
        {listSystem?.map((element: IResSystem, index: number) => {
          if (element?.system_id) {
            return (
              <div key={String(index)} onClick={() => selectSystem(element?.system_id)}>
                <Item isView={element?.is_viewed} count={element?.total} systemId={element.system_id} />
              </div>
            );
          }
          return <div />;
        })}
      </MobileWrapperSystem>
    </SystemWrapper>
  );
}

export default System;
