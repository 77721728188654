import Axios from 'axios';
import Cookies from 'js-cookie';
import { history } from '../App';
import { ServerErrorCode } from './networkConstant';
import NetworkError from 'helper/NetworkError';

const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

const axiosInstanceNoCustomNotify = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

const axiosInstanceWithDefaultHeader = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: process.env.REACT_APP_BASE_URL_ELASTIC_SEARCH,
});

const axiosCustomInstance = (fullUrl: string | any) =>
  Axios.create({
    timeout: 3 * 60 * 1000,
    baseURL: fullUrl,
  });

axiosInstance.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstanceNoCustomNotify.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstanceWithDefaultHeader.interceptors.request.use((config) => {
  const token = `${process.env.REACT_APP_ACCOUNT}:${process.env.REACT_APP_PASSWORD}`;
  const encodedToken = Buffer.from(token).toString('base64');
  config.headers.Authorization = 'Basic ' + encodedToken;
  return config;
});
const logout = () => {
  Cookies.remove('token');
  Cookies.remove('refreshToken');
  history.push('/');
};
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: any) => {
    const originalConfig = error.config;
    switch (error.response.status) {
      case ServerErrorCode.EXPECTATION:
      case ServerErrorCode.BAD_REQUEST:
      case ServerErrorCode.BAD_GATEWAY:
      case ServerErrorCode.FORBIDDEN:
      case ServerErrorCode.INTERNAL_SERVER_ERROR:
      case ServerErrorCode.NOT_FOUND:
      case ServerErrorCode.TIMEOUT:
      case ServerErrorCode.WRONG_DATA_REQUEST:
        if (process.env.REACT_APP_ENV === 'noShow') {
          NetworkError.instance?.show(error.response.status, error.response);
        }
        return Promise.reject(error);
      case ServerErrorCode.UNAUTHORIZED:
        (window as any).location.href = process.env.REACT_APP_API_DOMAIN_LOGIN;
        break;
    }

    const refreshToken = Cookies.get('refreshToken');
    if (!refreshToken) {
      logout();
      return Promise.reject(error);
    }
    return Axios.post(`${process.env.REACT_APP_API_DOMAIN}/auth/request-access-token`, {
      refreshToken,
    })
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data;
          Cookies.set('token', data.token);
          originalConfig.headers.Authorization = `Bearer ${data.token}`;
          return Axios(originalConfig);
        } else {
          logout();
          return Promise.reject(error);
        }
      })
      .catch(() => {
        logout();
        return Promise.reject(error);
      });
  }
);

export const sendGet = (url: string, params?: any) => axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any) =>
  axiosInstance.post(url, params, { params: queryParams }).then(
    (res) => res.data,
    (error) => {
      // add for upload file
      return Promise.reject(error);
    }
  );
export const sendPut = (url: string, params?: any) => axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url: string, params?: any) => axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url: string, params?: any) => axiosInstance.delete(url, { params }).then((res) => res.data);
export const fetchUserInfo = () => {
  const api = axiosCustomInstance(process.env.REACT_APP_API_GET_USER_INFO + '/user/auth?system_id=3');
  api.interceptors.request.use(
    (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
      return config;
    },
    (error) => {
      switch (error.response.status) {
        case ServerErrorCode.EXPECTATION:
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.BAD_GATEWAY:
        case ServerErrorCode.FORBIDDEN:
        case ServerErrorCode.INTERNAL_SERVER_ERROR:
        case ServerErrorCode.NOT_FOUND:
        case ServerErrorCode.TIMEOUT:
        case ServerErrorCode.WRONG_DATA_REQUEST:
          if (process.env.REACT_APP_ENV === 'noShow') {
            NetworkError.instance?.show(error.response.status, error.response);
          }
          return Promise.reject(error);
        case ServerErrorCode.UNAUTHORIZED:
          (window as any).location.href = process.env.REACT_APP_API_DOMAIN_LOGIN;
          break;
      }
      return Promise.reject(error);
    }
  );
  return api.get('', {}).then((res) => res.data);
};

export const sendPostWithDefaultHeader = (url: string, params?: any, queryParams?: any) =>
  axiosInstanceWithDefaultHeader.post(url, params, { params: queryParams }).then(
    (res) => res.data,
    (error) => {
      // add for upload file
      return Promise.reject(error);
    }
  );
export const sendGetAndDownload = (url: string, params?: any) =>
  axiosInstance.get(url, { params, responseType: 'blob' }).then((res) => res.data);
export const sendGetAndDownloadFile = (url: string, params?: any) =>
  axiosInstance.get(url, { params }).then((res) => res.data);

export const sendGetWithNoNotify = (url: string, params?: any) =>
  axiosInstanceNoCustomNotify
    .get(url, { params })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));

export const sendPostWithNoNotify = (url: string, params?: any, queryParams?: any) =>
  axiosInstanceNoCustomNotify.post(url, params, { params: queryParams }).then(
    (res) => res.data,
    (error) => {
      // add for upload file
      return Promise.reject(error);
    }
  );

export const sendPupWithNoNotify = (url: string, params?: any) =>
  axiosInstanceNoCustomNotify
    .put(url, params)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));
