import { Modal } from 'antd';
import styled from 'styled-components';
import { colors } from 'common/styled-base';
import { images } from 'assets/images-base';

interface IProps {
  padding?: string;
  isBottom?: boolean;
  marginTop?: string;
}

const ModalWrapper = styled(Modal)<IProps>`
  margin-top: ${(props) => (props?.marginTop ? '55px' : 'unset')};
  .ant-modal-header {
    background-color: #222433;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding: 0 0 0 1.563rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .ant-modal-content {
    position: ${(props) => (props?.isBottom ? 'fixed' : 'unset')};
    width: ${(props) => (props?.isBottom ? '100vw' : 'unset')};
    height: ${(props) => (props?.isBottom ? '90vh' : 'unset')};
    left: ${(props) => (props?.isBottom ? '0' : 'unset')};
    bottom: ${(props) => (props?.isBottom ? '0' : 'unset')};
  }

  .ant-modal-close {
    height: 3.125rem;
    display: flex;
    align-items: center;
    .ant-modal-close-x {
      color: ${colors.mainColor2};
      background-image: url(${images.layout.closeNewModal});
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      span {
      }
      svg {
        display: none;
      }
    }
  }
  .ant-modal-title {
    color: #ffffff;
    font-size: 20px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding: ${(props) => (props?.padding ? `${props.padding}` : '24px')};
  }
  .ant-modal-content {
    border-radius: 5px;
    position: relative;
  }
`;

export default ModalWrapper;
