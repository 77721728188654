import { colors } from 'common/styled-base';
import { Checkbox } from 'antd';
import styled from 'styled-components';

const { Group } = Checkbox;

interface IProps {
  isAutoCheck?: string;
  disabled?: boolean;
}

const StyledCheckBox = styled(Group)<IProps>`
  span {
    color: ${(props) => !props?.disabled && '#464646'};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${(props) => (props?.disabled ? '#fed49a' : colors.mainColor100)};
    border-radius: 5px;
    width: 18px;
    height: 18px;
    border: 1px solid ${(props) => (props?.disabled ? '#fed49a' : colors.mainColor100)} !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white !important;
  }
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-radius: 6px;
    border: 0.094em solid #c4c4c4;
  }
  .ant-checkbox-inner::after {
    left: 25%;
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${colors.mainColor100};
    border-radius: 5px;
  }
`;

export { StyledCheckBox };
