import React, { Suspense, useState, useLayoutEffect } from 'react';
import { createBrowserHistory } from 'history';
import RootWrapper from './wrappers/RootWrapper';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import configs, { ENV_NAME } from 'config';
import './styles/scss/abstract/_variables.scss';
import StyleGlobal from 'styles';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { store } from 'rootStore';

export const history = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1 * 60 * 60 * 1000, // cache for 1 day
      retry: false,
    },
  },
});

interface CustomRouterInterface {
  history: any;
}
const CustomRouter: React.SFC<CustomRouterInterface> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

function App() {
  const { t } = useTranslation();
  (window as any).OneSignal = (window as any)?.OneSignal || [];
  (window as any).OneSignal = (window as any)?.OneSignal || [];
  (window as any).OneSignal = (window as any)?.OneSignal || [];
  (window as any).OneSignal = (window as any)?.OneSignal || [];
  (window as any).OneSignal = (window as any)?.OneSignal || [];

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CustomRouter history={history}>
          <Suspense fallback={null}>
            <StyleGlobal noDataTable={t('admin.noData')} />
            <RootWrapper />
          </Suspense>
        </CustomRouter>
        {configs.APP_ENV !== ENV_NAME.PRODUCT && <ReactQueryDevtools initialIsOpen={false} />}
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
