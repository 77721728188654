import devicesBreakpoint from 'helper/devices-breakpoint';
import styled from 'styled-components';

const StyleCreateGroup = styled.div`
  @media ${devicesBreakpoint.DEVICE_BREAKPOINTS_MAX.tabletS_640} {
    width: 100%;
  }
`;

const StyleButton = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
`;

const ContentStyle = styled.div`
  padding: 20px 25px 0 25px;
  .ant-radio-group {
    .ant-radio {
      .ant-radio-inner {
        background: #f3f3f4;
      }
    }
  }
`;

export { ContentStyle, StyleButton };

export default StyleCreateGroup;
