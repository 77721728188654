import styled from 'styled-components';

const AllGroupSystemStyle = styled.div``;

const FrameGroups = styled.div``;

const FrameGroupItem = styled.div`
  background: #fbfbfb;
  border-radius: 5px;
  padding: 10px;
  :hover {
    background-color: #f3f7fb;
  }
`;

const GroupInfoItemStyle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

const FrameNameAndAvatar = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
`;

const NameGroupStyle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #223354;
  cursor: pointer;
  /* max-width: 190px; */

  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const CreatedAtGroup = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
  cursor: auto;
`;

const FrameCountGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: auto !important;
`;

const TextCount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
`;

const CountStyle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #464646;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const DotStyle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #b0b0b0;
`;

export {
  AllGroupSystemStyle,
  FrameGroups,
  GroupInfoItemStyle,
  FrameNameAndAvatar,
  NameGroupStyle,
  CreatedAtGroup,
  FrameCountGroup,
  TextCount,
  CountStyle,
  FrameGroupItem,
  FlexBox,
  DotStyle,
};
