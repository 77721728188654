const size = {
  mobileS: '320px',

  mobileM: '375px',

  mobileL: '428px',

  tabletS: '640px',

  tablet: '768px',

  tabletL: '992px',

  laptop: '1024px',

  laptopL: '1440px',

  desktop: '2560px',
};

const sizeNumber = {
  mobileS: 320,

  mobileM: 375,

  mobileL: 428,

  tabletS: 640,

  tablet: 768,

  tabletL: 992,

  laptop: 1024,

  laptopL: 1440,

  desktopS: 1920,

  desktop: 2560,
};

const customBreakPoint = (sizeCustom = 0, type = 'max') => ` (${type}-width: ${sizeCustom}px)`;

const DEVICE_BREAKPOINTS_MAX = {
  mobileS_320: ` (max-width: ${size.mobileS})`,

  mobileM_375: ` (max-width: ${size.mobileM})`,

  mobileL_425: ` (max-width: ${size.mobileL})`,

  tablet_992: ` (max-width: ${size.tabletL})`,

  tablet_768: ` (max-width: ${size.tablet})`,

  tabletS_640: ` (max-width: ${size.tabletS})`,

  laptop_1024: ` (max-width: ${size.laptop})`,

  laptopL_1440: ` (max-width: ${size.laptopL})`,

  desktop_2560: ` (max-width: ${size.desktop})`,
};

const DEVICE_BREAKPOINTS_MIN = {
  mobileS_320: ` (min-width: ${size.mobileS})`,

  mobileM_375: ` (min-width: ${size.mobileM})`,

  mobileL_425: ` (min-width: ${size.mobileL})`,

  tablet_992: ` (min-width: ${size.tabletL})`,

  tablet_768: ` (min-width: ${size.tablet})`,

  tabletS_640: ` (min-width: ${size.tabletS})`,

  laptop_1024: ` (min-width: ${size.laptop})`,

  laptopL_1440: ` (min-width: ${size.laptopL})`,

  desktop_2560: ` (min-width: ${size.desktop})`,
};

const devicesBreakpoint = {
  DEVICE_BREAKPOINTS_MAX,

  DEVICE_BREAKPOINTS_MIN,

  CUSTOM_BREAKPOINT: customBreakPoint,

  sizeNumber,
};

export default devicesBreakpoint;
