import { useState } from 'react';

const useHEIC = () => {
  const [isShowPopUpCropHEIC, setIsShowPopUpCropHEIC] = useState<boolean>(false);
  const [urlHeic, setUrlHeic] = useState<string>('');

  return { isShowPopUpCropHEIC, setIsShowPopUpCropHEIC, urlHeic, setUrlHeic };
};

export default useHEIC;
