export const colorsTag = [
  'red',
  'volcano',
  'orange',
  'green',
  'gold',
  'lime',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  'magenta',
];
