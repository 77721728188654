/* eslint-disable react/jsx-no-undef */
import { Avatar, Divider, Tooltip } from 'antd';
import images from 'assets/images-base';
import { colors } from 'common/styled-base';
import { AuthorAvatar, AvatarDefault } from 'pages/Home/TopRanking/AuthorRanking/AuthorRankingStyle';
import React from 'react';
import styled from 'styled-components';

interface IItemGroup {
  id: number;
  name: string;
  avatar_image: string;
  isBorderYellow?: boolean;
}

const GroupList: React.FC<IItemGroup> = (value: IItemGroup) => {
  return (
    <Styled className="group-item">
      <div className="item-group">
        <AuthorAvatar size={37} isTop={value.isBorderYellow}>
          <Avatar
            size={34}
            src={value?.avatar_image || images.profile.avatarDefault}
            icon={<AvatarDefault alt="" src={images.profile.avatarDefault} />}
          />
        </AuthorAvatar>
        <Tooltip placement="rightBottom" title={value?.name}>
          <div className="name-group">{value?.name}</div>
        </Tooltip>
      </div>
      <Divider dashed className="divider-group-home" />
    </Styled>
  );
};
export default GroupList;

const Styled = styled.div`
  .item-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
    align-items: center;
  }
  .avatar-group {
    width: 34px;
    height: 34px;
    border-radius: 100%;
  }
  .name-group {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: ${colors.colorText};
    word-break: break-word;
    flex: 1;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-divider-horizontal {
    margin: 10px 0;
  }
`;
