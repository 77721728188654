const getPathIcon = (image: string) => {
  return `/assets/icons/${image}`;
};

const getPathLogo = (image: string) => {
  return `/assets/logos/${image}`;
};

const getPathImages = (image: string) => {
  return `/assets/images/${image}`;
};

export { getPathIcon, getPathLogo, getPathImages };
