import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { Button, Modal, Checkbox, Radio, Form, Row, Input } from 'antd';
import { colors } from 'common/styled-base';

interface IProps {
  size?: number;
  bgColor?: string;
  isMobile?: boolean;
  isDisable?: boolean;
}

export const StyleMarginQuickSearch = styled(Row)<any>`
  div {
    padding: 8px 15px;
    width: 100%;
  }
  .bg-gray {
    background-color: ${colors.grey3};
  }
  .box-image-search-fast {
    display: flex;
    align-items: center;
  }
`;
export const StyleNameSearchQuick = styled(Button)`
  background: none;
  border: none;
`;
export const StyleButtonQuickSearch = styled.div<any>`
  cursor: pointer;
  color: #fff;
  border: 1px solid ${(props) => (props?.theme === true ? colors.gray2 : '#fea628')};
  background: #fea628;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  width: 464px;
  height: 45px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
`;
export const StyleRowQuickSearch = styled.div`
  width: 100%;
  height: 95px;
  overflow: scroll;
`;
export const StyleAvatarSearch = styled.img`
  width: 34.17px;
  height: 34.17px;
  border-radius: 50%;
  .focus {
    border: 1px solid #000;
  }
`;
export const CustomFooterModalSearch = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: ${isMobile ? 'wrap' : 'unset'};
  padding: ${isMobile ? '20px' : 'unset'};
  button {
    width: ${isMobile ? '100% !important' : 'unset'};
    border-radius: 5px;
    margin-right: ${isMobile ? '0 !important' : '10px'};
  }
  .button-reset {
    margin: ${isMobile ? '30px 0 24px 0' : '50px 0'};
  }
  .button-search {
    margin: ${isMobile ? '0 0 20px 0' : '50px 0'};
  }
`;
interface IPropsPadding {
  notPaddingVertical?: boolean;
}
export const PaddingModalSearch = styled.div<IPropsPadding>`
  padding: ${(props) => (props.notPaddingVertical || isMobile ? '0 6px' : '10px')};
  margin: ${(props) => (props.notPaddingVertical ? '-8px 0px 8px 6px' : 'unset')};
`;
export const CustomBackGroundHeaderSearch = styled.div`
  background: #fff;
  border-radius: 5px;
  padding-bottom: 10px;
  .styleHashTagSearch {
    padding-left: 10px;
  }
  .not-data {
    text-align: center;
    color: ${colors.textCaption};
    padding-top: 40px;
    margin-bottom: 40px;
  }
`;
export const TitleQuickSearch = styled.p`
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #223354;
  font-weight: 500;
  padding-top: 30px;
  padding-left: 15px;
  font-size: 18px;
`;
export const CustomCheckbox = styled.div`
  border-bottom: 1px dashed #8080806e;
  padding: 10px;
  .ant-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #464646;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    display: inline-flex;
    align-items: baseline;
    line-height: unset;
    cursor: pointer;
    padding-left: 10px;
  }
`;
export const CustomRow = styled(Row)`
  .ant-radio-group {
    display: ${isMobile ? 'flex' : 'unset'};
    flex-direction: ${isMobile ? 'column' : 'unset'};
  }
  .item-checked {
    background-color: #223354;
    color: #fff;
  }
`;
export const FixForm = styled(Form.Item)`
  .ant-form-item {
    width: 650px;
  }
`;

export const StyleButtonSearch = styled.button`
  background: none;
  border: none;
  color: orange;
`;
export const StyleDivTitle = styled.div`
  font-family: Roboto;
  font-size: 16px;
  color: #464646;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 10px;
`;
export const StyleButtonFooterModal = styled(Button)`
  margin-bottom: 50px;
  margin-top: 50px;
`;
export const StyleButtonDeleteAll = styled(Button)`
  border: none;
  background: none;
  color: #909090;
`;

export const StyleInputSearch = styled(Input)`
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding-left: 10px;
  border: none;
  border-color: none;
  border-radius: 0px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #fbfbfb;
  input {
    background-color: #fbfbfb;
  }
  :focus {
    outline: none;
  }
`;
export const StyleButtonSearchInput = styled.button<IProps>`
  background: ${(props) => (props.isDisable ? '#C4C4C4' : '#fea628')};
  cursor: ${(props) => (props.isDisable ? 'default' : 'pointer')};
  width: 67px;
  height: 50px !important;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  border: none;
`;
export const StyleBodyReport = styled.div<IProps>`
  padding-left: ${(props) => (props.isMobile ? 1 : 12)}%;
`;
export const StyledH3Report = styled.div`
  width: 126px;
  height: 21px;
  font-size: 18px;
  margin-bottom: 35px;
  color: #223354;
`;
export const FixButton = styled(Radio.Button)`
  font-size: 14px;
  color: #464646;
  border-color: #c9d5eb;
  border-left-width: 1px;
`;
export const StyledModal = styled(Modal)<IProps>`
  width: 100%;
  height: auto;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
    display: ${isMobile ? 'none' : 'unset'};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }
  .AvatarQuickSearch {
    width: 34.17px;
    height: 34.17px;
  }
  .ant-modal-body {
    padding: 1px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-modal-content {
    background: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    position: fixed;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    background: #fea628;
    color: #fff;
  }
  /* .ant-checkbox-group {
    padding: 10px;
  } */
  .styleHistorySearch {
    width: (94px);
    height: 32px;
    border-radius: 16px;
    color: #223354;
    background: #f3f3f4;
    margin: 5px;
  }
  .styleHotSearch {
    padding: 0 9px;
    font-size: 14px;
    background: none;
    border: none;
  }
  .styleTopic {
    font-size: 14px;
    width: (94px);
    height: 32px;
    border-radius: 16px;
    margin: 5px;
  }
  .styleHashtag {
    font-size: 14px;
    width: (94px);
    height: 32px;
    border-radius: 16px;
    margin: 5px;
    background: #fea628;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-btn {
    color: #fea628;
    border-color: #fea628;
    background: #fff6e9;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    width: 220px;
    height: 45px;
    margin-right: 10px;
  }
  .ant-modal-footer > .ant-btn {
    width: 220px;
    height: 45px;
    background: #fff6e9;
    color: #fea628;
  }
  .ant-modal-content {
    width: ${(props) => (props.isMobile ? 90 : 100)}%;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-modal-header {
    background: #223354;
  }
  .ant-modal-title {
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    word-wrap: break-word;
  }
  .ant-btn-primary {
    color: #fff;
    border-color: #fea628;
    background: #fea628;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    width: 220px;
    height: 45px;
  }
`;
export const StyledCheckbox = styled(Checkbox.Group)`
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: orange;
    border-color: palevioletred;
  }
`;

export const SearchActionStyle = styled.div`
  display: flex;
  button {
    height: 49px;
  }
  form {
    width: 100%;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;

export const IconSearch = styled.img`
  width: 20px;
`;
export const Title = styled.div`
  padding-top: 20px;
  font-weight: 500;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 10px;
  color: #464646;
`;
export const HashTagWrapper = styled.div`
  margin-left: 20px;
`;
export const IconSearchHeader = styled.img`
  margin-right: 14px;
`;
