import devicesBreakpoint from 'helper/devices-breakpoint';
import styled from 'styled-components';

interface Props {
  isMobile?: boolean;
}
const ListSuggestCategoryWrapper = styled.div<Props>`
  max-width: 801px;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #f5f5f5;
    display: ${(props) => (props?.isMobile ? 'none' : 'unset')};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
  @media ${devicesBreakpoint.DEVICE_BREAKPOINTS_MAX.tabletS_640} {
    width: 315px;
    max-width: 100%;
  }
`;

export default ListSuggestCategoryWrapper;
