import styled from 'styled-components';

export const Wrapper = styled.div<any>`
  width: 100%;
  /* height: 40vh; */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-slider {
    width: 50%;
  }
`;

export const PreviewFrame = styled.div`
  width: calc(100% - 2em);
  height: 40vh;
  position: relative;
  overflow: hidden;
  margin: 1em;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0.2em;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonZoom = styled.div`
  font-size: 1.5em;
  width: fit-content;
  height: fit-content;
  font-weight: 500;
  cursor: pointer;
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: gray;
  user-select: none;
`;

export const Line = styled.div`
  width: 100%;
  background-color: #c4c4c4;
  height: 1px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.5em 1em;
`;

export const Blank = styled.div<any>`
  width: ${(props) => props.width}em;
`;
