import styled from 'styled-components';

interface IProps {
  size?: number;
  bgColor?: string;
}

const StyleRadio = styled.div<IProps>`
  .ant-radio-inner,
  .ant-radio-inner::after,
  .ant-radio-wrapper {
    font-size: ${(props) => (props?.size ? `${props?.size}px` : '14px')};
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #ffce00;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #c4c4c4;
  }
  .ant-radio-inner {
    width: 1.25em;
    height: 1.25em;
    background-color: transparent;
    border-color: #c4c4c4;
  }
  .ant-radio-inner::after {
    width: 1.75em;
    height: 1.75em;
    margin-top: -0.875em;
    margin-left: -0.875em;
    background-color: #ffce00;
  }
  .ant-radio-wrapper {
    color: #464646;
    display: block;
    font-weight: 400;
  }
  .ant-radio-inner::after {
    background-color: ${(props) => (props?.bgColor ? `${props?.bgColor}` : '#fea628')};
  }
  .ant-radio {
    width: 20px;
    height: 20px;
  }
`;
export default StyleRadio;
