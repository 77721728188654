import React, { FunctionComponent, useRef, useState } from 'react';
import DatePickerOneWrapper from './styled';
import { DatePicker, Image } from 'antd';
import { images } from 'assets/images-base';
import { Moment } from 'moment';
import { isEmpty } from 'lodash';

interface IStyle {
  height?: string;
  width?: string;
}
interface Props {
  placeholder?: string;
  className?: string;
  onChange?: (a: Moment, b: string) => void;
  suffixIcon?: string;
  format?: string;
  value?: any;
  style?: IStyle;
}
const DatePickerOne: FunctionComponent<Props> = ({
  placeholder,
  className,
  suffixIcon,
  format,
  onChange,
  value,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const valueRef = useRef<boolean>(false);
  return (
    <DatePickerOneWrapper style={props?.style} className={`date-picker-custom ${className || ''}`}>
      <DatePicker
        placeholder={placeholder}
        format={format || 'YYYY-MM-DD'}
        suffixIcon={<Image preview={false} src={suffixIcon || images.adminPost.calendar} />}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          if (!valueRef.current) setIsFocus(false);
        }}
        value={value}
        onChange={(a: any, b: string) => {
          if (isEmpty(a)) {
            setIsFocus(false);
            valueRef.current = false;
          } else {
            valueRef.current = true;
          }
          if (onChange) onChange(a, b);
        }}
        className={`date-picker-body${isFocus ? ' date-picker-body-active' : ''}`}
        {...props}
      />
      <label className={`floating-label ${isFocus ? ' floating-label-active' : ''}`}>
        {!isFocus ? placeholder : ''}
      </label>
    </DatePickerOneWrapper>
  );
};

export default DatePickerOne;
