import { colors } from 'common/styled-base';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const ListNotificationWrapper = styled.div`
  padding: 13px 10px 13px 10px;
  width: ${isMobile ? '100%' : '63%'};
  height: ${isMobile ? '60vh' : '680px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const NoticeItem = styled.a<any>`
  cursor: pointer;
  color: ${colors.second1};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px dashed #e0e0e0;
  width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 10px;
  .color-yellow {
    background-color: ${colors.mainColor2};
    margin-right: 6px;
  }
  :hover {
    background-color: ${colors.grey3};
  }
`;
export const SpaceNotRead = styled.div`
  width: 16px;
`;
export const RightNotice = styled.div`
  margin-left: 10px;
  width: fit-content;
  line-height: 18px;
  width: ${isMobile ? '80%' : '85%'};
  div:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
  }
  div:nth-child(2) {
    color: #464646;
  }
  div:nth-child(3) {
    font-size: 12px;
    color: ${colors.textCaption};
  }
`;
export const SpinWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeightSpace = styled.div`
  height: 20px;
`;
export const Nodata = styled.div`
  margin-top: 50px;
  text-align: center;
  color: #e74c3c;
`;
export const AvatarDefault = styled.img`
  background-color: ${colors.grey3};
`;
