/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { sendGet, sendPost } from 'api/axios';

import images from 'assets/images-base';
import BorderButton from 'components/header/BorderButton';
import TagCustomHome from 'components/FilterHome/FilterContent/TagCustomHome';

import { listHashtag } from 'data/useCreatePost';
import useSearchTopKeyWord from 'data/home/useSearchTopKey';

import { Form, message } from 'antd';
import { isMobile } from 'react-device-detect';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { EFollow, EIsSeries, EKeyCode, ETopHotTag, ETypeView } from 'common';

import {
  StyledModal,
  CustomBackGroundHeaderSearch,
  StyleInputSearch,
  StyleButtonSearchInput,
  SearchActionStyle,
  IconSearch,
  Title,
  HashTagWrapper,
} from './styled';
import ListButton from '../widget/listbutton';
import FastSearch from './FastSearch';
import { useForm } from 'antd/lib/form/Form';

interface IBorderButtonStyle {
  height?: string;
}

const SearchUi = ({
  isOpen = false,
  className = '',
  setIsOpen,
  hiddenButton = false,
  ...props
}: {
  isOpen?: boolean;
  className?: string;
  setIsOpen?: (isOpen: boolean) => void;
  hiddenButton?: boolean;
  borderButtonStyle?: IBorderButtonStyle;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const [form] = useForm();
  const [keyWord, setKeyWord] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSearchTags, setSelectedSearchTags] = useState<number[]>([]);
  const [active, setActive] = useState<number | null>(null);

  const [postList, setPostList] = useState<any>([]);
  const [groupsQuick, setGroupsQuick] = useState<any>();
  const [userQuick, setUserQuick] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hintSearchFast = 1;
  const callPostList = async (callback: (e: any) => void) => {
    const res = await sendGet('/post/search', {
      per_page: 7,
      page: 1,
      keyword: keyWord,
      category_ids: [],
      is_following: EFollow.ALL,
      type_view: ETypeView.HOME,
      is_series: EIsSeries.SERI_OR_NOT,
      hint: hintSearchFast,
    });
    callback(res?.data);
  };

  const callGroupList = async (callback: (e: any) => void) => {
    const res = await sendGet('/group/search', { keyword: keyWord, per_page: 3 });
    callback(res?.data);
  };
  const callUserList = async (callback: (e: any) => void) => {
    const res = await sendGet('/user/search', {
      per_page: 3,
      keyword: keyWord,
      group_id: null,
      is_following: 2,
      page: 1,
    });
    callback(res?.data);
  };
  useEffect(() => {
    if (!keyWord.trim()) {
      return;
    }
    setIsLoading(true);
    const timeOutId = setTimeout(async () => {
      let sum = 0;
      let postLi: any = [];
      let groupLi = null;
      let userLi = null;

      await callPostList((res) => {
        postLi = res;
        sum++;
      });
      await callGroupList((res) => {
        groupLi = res;
        sum++;
      });
      await callUserList((res) => {
        userLi = res;
        sum++;
      });
      if (sum === 3) {
        setIsLoading(false);
        setPostList(postLi);
        setGroupsQuick(groupLi);
        setUserQuick(userLi);
      }
    }, 700);
    return () => clearTimeout(timeOutId);
  }, [keyWord]);

  useEffect(() => {
    setIsModalVisible(false);
  }, [location.pathname, location.search]);

  const postQuickLength = useMemo(() => {
    return postList?.posts?.length || 0;
  }, [postList]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const groupQuickLength = useMemo(() => {
    return groupsQuick?.groups?.length || 0;
  }, [groupsQuick]);

  const userQuickLength = useMemo(() => {
    return userQuick?.users?.length || 0;
  }, [userQuick]);

  const { data: hashTags } = listHashtag({
    top: ETopHotTag.TOP_IN_ALL_POSTS,
    page: 1,
    per_page: 20,
  });

  const totalQuickLength = useMemo(() => {
    return userQuickLength + groupQuickLength + postQuickLength;
  }, [userQuickLength, groupQuickLength, postQuickLength]);

  const { data: listTopKeyWord } = useSearchTopKeyWord();

  const SearchChange = (e: any) => {
    const inputSearch = document.getElementById('InputHomeSearch') as any;
    if (inputSearch && inputSearch.value !== undefined) inputSearch.value = e || '';
    setKeyWord(e);
  };

  const handleOk = () => {
    navigate(`/home/search`);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setSelectedSearchTags([]);
    if (setIsOpen) {
      setIsOpen(false);
    }
  };
  const onSearch = () => {
    navigate(`/home/search?search=${keyWord}`, { state: { keyword: keyWord } });
    createOrUpdateKeyword(keyWord);
  };

  const { mutate: createOrUpdateKeyword } = useMutation(
    async (keyword?: string) => {
      if (!keyword?.trim()) {
        return;
      }
      return sendPost(`/keyword`, { keyword });
    },
    {
      onError: () => {
        message.error(t('error.errorSystem'));
      },
    }
  );
  const handleSearchFast = (e?: any) => {
    if (!(e?.keyCode === EKeyCode.UP || e?.keyCode === EKeyCode.DOWN || e?.keyCode === EKeyCode.ENTER)) {
      setActive(null);
    }
    if (e?.keyCode === EKeyCode.ENTER) {
      if (active === null || active === totalQuickLength + 1) {
        navigate(`/home/search?search=${e?.target?.value}`, { state: { keyword: e?.target?.value } });
      }
      createOrUpdateKeyword(e?.target?.value);
    }
  };

  const BuildListTopKeyWord = () => {
    if (listTopKeyWord?.length)
      return (
        <ListButton
          className="styleHotSearch"
          notPaddingVertical
          title={t('search.hotSearch')}
          listSearch={listTopKeyWord}
          changeHistorySearch={(e: any) => SearchChange(e)}
        />
      );
  };

  const HeaderSearch = () => {
    return (
      <CustomBackGroundHeaderSearch>
        <Title>{t('search.hotSearch')}</Title>
        {BuildListTopKeyWord()}
        <HashTagWrapper>
          <TagCustomHome
            className="hot-tag-component"
            title="Hashtag"
            data={hashTags?.hashtags}
            selectedTags={selectedSearchTags}
            setSelectedTags={setSelectedSearchTags}
            onClickTag={(e) => navigate(`/home/hot-tag/${e}`)}
          />
        </HashTagWrapper>
      </CustomBackGroundHeaderSearch>
    );
  };

  return (
    <React.Fragment>
      <BorderButton
        text={t('search.search')}
        iconUrl={images.home.iconSearchWhite}
        colorHover={isMobile ? 'unset' : '#c9c7c7'}
        color={isMobile ? 'unset' : '#fff'}
        onClick={showModal}
        hidden={hiddenButton}
        style={props?.borderButtonStyle}
      />
      <StyledModal
        onCancel={hideModal}
        isMobile={isMobile}
        footer={''}
        width={850}
        afterClose={() => {
          form.resetFields();
          setKeyWord('');
        }}
        visible={isModalVisible || isOpen}
        onOk={handleOk}
      >
        <SearchActionStyle>
          <Form form={form}>
            <Form.Item name="search">
              <StyleInputSearch
                id="InputHomeSearch"
                allowClear
                autoComplete="off"
                value={keyWord}
                onChange={(e) => {
                  setActive(null);
                  setPostList([]);
                  setGroupsQuick(null);
                  setUserQuick(null);
                  setKeyWord(e.target.value);
                }}
                placeholder={t('search.placeholder')}
                onKeyUp={(e: any) => handleSearchFast(e)}
                onKeyDown={(e: any) => {
                  const total = totalQuickLength - 1;
                  if (active && active >= total && e?.keyCode === EKeyCode.DOWN) {
                    setActive(total + 1);
                    return;
                  }
                  if (active && active === 1 && e?.keyCode === EKeyCode.UP) {
                    return;
                  }
                  // arrow up/down button should select next/previous list element
                  if (e?.keyCode === EKeyCode.UP && active && active > 1) {
                    setActive(active - 1);
                  } else if (e?.keyCode === EKeyCode.DOWN && active) {
                    setActive(active + 1);
                  } else if (e?.keyCode === EKeyCode.DOWN && !active) {
                    setActive(1);
                  }
                }}
              />{' '}
            </Form.Item>
          </Form>
          <StyleButtonSearchInput disabled={keyWord === ''} isDisable={keyWord === ''} onClick={onSearch}>
            <IconSearch src={images.table.search} alt="icon search" />
          </StyleButtonSearchInput>
        </SearchActionStyle>
        {keyWord.trim() ? (
          <FastSearch
            isLoading={isLoading}
            active={active}
            totalQuickLength={totalQuickLength}
            keyWord={keyWord}
            selectedSearchTags={selectedSearchTags}
            setSelectedSearchTags={setSelectedSearchTags}
            userQuick={userQuick}
            groupsQuick={groupsQuick}
            idSearch={null}
            postList={postList?.posts || []}
          />
        ) : (
          <HeaderSearch />
        )}
      </StyledModal>
    </React.Fragment>
  );
};

export default SearchUi;
