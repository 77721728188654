import React from 'react';
import { Col, Row } from 'antd';
import AnswerWrapper, { ImgComment } from './styled';
import AvatarText from 'components/AvatarText';
import { checkIsTag } from 'helper/check-tag-comment';
// import { useNavigate } from 'react-router-dom';
import { IUser } from 'pages/DetailPost/interface';
import useUserList from 'data/DetailPost/userUserList';

interface Props {
  userAvatar?: string;
  sizeUserAvatar?: number;
  userContent?: any;
  className?: string;
  dateContent?: string;
  content?: string;
  defaultAvatar?: string;
  content_images?: string;
}

const Answer: React.FC<Props> = ({
  content,
  userAvatar,
  sizeUserAvatar,
  userContent,
  className,
  dateContent,
  defaultAvatar,
  content_images,
}) => {
  // const navigate = useNavigate();
  const { data: userList } = useUserList();
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const textComment: any = content?.replace(urlRegex, (url: string) => {
    const lastLink = url[url.length - 1] || null;
    if (lastLink === `"`) {
      return url;
    }
    return '<a href="' + url + '">' + url + '</a>';
  });

  const getUser = (str: string) => {
    let mystr = str;
    mystr = mystr?.substring(1);
    if (!mystr) {
      return [];
    }
    const array = mystr.split(' @');
    const returnArr = [];
    for (const a of array) {
      const obj: any = {};
      const value: any = a.substring(a.lastIndexOf('[') + 1, a.lastIndexOf(']'));
      const key = a.substring(a.lastIndexOf('(') + 1, a.lastIndexOf(')'));
      obj[`imageName`] = value;
      obj[`id`] = key;
      returnArr.push(obj);
    }
    return returnArr;
  };

  const formatOldComment = (text: string) => {
    const txt = checkIsTag(text, userList?.users) ? text?.replaceAll('-', ' ')?.replace('@', '') : null;
    const findUser: IUser = userList?.users?.find((userItem: IUser) => userItem?.name === txt);
    return txt
      ? `<a
        target="_blank"
        href="/home/my-page/${findUser?.user_id}"
        class="mr-10 tagStyle"
      >${txt}</a>`
      : text;
  };

  const checkTagComment = (text: string) => {
    const userTag = getUser(text);
    let textReplace = text;
    userTag.forEach((element) => {
      textReplace = textReplace.replace(
        `@[${element.imageName}](${element.id})`,
        `<a target="_blank" href="/home/my-page/${element.id}" class="mr-10 tagStyle" >${
          element?.imageName?.split('-')[1] || element?.imageName || ''
        }</a>`
      );
    });

    const resComment = textReplace
      ?.split(' ')
      ?.map((txtElement: string) => formatOldComment(txtElement))
      ?.join(' ');

    return resComment;
  };

  return (
    <AnswerWrapper className={className || ''}>
      <Row>
        <Col span={24} className="answer-component-info-author">
          <AvatarText
            className="answer-component-author"
            defaultImage={defaultAvatar}
            textProperty={{ content: userContent }}
            avatarProperty={{ src: userAvatar, size: sizeUserAvatar }}
          />
          <AvatarText
            avatarProperty={{ dot: true, size: 5 }}
            textProperty={{ content: dateContent }}
            className="answer-component-created-at"
          />
        </Col>
        <ImgComment src={content_images} alt="" />
        <Col
          span={24}
          className="answer-component-content"
          dangerouslySetInnerHTML={{
            __html: checkTagComment(textComment),
          }}
        />
        {/* {textComment?.map((txtElement: string) => checkTagComment(txtElement))} */}
        {/* </Col> */}
      </Row>
    </AnswerWrapper>
  );
};

export default Answer;
