export const REGEX_ACCOUNT_NUMBER = /^\d{7,8}$/;
export const REGEX_PHONE_NUMBER = /^\+?[\d-]+$/;
export const REGEX_EMAIL = /^[a-z][a-z0-9_=\-+.]{5,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/;
export const REGEX_URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
export const REGEX_PASSWORD =
  /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[[$&+,:;=?@#|'<>.^*()%!-\]{}'"/?_])[A-Za-z\d[$&+,:;=?@#|'<>.^*()%!-\]{}'"/?_]{8,}$/;
export const REGEX_PASSWORD_CMS = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
export const REGEX_USERNAME = /^[a-zA-Zａ-ｚＡ-Ｚ0-9０-９ぁ-んァ-ンー一-龯$&+,:;=?@#|'<>.^*()%!{}'"/?_]{1,}$/;
export const REGEX_NUMBER = /\B(?=(\d{3})+(?!\d))/g;
export const REGEX_IMAGE = /\.(heic|jpe?g|jfif|png)$/i;
export const REGEX_URL_NAME = /^([a-zA-Z0-9]|-|_)+$/;
export const REGEX_HTML_TAG = /<[^>]+>/g;
export const REGEX_NUMBER_HAFLWIDTH = /^[0-9]+$/;
export const REGEX_IMG_TAG = /<img([\w\W]+?)\/>/;
export const REGEX_IFRAME_TAG = /<iframe([\w\W]+?)>/;
export const REGEX_USERNAME_STAFF = /^[ぁ-んァ-ンA-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF]{1,}$/;
export const REGEX_NUMBER_AND_DOT = /^[0-9]*\.?[0-9]*$/;
export const MAX_IMAGE_SIZE = 10 * 1024 * 1024;
export const REGEX_SPECIAL_STRING = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
export const REGEX_STRING =
  /^[A-Za-z0-9.,:;__ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý ]+$/;
export const REGEX_HIRAGANA_KATAKANA_KANJI_ALPHANUMERIC_FULL_WIDTH =
  /^[ぁ-んァ-ンA-Za-z一-龥 A-Za-z0-9Ａ-ｚ!@#$%^&“”*()_+\-=[\]{};':"\\|,.<>/?–~\n\r\n…;__ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf ]+$/;
export const REGEX_HIRAGANA_FULL_WIDTH = /^[ぁ-ん]+$/;
export const REGEX_KATAKANA_ALPHANUMERIC_FULL_WIDTH =
  /^([ァ-ン ]|ー|（|）|[０-９ ]|[ａ-ｚ ]|[Ａ-Ｚ ]|[a-z ]|[A-Z ]|[0-9 ]|^[一-龥ぁ-ん ])/;
export const REGEX_KATAKANA_FULL_WIDTH = /^([ァ-ン]|ー|（|）)+$/;
export const REGEX_STRING_HASHTAG =
  /^[A-Za-z0-9.,:#;__ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf ]+$/;
export const REGEX_EMOJI =
  /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g;

/*eslint no-empty-character-class: "error"*/
export const REGEX_EMOJI_TWO =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

export const REGEX_ACCEPT_FILE_UPLOAD = /(png|jpg|jpeg|heic|pdf|sheet|document|jfif|msword|pptx|presentation)+$/;

export const REGEX_ACCEPT_FILE_UPLOAD_EXCEPT_IMAGE = /(pdf|sheet|document|msword|pptx|presentation)+$/;

export const REGEX_ACCEPT_FILE_UPLOAD_IMAGE_ONLY = /(png|jpg|jpeg|heic|jfif)+$/;
