import useWindowSize from 'hooks/useWindow';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import ListSuggestCategoryWrapper from './styled';

interface Props {
  list?: string[];
  children?: any;
  className?: string;
}
const ListSuggestCategory: FunctionComponent<Props> = ({ className, list, children }) => {
  const listSearchRef = useRef<HTMLDivElement>(null);

  const defaultValue = useRef<number>(0);

  const clientXRef = useRef<number>(0);

  const { isMobile } = useWindowSize();

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (listSearchRef?.current) {
      clientXRef.current = e.clientX;
      defaultValue.current = listSearchRef.current.scrollLeft;
    }
  };

  const handleMouseMove = (e: MouseEvent): void => {
    if (listSearchRef?.current && clientXRef.current !== 0) {
      listSearchRef.current.scrollLeft = defaultValue.current + (clientXRef.current - e.clientX);
    }
  };

  const handleMouseUp = (e: MouseEvent): void => {
    clientXRef.current = 0;
  };

  return (
    <ListSuggestCategoryWrapper
      isMobile={isMobile}
      onPointerDown={handleMouseDown}
      className={className || ''}
      ref={listSearchRef}
    >
      {children}
    </ListSuggestCategoryWrapper>
  );
};

export default ListSuggestCategory;
