import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import FooterCustom from './Footer';
import HeaderCustom from './Header';
import HeaderPlaceHolder from './Header/HeaderPlaceHolder';
import ChatBot from 'components/ChatBot';

const { Footer, Content } = Layout;

const WhiteContent = styled(Content)`
  background: white;
`;

const WhiteFooter = styled(Footer)`
  background: white;
`;

const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Layout>
      <HeaderPlaceHolder />
      <HeaderCustom />
      <WhiteContent>{children}</WhiteContent>
      <WhiteFooter>
        <FooterCustom children={undefined}></FooterCustom>
      </WhiteFooter>
      <ChatBot />
    </Layout>
  );
};

export default DefaultLayout;
