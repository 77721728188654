import { images } from 'assets/images-base';
import React from 'react';
import { PluginComponent } from 'react-markdown-editor-lite';
import { EditorContext } from './EditorProvider';
import { Div } from './styled';

export class UserManual extends PluginComponent {
  static pluginName = 'user-manual';
  static align = 'left';
  static contextType = EditorContext;

  render() {
    return (
      <Div className="user-manual" title="user-manual">
        <div className="icon">
          <img src={images.circleQuestion} alt="icon" className="w-100" />
        </div>
      </Div>
    );
  }
}
