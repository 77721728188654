import { Input } from 'antd';
import React, { FunctionComponent } from 'react';
import { InputFullRowWrapper } from './styled';
interface InputFieldProps {
  className?: string;
  onClick?: (e: any) => void;
  value?: any;
  label?: string;
  isMust?: boolean;
  error?: string;
  maxLength?: number;
  readOnly?: boolean;
  onChange?: any;
  defaultValue?: string;
  name?: string;
  children?: any;
  onBlur?: () => void;
  borderError?: boolean;
  disabled?: boolean;
}

const InputField: FunctionComponent<InputFieldProps> = ({
  className = '',
  label,
  value,
  isMust = false,
  error,
  children,
  disabled = false,
  borderError = false,
  onChange,
  ...props
}) => {
  return (
    <InputFullRowWrapper disabled={disabled} theme={!!value} className="InputFieldWrapper">
      <article className={`${className || 'l-design-widht'} `}>
        <label className="input">
          <Input
            className={`${!!error || borderError ? 'input__fieldErr' : 'input__field'}`}
            type="text"
            onChange={!disabled && onChange}
            placeholder=" "
            {...props}
            value={value}
            autoComplete="off"
            disabled={disabled}
          />
          <div className={`${!!error || borderError ? 'input__labelErr' : 'input__label'}`}>
            {label} {isMust ? <span className="must">*</span> : null}
          </div>
          {children}
        </label>
      </article>
      <div className="error">{error}</div>
    </InputFullRowWrapper>
  );
};

export default InputField;
