import { sendGet } from 'api/axios';
import { useQuery } from 'react-query';
import { EFollow, ETopCategory, ETopic } from '../common';
export interface ITopCategory {
  group_id?: number;
  per_page?: number;
  page?: number;
  top?: ETopCategory;
  topic_id?: ETopic | number | null | number[];
  category_id?: number[];
  start_date?: string;
  end_date?: string;
  hashtag?: string[];
  is_following?: EFollow;
}

const useGetTopCategory = (params?: ITopCategory) =>
  useQuery(
    ['get_top_category', params],
    async () => {
      const res = await sendGet('/category/list', params);
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

export default useGetTopCategory;
