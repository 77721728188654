import axios from 'axios';

const instanceChatBot = axios.create({
    baseURL: 'https://chatgpt.amela.vn/api',
    headers: {
        'Content-Type': 'application/json',
        Accept: '/',
    },
});

export const getAnswers = (question: string) => instanceChatBot.post('/question', {question: question});
