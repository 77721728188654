import useListSystem from 'data/notification/useListSystem';
import useNotice, { IResNotice } from 'data/notification/useNotice';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import React, { useState } from 'react';
import Header from './Header/Header';
import ListNotification from './ListNotification/ListNotification';
import { BodyWrapper, NotificationWrapper } from './NotificationStyle';
import System from './System/System';
import { INoticeParams } from './../../../../data/notification/useNotice';

const Notification = () => {
  const userId = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.user_id;
  const [paramsNotice, setParamsNotice] = useState<INoticeParams>({
    user_id: userId,
    system_id: 1,
    page: 1,
    size: 20,
  });
  const { data: listSystem } = useListSystem({ user_id: userId });
  const { data: listNotice, isLoading } = useNotice(paramsNotice);

  const [listNoticeState, setListNoticeState] = useState<IResNotice[] | null>(null);
  return (
    <NotificationWrapper>
      <Header listSystem={listSystem} />
      <BodyWrapper>
        <System
          listSystem={listSystem}
          paramsNotice={paramsNotice}
          setParamsNotice={setParamsNotice}
          setListNoticeState={setListNoticeState}
        />
        <ListNotification
          isLoading={isLoading}
          ListNotice={listNotice || []}
          paramsNotice={paramsNotice}
          listNoticeState={listNoticeState}
          setParamsNotice={setParamsNotice}
          setListNoticeState={setListNoticeState}
        />
      </BodyWrapper>
    </NotificationWrapper>
  );
};

export default Notification;
