import React from 'react';
import {
  AcmsSystemWrapper,
  SystemDesc,
  SystemElementWrapper,
  SystemName,
  SystemWrapper,
  UserDesc,
  UserWrapper,
  WrapperElement,
} from './AcmsSystemStyle';
import { Avatar } from 'antd';
import useListSystem, { IResSystem } from 'data/notification/useListSystem';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import defaultConst from 'common/default';
import { ESystemId } from 'common';
import { useTranslation } from 'react-i18next';

interface ISystem {
  systemId: ESystemId.ME | ESystemId.BO | ESystemId.RECRUIT | ESystemId.WORKFLOW | ESystemId.WIKI | ESystemId.PROJECT;
}
const SystemElement = ({ systemId }: ISystem) => {
  const { t } = useTranslation();
  const systemObject = defaultConst.SYSTEM_SELECT[systemId];

  const getUrl = (url: string | undefined) => {
    if (url) {
      return url;
    }

    return null;
  };
  return (
    <SystemElementWrapper target="blank" href={getUrl(systemObject?.url)}>
      <Avatar size={50} src={systemObject.avatar} />
      <SystemName>{t(`notification.${systemObject.name}`)}</SystemName>
      <SystemDesc>{t(`notification.${systemObject.desc}`)}</SystemDesc>
    </SystemElementWrapper>
  );
};

const AcmsSystem = () => {
  const name = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.name;
  const email = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.email;
  const avatar = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.avatar;
  const userId = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.user_id;
  const positionName = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.position_name;
  const divisionName = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.division_name;

  const { data: listSystem } = useListSystem({ user_id: userId });
  console.log(listSystem)

  return (
    <AcmsSystemWrapper>
      <UserWrapper>
        <Avatar size={60} src={avatar} />
        <UserDesc>
          <div>{name}</div>
          <div>{email}</div>
          <div>
            {positionName} - {divisionName}
          </div>
        </UserDesc>
      </UserWrapper>
      <SystemWrapper>
        {listSystem?.map((element: IResSystem, index: number) => {
          if (element?.system_id) {
            return (
              <WrapperElement key={String(index)}>
                <SystemElement systemId={element.system_id} />
              </WrapperElement>
            );
          }
        })}
      </SystemWrapper>
    </AcmsSystemWrapper>
  );
};

export default AcmsSystem;
