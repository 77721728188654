export enum Permission {
  View = 2,
  Edit = 3,
  Delete = 4,
}

export enum Role {
  Dashboard = 'dashboard_wiki', // 22,
  ListPosts = 'list_posts_wiki', // 23,
  ListPostsReport = 'list_posts_report_wiki', // 24,
  ManagerGroup = 'manager_group_wiki', // 25,
  MasterDataCategory = 'master_data_category_wiki', // 26,
  MasterDataTopic = 'master_data_topic_wiki', // 27,
  MasterDataTemplate = 'master_data_template_wiki', // 28,
  MasterDataRole = 'master_data_role_wiki', // 29,
  PinPostWiki = 'pin_post_wiki',
  MasterDataBanner = 'master_data_banner_wiki', // 31
}

interface IPermissionItem {
  dashboard_wiki: Permission[];
  list_posts_wiki: Permission[];
  list_posts_report_wiki: Permission[];
  manager_group_wiki: Permission[];
  master_data_category_wiki: Permission[];
  master_data_topic_wiki: Permission[];
  master_data_template_wiki: Permission[];
  master_data_role_wiki: Permission[];
  master_data_banner_wiki: Permission[];
  name: string;
  pin_post_wiki: Permission[];
}

export interface IGroupRole {
  role: number;
  permission: IPermissionItem;
}

const getRoleDataDefault = (): IGroupRole => ({
  role: -1,
  permission: {
    dashboard_wiki: [], // [Permission.View, Permission.Edit, Permission.Delete],
    list_posts_wiki: [],
    list_posts_report_wiki: [],
    manager_group_wiki: [],
    master_data_category_wiki: [],
    master_data_topic_wiki: [],
    master_data_template_wiki: [],
    master_data_role_wiki: [],
    name: 'noname',
    pin_post_wiki: [],
    master_data_banner_wiki: [],
  },
});

class RolePermission {
  roleData: IGroupRole | null;
  static current: RolePermission | null = null;
  constructor(data: IGroupRole) {
    this.roleData = data;
    if (!data.permission) this.roleData = getRoleDataDefault();
    RolePermission.current = this;
  }

  static createInstance(data: IGroupRole) {
    if (RolePermission.current == null) {
      RolePermission.current = new RolePermission(data);
    } else {
      RolePermission.current.roleData = data;
    }
  }

  static getInstance(): RolePermission | null {
    return RolePermission.current;
  }

  getRoleName(): string {
    if (this.roleData === null || this.roleData.permission === null) return '';
    return this.roleData.permission.name;
  }

  getPermissionOfRole(role: Role): Permission[] {
    if (this.roleData === null || this.roleData.permission === null) return [];
    if (this.roleData.permission[role]) return this.roleData.permission[role];
    return [];
  }

  static checkPermission(role: Role, per: Permission): boolean {
    if (this.current === null) {
      return false;
    }
    const permissions = RolePermission.current?.getPermissionOfRole(role);
    return permissions ? permissions.includes(per) : false;
  }
}

export default RolePermission;
