/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Image, Popconfirm } from 'antd';
import StyleHeader, { HeaderTabs, HeaderTabPane, Space, TotalNotification, TabName, IconLogout } from './style';
import { useMutation } from 'react-query';
import { getPathIcon } from 'helper/image';
import { useTranslation } from 'react-i18next';
import { sendPost } from 'api/axios';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import images from 'assets/images-base';
import useWindowSize from 'hooks/useWindow';
import HeaderMobile from '../Header/Mobile/HeaderMobile';
import { CloseOutlined } from '@ant-design/icons';
import Notification from './Notification/Notification';
import useListSystem from 'data/notification/useListSystem';
import AcmsSystem from './AcmsSystem/AcmsSystem';
import { showConfirm } from 'helper/modal-confirm';
import SearchUi from 'components/SearchUI/component/searchUI';
import ButtonCreatePostMini from 'components/ButtonCreatePost/ButtonCreatePostMini';
import { useAppSelector } from 'hooks/use-Selector/useAppSelector';

export const getTabSelected = (tabsInfo: { url: string; key: string }[]): string => {
  const path = window.location.pathname;
  for (const element of tabsInfo) {
    if (path.includes(element.url)) {
      return element.key;
    }
  }
  return '-1';
};

const getHeaderTabSelected = (tabsInfo: { url: string; key: string }[]): string => {
  const path = window.location.pathname;
  if (path === '/' || path.includes('home')) return '1'; // tab home
  for (const element of tabsInfo) {
    if (element.url === '/') continue;
    const findIndex = path.indexOf(element.url);
    if (findIndex === 0) {
      return element.key;
    }
  }
  return '-1';
};

const HeaderCustom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.user_id;

  const { myInfo, isAdminSystem } = useAppSelector((state: any) => state?.auth);

  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { isMobile } = useWindowSize();

  const { data: listSystem } = useListSystem({ user_id: userId });
  const totalNotice = useMemo(() => {
    return listSystem?.map((item: any) => item.total).reduce((prev: any, curr: any) => prev + curr, 0) || 0;
  }, [listSystem]);

  useEffect(() => {
    if (location.pathname === '/') {
      setIsHomePage(true);
    } else setIsHomePage(false);
  }, [location]);

  const { mutate: logout } = useMutation(
    async () => {
      return await sendPost(`${process.env.REACT_APP_API_GET_USER_INFO}/logout`);
    },
    {
      onSuccess: async (res: any) => {
        if (res?.status === 200) {
          (window as any).location.href = process.env.REACT_APP_API_DOMAIN_LOGIN;
        }
      },
      onError: () => {},
    }
  );

  const signOut = () => {
    logout();
  };

  const onChangeTab = (key: any) => {
    const index = parseInt(key) - 1;
    navigate(TAB[index].url);
  };

  const TAB = [
    {
      key: '1',
      url: '/',
      title: t('header.newsfeed'),
      permissions: '',
    },
    {
      key: '2',
      url: '/group',
      title: t('header.group'),
      permissions: '',
    },
    {
      key: '3',
      url: '/admin',
      title: t('header.manage'),
      permissions: '',
    },
  ];

  const renderButtonMenu = () => {
    if (showMenu) {
      return <CloseOutlined onClick={() => setShowMenu(false)} className="close-menu-mobile" />;
    } else {
      return (
        <Image
          onClick={() => setShowMenu(true)}
          className="menu-home-mobile"
          preview={false}
          src={images.home.HomeMenu}
          width={24}
          height={24}
        />
      );
    }
  };

  const checkLogOut = () => {
    showConfirm({
      title: t('confirmLogOut'),
      onOk: () => {
        signOut();
      },
    });
  };

  return (
    <StyleHeader className={isHomePage ? 'home-header' : ''} isMobile={isMobile} id="HeaderWrapper">
      <div className="logo" onClick={() => navigate('/')}>
        <img className="image" src={getPathIcon('mask.svg')} alt="logo" />
        <div className="name-project">WIKI</div>
      </div>
      {!isMobile && (
        <HeaderTabs activeKey={getHeaderTabSelected(TAB)} onTabClick={onChangeTab}>
          <HeaderTabPane tab={<TabName>{TAB[0].title}</TabName>} key={TAB[0].key} />
          <HeaderTabPane tab={<TabName>{TAB[1].title}</TabName>} key={TAB[1].key} />
          {isAdminSystem && <HeaderTabPane tab={<TabName>{TAB[2].title}</TabName>} key={TAB[2].key} />}
        </HeaderTabs>
      )}
      <Space />
      <div className="list-item">
        <SearchUi borderButtonStyle={{ height: '30px' }} />
        <ButtonCreatePostMini onClickNormal={() => navigate('/home/create')} borderButtonStyle={{ height: '30px' }} />
        {!isMobile && (
          <div
            onClick={() => {
              navigate(`/home/my-page/${SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {}).user_id}`);
            }}
          >
            <Avatar
              size={36}
              src={myInfo?.avatar}
              icon={<img alt="" src={images.profile.avatarDefault} />}
              style={{ width: 36, cursor: 'pointer' }}
            />
          </div>
        )}
        <Popconfirm placement="bottomRight" title={<Notification />}>
          <img className="item" src={images.header.RingIcon} alt="" />
          {totalNotice > 0 && <TotalNotification>{totalNotice}</TotalNotification>}
        </Popconfirm>
        <Popconfirm placement="top" title={<AcmsSystem />}>
          <img className="item" src={getPathIcon('element.svg')} alt="" />
        </Popconfirm>

        {isMobile ? (
          renderButtonMenu()
        ) : (
          <IconLogout src={getPathIcon('logout.svg')} alt="logout" onClick={checkLogOut} />
        )}
      </div>
      {showMenu && isMobile && <HeaderMobile userData={myInfo} onCloseMenu={setShowMenu} checkLogOut={checkLogOut} />}
    </StyleHeader>
  );
};

export default HeaderCustom;
