/* eslint-disable react-hooks/rules-of-hooks */
import { sendGet } from 'api/axios';
import { ETopHotTag } from 'common';
import { useQuery } from 'react-query';
import { IListTopPic } from 'types/interface';

export interface IParamTag {
  per_page?: number;
  page?: number;
  keyword?: string;
  top?: ETopHotTag;
  group_id?: number;
}

export const useListTopPic = (params?: IListTopPic) =>
  useQuery(
    ['get_list_topic', params],
    async () => {
      const res = await sendGet('/topic/list', params);
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export const listTemplateByTopic = (params: IListTopPic, options: any) => {
  return useQuery(
    ['get_list_template_by_topic', params],
    async () => {
      const res = await sendGet('/template/list', params);
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
      ...options,
    }
  );
};

export const listHashtag = (params?: IParamTag, options?: any) => {
  return useQuery(
    ['get_list_hashtag', params],
    async () => {
      const res = await sendGet('/hashtag/list', params);
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
      ...options,
    }
  );
};
