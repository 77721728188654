import { colors } from 'common/styled-base';
import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
  noBg?: boolean;
}

export const ButtonCancelWrapper = styled.button<Props>`
  width: ${(props) => (props?.width ? `${props?.width}px` : '100%')};
  height: ${(props) => (props?.height ? `${props?.height}px` : '')};
  cursor: pointer;
  font-weight: 500;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.mainColor100};
  border: 1px solid ${colors.mainColor2};
  img {
    margin-right: 10px;
  }
  span {
    font-size: 14px;
  }
`;
