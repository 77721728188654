import { isMobile } from 'react-device-detect';
import { Button } from 'antd';
import styled from 'styled-components';

const HiddenSidebarStyle = styled.div`
  .divider {
    margin: 10px 0;
    border: 1px dashed #e0e0e0;
  }

  .empty-data {
    text-align: center;
    width: 100% !important;
    color: #909090;
  }
`;

const SeeMoreStyle = styled(Button)`
  height: 36px;
  background: #e4e4e4;
  border-radius: 5px;
  width: ${isMobile ? '100%' : '654px'};
  margin: auto;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #909090;

  :hover,
  :focus {
    background: #e4e4e4;
    color: #909090;
    border: none;
  }
`;

export { SeeMoreStyle };

export default HiddenSidebarStyle;
