import images from 'assets/images-base';
import { ETypeView } from 'common';
import ContentCustom from 'components/layout/Content/Content';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import useWindowSize from 'hooks/useWindow';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AllGroupSystem from './AllGroupSystem/AllGroupSystem';
import GeneralGroup from './GeneralGroup/GeneralGroup';
import { SideBar } from './SideBar/SideBar';
import { SideBarMobile } from './SideBar/SideBarMobile';
import StyledGroupPage, {
  BoxSearchStyle,
  FrameDisplay,
  FrameTabList,
  ImageTab,
  InputSearchStyle,
  TabListStyle,
  TextTab,
  TitleStyle,
  WrapperSidebar,
} from './StyledGroupPage';

interface ITabList {
  text: string;
  image: string;
  isFocus?: boolean;
  onClick?: () => void;
  imageInFocus: string;
}
enum ETabOnFocus {
  POST = 1,
  GROUP = 2,
}

const TabList: React.FC<ITabList> = (props) => {
  return (
    <TabListStyle isFocus={props?.isFocus} onClick={props?.onClick}>
      <ImageTab src={props?.isFocus ? props?.imageInFocus : props?.image} alt="" />
      <SpaceStyle padding="6.34px" />
      <TextTab>{props?.text}</TextTab>
    </TabListStyle>
  );
};

const GroupPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [tabOnFocus, setTabOnFocus] = useState<ETabOnFocus>(ETabOnFocus.POST);

  const { isMobile } = useWindowSize();
  const [value, setValue] = useState<string>('');
  useEffect(() => window.scrollTo(0, 0), []);

  const changeTabFocus = (value: ETabOnFocus) => {
    setTabOnFocus(value);
  };

  const renderTabList = () => {
    return (
      <FrameTabList>
        <TabList
          onClick={() => changeTabFocus(ETabOnFocus.POST)}
          imageInFocus={images.group.PostGroupIcon}
          text={t('group.postGroup')}
          isFocus={tabOnFocus === ETabOnFocus.POST}
          image={images.group.PostGroupDisabledIcon}
        />
        <TabList
          onClick={() => changeTabFocus(ETabOnFocus.GROUP)}
          image={images.group.GroupGroupIcon}
          text={t('group.discoveryGroup')}
          isFocus={tabOnFocus === ETabOnFocus.GROUP}
          imageInFocus={images.group.GroupGroupEnableIcon}
        />
      </FrameTabList>
    );
  };

  const renderSideBar = () => {
    return (
      <WrapperSidebar>
        <BoxSearchStyle>
          <TitleStyle
            avatarProperty={{ size: 34, shape: 'square', src: images.group.MyGroupIcon }}
            textProperty={{
              content: t('group.myGroup'),
              style: { color: '#FEA628', fontSize: '20px', fontWeight: 500 },
              marginLeft: 12.68,
            }}
          />

          <SpaceStyle padding="11px 0" />

          <InputSearchStyle
            placeholder={t('group.searchGroup')}
            value={value}
            onChange={(e: any) => setValue(e?.target?.value)}
            onSearch={(e: any) => {
              if (e) {
                navigate(`/group/search?search=${e}&type_view=${ETypeView.GROUP}`, {
                  state: { type_view: ETypeView.GROUP, keyword: e },
                });
              }
            }}
          />

          <SpaceStyle padding="5px" />

          {!isMobile && renderTabList()}
        </BoxSearchStyle>

        {isMobile ? <SpaceStyle padding="15px 0" /> : <></>}

        {isMobile ? <SideBarMobile /> : <SideBar />}
        {isMobile && (
          <>
            {renderTabList()}
            <SpaceStyle padding="10px" />
          </>
        )}
      </WrapperSidebar>
    );
  };

  return (
    <StyledGroupPage>
      <ContentCustom
        spaceContentStyle={{ order: -1 }}
        showBanner={false}
        sideBarStyle={{ order: -2 }}
        sibar={renderSideBar()}
      >
        <FrameDisplay display={tabOnFocus === ETabOnFocus.POST}>
          <GeneralGroup />
        </FrameDisplay>

        <FrameDisplay display={tabOnFocus === ETabOnFocus.GROUP}>
          <AllGroupSystem />
        </FrameDisplay>
      </ContentCustom>
    </StyledGroupPage>
  );
};

export default GroupPage;
