/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antd';
import { EIsSeries, ESeriesType, ETopCategory, ETopic } from 'common';
import FilterHome from 'components/FilterHome/FilterHome';
import useGetTopCategory from 'data/useGetTopCategory';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { IParamsPost } from 'types/interface';
import { CategoryFilterStyle, FilterPostByCategoryStyle, ListSuggestCategoryStyle } from './filterPostByCategoryStyle';

interface ICountPost {
  question: number;
  share: number;
  currentTab?: ETopic | null;
}
interface Props {
  countPosts?: ICountPost;
  onSetParams: (value: any) => void;
  params: IParamsPost;
  isSeries?: boolean;
}

const FilterPostByCategory: React.FC<Props> = ({ countPosts, params, isSeries = false, ...props }) => {
  const { t } = useTranslation();
  const param = useParams();
  const queryClient = useQueryClient();
  const location = useLocation();

  const [chooseButton, setChooseButton] = useState<number[] | any>([0]);

  useEffect(() => {
    if (!params?.category_id || params?.category_id?.length === 0) {
      setChooseButton([0]);
    } else {
      setChooseButton(params?.category_id);
    }
  }, [params?.category_id]);

  const topTopic =
    location.pathname.includes('/user-manage-group') ||
    location.pathname.includes('/group-joining') ||
    location.pathname.includes('/group/classification')
      ? ETopCategory.TOP_IN_GROUP
      : location.pathname.includes('/group')
      ? ETopCategory.TOP_IN_ALL_GROUPS
      : ETopCategory.TOP_IN_ALL_POSTS;

  const groupId = param?.id ? Number(param.id) : undefined;

  const paramCategory = {
    per_page: 7,
    page: 1,
    top: topTopic,
    topic_id: null,
    group_id: groupId,
    is_series: isSeries ? EIsSeries.SERI : undefined,
    series_type: isSeries ? ESeriesType.PARENT : undefined,
  };
  const { data: dataCategory } = useGetTopCategory({ ...paramCategory, topic_id: params?.topic_id });
  const categories = dataCategory?.categories;

  const handleSetParamFilter = (value: any) => {
    const _categories = value?.category_id?.length > 0 ? value?.category_id : [0];
    const subCategories = value?.sub_category_id?.length > 0 ? value?.sub_category_id : [];
    const series = value?.is_series;

    setChooseButton(_categories);

    if (props.onSetParams)
      props.onSetParams((_data: any) => {
        return {
          ..._data,
          ...value,
          is_series: series,
          category_id: subCategories?.length > 0 ? subCategories : _categories?.filter((id: number) => id !== 0),
        };
      });
  };

  useEffect(() => {
    queryClient.setQueryDefaults('setPostParams', handleSetParamFilter as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCategory = (_chooseButton: number[]) => {
    if (props.onSetParams)
      props.onSetParams((_data: any) => {
        return {
          ..._data,
          category_id: _chooseButton?.filter((id: number) => id !== 0),
        };
      });
  };

  return (
    <FilterPostByCategoryStyle>
      <CategoryFilterStyle>
        <ListSuggestCategoryStyle>
          {categories?.length > 0 && (
            <Button
              shape="round"
              className={`${chooseButton.includes(0) ? 'active-button' : ''}`}
              onClick={() => {
                setChooseButton([0]);
                handleChangeCategory([]);
              }}
            >
              {t('userManageGroup.newFeed.all')}
            </Button>
          )}
          {categories?.map((item: any) => (
            <Button
              onClick={() => {
                if (chooseButton.includes(item?.id)) {
                  const _categoryId = chooseButton.filter((id: number) => id !== item?.id);
                  setChooseButton(_categoryId?.length === 0 ? [0] : _categoryId);
                  handleChangeCategory(_categoryId);
                  return;
                }
                const __categoryId = [...chooseButton.filter((id: number) => id !== 0), item?.id];
                setChooseButton(__categoryId);
                handleChangeCategory(__categoryId);
              }}
              className={`${chooseButton.includes(item?.id) ? 'active-button' : ''}`}
              shape="round"
              key={item?.id}
            >
              {item?.name}
            </Button>
          ))}
        </ListSuggestCategoryStyle>
        {/* <FilterButtonStyle
          categoriesIds={{
            categoriesIds: chooseButton,
            onSetCategoriesIds: setChooseButton,
            onSetParamFilter: handleSetParamFilter,
          }}
          ref={filterRef}
        /> */}
        {categories?.length > 0 && <FilterHome params={params} setParams={props.onSetParams} />}
      </CategoryFilterStyle>
    </FilterPostByCategoryStyle>
  );
};
export default FilterPostByCategory;
