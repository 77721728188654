import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: ${(props) => props.height || 'fit-content'};
  align-items: center;
  border: 1px solid ${(props) => props.color};
  border-radius: 0.5em;
  cursor: pointer;
  margin-right: ${isMobile ? 'unset' : '1em'};

  :hover {
    border: 1px solid ${(props) => props.colorHover};
  }
  :active {
    border: 1px solid ${(props) => props.colorHover};
  }
`;

const Image = styled.img`
  height: 1.5357em;
  margin: 0.3em 0.7em;
`;

const Text = styled.div<any>`
  font-size: 12px;
  color: ${(props) => props.color};
  font-weight: 400;
  margin-left: 0.7em;
  margin-top: 0.075em;

  :hover {
    color: ${(props) => props.colorHover};
  }
`;

interface IStyle {
  height?: string;
}

interface Props {
  iconUrl?: string;
  fontSize?: number | string;
  text: string;
  color?: string;
  colorHover?: string;
  onClick?: () => void;
  hidden?: boolean;
  style?: IStyle;
}

const BorderButton: React.FC<Props> = (props) => {
  return (
    <Wrapper
      hidden={props?.hidden}
      color={props.color || 'white'}
      colorHover={props.colorHover || 'white'}
      onClick={props.onClick}
      style={props?.style}
    >
      {!isMobile && (
        <Text color={props.color || 'white'} colorHover={props.colorHover || 'white'}>
          {props.text}
        </Text>
      )}
      {props.iconUrl !== undefined && <Image src={props.iconUrl} alt="icon" />}
    </Wrapper>
  );
};

export default BorderButton;
