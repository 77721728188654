/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import images from 'assets/images-base';
import ModalNormal from 'components/common/modal';
import defaultConst from 'common/default';
import useFilterCategoryHome from 'hooks/FilterCategoryHome/useFilterCategoryHome';
import { IParamsPost } from 'types/interface';
import { showConfirm } from 'helper/modal-confirm';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import FilterContent, { IDateFilter } from './FilterContent/FilterContent';
import { DoteFilter, FilterHomeWrapper, IconFilter } from './filterHomeStyle';
import { checkIsFollow, getIdFilter, paramsSetRange } from './FilterContent/filterContentStyle';
import { EFollow } from 'common';

const FilterHome = React.memo(({ params, setParams }: { params: IParamsPost; setParams: (value: any) => void }) => {
  const { t } = useTranslation();

  const [shown, setShown] = useState<boolean>(false);
  const { dataFilter, setDataFilter, getSubCategory, paramSetFilter, resetFilter } = useFilterCategoryHome();
  const [rangeList, setRangeList] = useState<CheckboxValueType[]>(defaultConst.RANGE_LIST);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [dateFilter, setDateFilter] = useState<IDateFilter>({
    start_date: null,
    end_date: null,
  });

  const checkIsFilter = () => {
    if (
      params?.category_id?.length > 0 ||
      (params?.is_following !== EFollow.ALL && params?.is_following) ||
      params?.is_following === EFollow.NOT_FOLLOWING ||
      params?.start_date ||
      params?.end_date ||
      (params?.hashtag && params?.hashtag?.length > 0)
    ) {
      return true;
    }
    return false;
  };

  const showSidebar = () => {
    setShown(true);
  };

  const onFilter = () => {
    if (
      dateFilter?.start_date &&
      dateFilter?.end_date &&
      moment(dateFilter?.start_date)?.isAfter(moment(dateFilter?.end_date))
    ) {
      showConfirm({
        title: t('home.errorDate'),
        hideCancel: true,
      });
      return;
    }
    setParams({
      ...params,
      ...dateFilter,
      page: 1,
      hashtag: selectedTags,
      category_id: getIdFilter(dataFilter),
      is_following: checkIsFollow(rangeList),
    });
    setShown(false);
  };

  // setup filer theo params
  const paramToDataFilter = () => {
    paramSetFilter(params?.category_id);
  };
  const paramToRangeList = () => {
    setRangeList(paramsSetRange(params?.is_following));
  };
  const paramToDate = () => {
    setDateFilter({
      start_date: params?.start_date || null,
      end_date: params?.end_date || null,
    });
  };
  const paramToHashTag = () => {
    setSelectedTags(params?.hashtag || []);
  };

  const setAllDefaultParams = () => {
    paramToDataFilter();
    paramToRangeList();
    paramToDate();
    paramToHashTag();
  };

  const resetData = () => {
    resetFilter();
    setRangeList(defaultConst.RANGE_LIST);
    setSelectedTags([]);
    setDateFilter({
      start_date: null,
      end_date: null,
    });
  };

  useEffect(() => {
    setAllDefaultParams();
  }, [params, shown]);

  return (
    <FilterHomeWrapper>
      {checkIsFilter() && <DoteFilter size={14} />}
      <IconFilter onClick={showSidebar} src={images.group.Filter} alt="" />
      <ModalNormal
        visible={shown}
        setVisible={setShown}
        width={643}
        title={t('home.filterPost')}
        afterClose={setAllDefaultParams}
      >
        <FilterContent
          rangeList={rangeList}
          dataFilter={dataFilter}
          dateFilter={dateFilter}
          selectedTags={selectedTags}
          setRangeList={setRangeList}
          setDateFilter={setDateFilter}
          setDataFilter={setDataFilter}
          getSubCategory={getSubCategory}
          setSelectedTags={setSelectedTags}
          onFilter={onFilter}
          resetData={resetData}
        />
      </ModalNormal>
    </FilterHomeWrapper>
  );
});

export default FilterHome;
