import { sendPost } from 'api/axios';
import { images } from 'assets/images-base';
import ButtonIconCustom from 'components/common/button-icon-custom/ButtonIcon';
import ModalNormal from 'components/common/modal';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import { showConfirm, successPopup } from 'helper/modal-confirm';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import StyleCreateGroup, { ContentStyle, StyleButton } from './style';
import UploadImages from './UploadImg';
import { TitleStyle } from 'components/userManageGroup/Bannner/ModalSettingGroup/bodyModalSettingGroupStyle';
import InputField from 'components/common/input/InputFullRow';
import BaseRadio from 'components/common/radio';
import SelectMember from 'components/userManageGroup/Component/SelectMember/SelectMemberCustom';
import useGetListUserByIds from 'data/useGetListUserByIds';
import { EPermissionToPost, ETypeGroup } from 'common';
import { validateMaxLength, validateRegex } from './validate';
import { ErrorText } from 'pages/CreateAndEditPost/createAndEditPostStyle';
import { REGEX_HIRAGANA_KATAKANA_KANJI_ALPHANUMERIC_FULL_WIDTH } from 'helper/regex';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import EditorNoFormValue from 'components/Editor/EditorNoFormValue';
interface IProps {
  openModal: boolean;
  setOpenModal: any;
}

interface IRequestData {
  name?: string;
  rule?: string;
  admin?: number[];
  member?: number[];
  post_permission?: EPermissionToPost;
  type?: ETypeGroup;
  avatar_image?: string;
  content_images?: string[];
}

interface IErrorRequestData {
  name: string;
  rule: string;
}

const myInfo = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {});
const defaultValue = {
  name: '',
  rule: '',
  admin: [],
  member: [],
  type: ETypeGroup.PUBLIC,
  post_permission: EPermissionToPost.FREE,
  avatar_image: '',
  content_images: [],
};
const CreateGroup: FunctionComponent<IProps> = ({ openModal, setOpenModal }) => {
  const QueryClient = useQueryClient();
  const { t } = useTranslation();
  const [values, setValues] = useState<IRequestData>(defaultValue);
  const [errors, setErrors] = useState<IErrorRequestData>({ name: '', rule: '' });
  const navigate = useNavigate();
  const { mutate: createGroup, isLoading } = useMutation((params: IRequestData) => sendPost(`/group/create`, params), {
    onSuccess: (_data) => {
      QueryClient.refetchQueries(['list group manage user']);
      successPopup({
        content: t('createGroup.createGroupSuccess'),
        className: 'alert-confirm-modal',
        onOk: () => {
          setOpenModal(false);
          navigate(`/group/user-manage-group/${_data?.data?.id}`);
        },
      });
    },
    onError: () => {
      successPopup({ content: t('createPost.createPostFail'), className: 'alert-confirm-modal' });
    },
  });

  const contentImage = useRef<string[]>([]);

  const { data: users } = useGetListUserByIds();
  const onFinish = async () => {
    // form.validateFields().then((formData) => {
    createGroup({
      ...values,
      content_images: contentImage.current,
    });
    // });
  };

  const dataRadioPost = [
    { value: EPermissionToPost.FREE, label: t('createGroup.free') },
    { value: EPermissionToPost.APPROVE, label: t('createGroup.approve') },
  ];
  const dataRadioGroup = [
    { value: ETypeGroup.PUBLIC, label: t('createGroup.public') },
    { value: ETypeGroup.PRIVATE, label: t('createGroup.private') },
  ];

  const updateValues = (_data: any) => {
    setValues({ ...values, ..._data });
  };

  const handleSetAvatar = (url: string) => {
    updateValues({ avatar_image: url });
  };

  const closeModal = () => {
    const isHaveValue =
      values.name?.length ||
      (values.admin?.length || 0) > 0 ||
      (values.member?.length || 0) > 0 ||
      (values.avatar_image?.length || 0) > 0 ||
      values.rule;

    const acceptClose = () => {
      setOpenModal(false);
      updateValues(defaultValue);
      setErrors({ name: '', rule: '' });
    };

    isHaveValue &&
      showConfirm({
        content: t('createGroup.confirmBeforeCancel'),
        onOk: acceptClose,
      });
    !isHaveValue && acceptClose();
  };

  return (
    <StyleCreateGroup>
      <ModalNormal
        visible={openModal}
        setVisible={setOpenModal}
        title={t('createGroup.addNewGroup')}
        width={600}
        padding="unset"
        className="bgAddGroup"
        preventCloseModal={true}
        onCancel={() => {
          closeModal();
        }}
      >
        <UploadImages selectedAvatar={values.avatar_image} setSelectedAvatar={handleSetAvatar} />
        <ContentStyle>
          <TitleStyle padding="5px 0">{t('createGroup.infoGroup')}</TitleStyle>
          <>
            <InputField
              onChange={(value: any) => {
                const name = value.target.value as string;
                const errorLength = validateMaxLength(70, name.trimStart());
                const errorRegex = validateRegex(name, REGEX_HIRAGANA_KATAKANA_KANJI_ALPHANUMERIC_FULL_WIDTH);
                updateValues({ name });
                setErrors({ ...errors, name: errorLength || errorRegex });
              }}
              isMust
              borderError={!!(errors.name && errors.name.length > 0)}
              label={t('createGroup.labelNameGroup')}
              className="title"
              value={values.name}
            />
            {errors.name && errors.name.length > 0 && <ErrorText>{t(errors.name, { max: 70 })}</ErrorText>}
          </>

          <SpaceStyle padding="10px 0" />

          <TitleStyle>{t('createGroup.permissionGroup')}</TitleStyle>
          <BaseRadio
            options={dataRadioGroup}
            onChange={(e: any) => {
              updateValues({ type: e?.target?.value === ETypeGroup.PUBLIC ? ETypeGroup.PUBLIC : ETypeGroup.PRIVATE });
            }}
            defaultValue={dataRadioGroup[0].value}
          />

          <SpaceStyle padding="10px 0" />

          <TitleStyle>{t('createGroup.permissionPost')}</TitleStyle>
          <BaseRadio
            onChange={(e: any) => {
              updateValues({
                post_permission:
                  e?.target?.value === EPermissionToPost.APPROVE ? EPermissionToPost.APPROVE : EPermissionToPost.FREE,
              });
            }}
            options={dataRadioPost}
            defaultValue={dataRadioPost[0].value}
          />

          <SpaceStyle padding="10px 0" />

          <TitleStyle padding="0 0 10px 0">{t('userManageGroup.modalSettingGroup.addAdmin')}</TitleStyle>
          <SelectMember
            style={{ width: '100%', height: '50px' }}
            data={users?.users || []}
            placeHolder={t('userManageGroup.modalSettingGroup.placeHolderAddAdmin')}
            onChange={(value: number[]) => updateValues({ admin: value || [] })}
            idLeave={[...(values.member || []), myInfo?.user_id]}
            value={values?.admin}
          />

          <SpaceStyle padding="10px 0" />

          <TitleStyle padding="0 0 10px 0">{t('userManageGroup.modalSettingGroup.addMember')}</TitleStyle>
          <SelectMember
            style={{ width: '100%', height: '50px' }}
            data={users?.users || []}
            placeHolder={t('userManageGroup.modalSettingGroup.placeHolderAddAdmin')}
            onChange={(value: number[]) => updateValues({ member: value || [] })}
            idLeave={[...(values?.admin || []), myInfo?.user_id]}
            value={values?.member}
          ></SelectMember>

          <SpaceStyle padding="10px 0" />

          <TitleStyle padding="0 0 10px 0">{t('createGroup.ruleGroup')}</TitleStyle>

          <EditorNoFormValue
            // maxLength={499}
            onChangeText={(value: string) => {
              // const valueShort = value.trimStart();
              updateValues({ rule: value });
            }}
            onUploadImages={(urls: string[], reason: string) => {
              contentImage.current = urls;
            }}
            height={300}
            value={values?.rule}
          />
          {errors.rule && errors.rule.length > 0 && <ErrorText>{t(errors.rule)}</ErrorText>}
        </ContentStyle>

        <StyleButton>
          <ButtonIconCustom
            textProperty={{ text: t('createGroup.cancel'), style: { color: '#fea628' } }}
            iconProperty={{ size: 20, shape: 'circle', src: images.createGroup.CancelIcon }}
            buttonProperty={{
              style: { width: '100%', height: '40px', borderRadius: '5px', border: '1px solid #fea628', flex: 0.9 },
            }}
            className="button-cancel"
            onClick={closeModal}
          />
          <SpaceStyle padding="0 10px" />

          <ButtonIconCustom
            buttonProperty={{
              type: 'submit',
              style: { height: 40, width: '100%', backGroundColor: '#fea628', borderRadius: '5px', flex: 1 },
            }}
            iconProperty={{ src: images.userManageGroup.AddMemberIcon, size: 20 }}
            disabled={errors.name.length > 0 || values.name?.length === 0 || isLoading}
            textProperty={{ text: t('createGroup.buttonAddGroup'), style: { color: 'white' } }}
            onClick={onFinish}
          />
        </StyleButton>
      </ModalNormal>
    </StyleCreateGroup>
  );
};

export default CreateGroup;
