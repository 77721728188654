import { SeeMoreStyle } from 'components/userManageGroup/ListReport/Sibar/Hidden/hiddentSibarStyle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FrameDataStyle } from './frameDataStyle';

interface IStyle {
  padding?: string;
}
interface IFrameListPost {
  onSeeMore?: () => void;
  isShowSeeMore?: boolean;
  style?: IStyle;
  textSeeMore?: string;
}

const FrameData: React.FC<IFrameListPost> = ({ children, ...props }) => {
  const { t } = useTranslation();

  const handleSeeMore = () => {
    props?.onSeeMore && props.onSeeMore();
  };
  return (
    <FrameDataStyle {...props?.style}>
      {children}
      {props?.isShowSeeMore && (
        <SeeMoreStyle onClick={handleSeeMore}>
          {props?.textSeeMore || t('userManageGroup.newFeed.buttonSeeMore')}
        </SeeMoreStyle>
      )}
    </FrameDataStyle>
  );
};

export default FrameData;
