import React, { FunctionComponent } from 'react';
import ModalWrapper from './styled';

interface ModalNormalProps {
  visible: boolean | any;
  ModalWrap?: string;
  className?: string;
  title: string;
  children: any;
  width?: number | string;
  afterClose?: () => void;
  maskClosable?: boolean;
  setVisible: any;
  padding?: string;
  onCancel?: () => void;
  isBottom?: boolean;
  preventCloseModal?: boolean;
  marginTop?: string;
}

const ModalNormal: FunctionComponent<ModalNormalProps> = ({
  visible,
  setVisible,
  className,
  onCancel,
  children,
  maskClosable = false,
  title = 'Modal',
  isBottom = false,
  preventCloseModal = false,
  marginTop,
  ...props
}) => {
  return (
    <ModalWrapper
      title={title}
      maskClosable={maskClosable}
      maskTransitionName=""
      className={className}
      visible={visible}
      isBottom={isBottom}
      marginTop={marginTop}
      onOk={() => setVisible((prev: boolean) => !prev)}
      onCancel={() => {
        !preventCloseModal && setVisible((prev: boolean) => !prev);
        if (onCancel) onCancel();
      }}
      footer={[]}
      {...props}
    >
      {children}
    </ModalWrapper>
  );
};

export default ModalNormal;
