import { Radio, RadioChangeEvent } from 'antd';
import React, { FunctionComponent } from 'react';
import StyleRadio from './style';

interface IRadio {
  options: any;
  size?: number;
  bgColor?: string;
  defaultValue?: any;
  onChange?: (e: RadioChangeEvent | any) => void;
  value?: any;
  disabled?: boolean;
}

const BaseRadio: FunctionComponent<IRadio> = ({ options, size, bgColor, ...props }) => {
  if (props?.value) {
    return (
      <StyleRadio size={size} bgColor={bgColor}>
        <Radio.Group
          options={options}
          {...props}
          onChange={(e: RadioChangeEvent) => {
            if (props.onChange) props.onChange(e);
          }}
          value={props?.value}
          disabled={props?.disabled}
        />
      </StyleRadio>
    );
  }
  return (
    <StyleRadio size={size} bgColor={bgColor}>
      <Radio.Group
        options={options}
        {...props}
        onChange={(e: RadioChangeEvent) => {
          if (props.onChange) props.onChange(e);
        }}
      />
    </StyleRadio>
  );
};
export default BaseRadio;
