import React, { ImgHTMLAttributes, useEffect, useState } from 'react';
import { Avatar, Image, message } from 'antd';
import { images } from 'assets/images-base';
import { EStatusSavePost, ETopic } from 'common';
import { useMutation, useQueryClient } from 'react-query';
import { sendGet, sendPost } from 'api/axios';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import useWindowSize from 'hooks/useWindow';
import PostWrapper, {
  AnswerBoxStyle,
  AnsweredStyle,
  AnswerStyle,
  AnswerTotalRecordStyle,
  AvatarStyle,
  CategoryStyle,
  CategoryText,
  ContentPost,
  ContentStyle,
  FrameAvatar,
  getIndicesOf,
  GroupNameStyle,
  HeaderContentStyle,
  HeaderTextStyle,
  IconViewStyle,
  LeftContentStyle,
  LineCategory,
  ListTagStyle,
  PostActionSavePostStyle,
  PostCreatedAtStyle,
  QuestionAndAnswerStyle,
  QuestionTypeStyle,
  SaveCountStyle,
  SeriesFrame,
  SeriesName,
  SeriesNameFrame,
  StatisticalStyle,
  StatisticalTextStyle,
  StickerSeries,
  TagItemStyle,
  TitleStyle,
  TrongStyle,
  UserNameStyle,
} from './styled';
import AvatarText from 'components/AvatarText';
import Answer from 'components/Answer';
import moment from 'moment';
import useNavigateFunction from 'helper/navigate';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from 'hooks/use-Selector/useAppSelector';
import { AuthorAvatar, AvatarDefault } from 'pages/Home/TopRanking/AuthorRanking/AuthorRankingStyle';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import ImageError from 'components/common/image-error/ImageError';
import { isTablet } from 'react-device-detect';

interface IDateProperty {
  isHiddenDot?: boolean;
  text?: string;
  node?: any;
}
interface ISaveProperty {
  node?: any;
}

interface IAvatarStyle {
  isHiddenAvatarMobile?: boolean;
}
interface Props {
  data?: any;
  showPostPin?: boolean;
  showSave?: boolean;
  className?: string;
  onClickTitle?: () => void;
  onClickUserName?: () => void;
  nameUser?: string;
  avatar?: string;
  defaultImage?: string;
  onSuccessSavePost?: () => void;
  showAvatar?: boolean;
  showComment?: boolean;
  isShowName?: boolean;
  isShowGroupName?: boolean;
  dateProperty?: IDateProperty;
  saveProperty?: ISaveProperty;
  avatarProperty?: IAvatarStyle;
  fromSearch?: boolean;
  onClickCategory?: (categoryId: number, value?: any) => void;
}

interface Props extends ImgHTMLAttributes<any> {
  fallback?: string;
}

enum IsDeletedUser {
  UN_DELETED = 0,
  DELETED = 1,
}

enum IsComplete {
  UN_COMPLETE = 0,
  COMPLETE = 1,
}

interface ISeriesDataRequest {
  id: string;
  name?: string;
  post_quantity: number;
  updated_at?: string;
  created_at?: string;
  master_post_id: number;
  master_post_creator?: number;
}

const getListUser = async (ids: number[]) => {
  const res = await sendGet('/user/list', { list_id: ids });
  return res?.data?.users;
};

const Post: React.FC<Props> = ({
  data,
  showPostPin = false,
  showSave = true,
  fallback,
  className,
  nameUser,
  avatar,
  showComment = true,
  showAvatar = true,
  isShowName = true,
  isShowGroupName = true,
  onClickTitle,
  onClickUserName,
  dateProperty,
  fromSearch,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const QueryClient = useQueryClient();

  const [hideAnswer, setHideAnswer] = useState<boolean>(true);
  const [savePost, setSavePost] = useState<boolean>(false);
  const [saveCount, setSaveCount] = useState<number>(data?.save_quantity || 0);

  const [comments, setComments] = useState<any[]>([]);

  const { navigateToProfile, navigateToGroup, navigateToHotTag, navigateToHotCategory, navigateToPostDetail } =
    useNavigateFunction();

  const { isMobile } = useWindowSize();

  const myInfo = useAppSelector((state) => state.auth.myInfo);

  const handleGetListUser = async () => {
    if (!isEmpty(data?.comments)) {
      const ids: number[] = data?.comments?.map((item: any) => item?.user_id);
      const users = await getListUser(
        ids.filter((id: number, index: number) => {
          return ids.indexOf(id) === index;
        })
      );
      const usersObj = users.reduce((newObject: any, item: any) => {
        return { ...newObject, [item?.user_id]: { avatar: item?.avatar, name: item?.name } };
      }, {});

      setComments(
        data?.comments?.map((item: any) => {
          return { ...item, ...usersObj[item?.user_id] };
        })
      );
    }
  };

  useEffect(() => {
    if (data) {
      setSavePost(data?.is_saved === EStatusSavePost.SAVED ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data && !hideAnswer && comments?.length === 0) {
      handleGetListUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hideAnswer]);

  const handleHideAnswer = () => {
    setHideAnswer(!hideAnswer);
  };

  const { mutate: updateSavePost, isLoading: loadingSave } = useMutation(
    async ({ post_id }: { post_id: string }) => {
      return sendPost(`/post/save`, { post_id });
    },
    {
      onSuccess: async (_value: any) => {
        setSavePost(!savePost);
        !savePost ? setSaveCount(saveCount + 1) : setSaveCount(saveCount - 1);
        QueryClient.refetchQueries(['search_list_post']);

        if (props.onSuccessSavePost) props.onSuccessSavePost();
      },
      onError: () => {
        message.error(t('error.errorSystem'));
      },
    }
  );

  const uiCategory = (category: any) => {
    return (
      <CategoryText
        onClick={() => {
          if (props?.onClickCategory) {
            props?.onClickCategory(category?.id);
            return;
          }
          navigateToHotCategory(category?.id, data?.group_id);
        }}
      >
        {category?.name}
      </CategoryText>
    );
  };

  const renderCategoryText = (category: any) => {
    if (!category?.sub_categories || category?.sub_categories?.length === 0) return uiCategory(category);

    return (
      <>
        {uiCategory(category)}
        <LineCategory>|</LineCategory>
        {uiCategory(category?.sub_categories[0])}
      </>
    );
  };

  const handleGotoProfile = () => {
    if (onClickUserName) {
      onClickUserName();
    }
  };

  const renderDateText = () => {
    const series = data?.series as ISeriesDataRequest;
    if (dateProperty?.text) return dateProperty?.text;

    if (data?.topic_id === ETopic.QUESTION) return t('component.post.questionDate');

    if (series?.master_post_creator && series?.master_post_creator !== data?.user?.id)
      return t('component.post.donateDay');

    if ((series?.name && series) || !series) return t('component.post.shareDate');

    return series?.created_at ? t('component.post.createdAt') : t('component.post.updatedAt');
  };

  const linkToPostDetail = () => {
    if (!data.group_id) {
      return `/home/detail-post/${data?.id}`;
    } else {
      return `/group/detail-group/${data.group_id}/${data?.id}`;
    }
  };

  const renderIconSave = () => {
    if (!props?.saveProperty?.node)
      return (
        myInfo?.user_id !== data?.user?.id &&
        showSave && (
          <PostActionSavePostStyle>
            {!savePost ? (
              <Image
                width={18}
                height={21}
                onClick={() => !loadingSave && updateSavePost({ post_id: data?.id })}
                preview={false}
                src={images.home.VectorImg}
              />
            ) : (
              <Image
                onClick={() => !loadingSave && updateSavePost({ post_id: data?.id })}
                preview={false}
                src={images.layout.SaveYellow}
              />
            )}
          </PostActionSavePostStyle>
        )
      );

    return props?.saveProperty?.node;
  };

  const replaceAt = (index: number, string: string, length: number) => {
    if (index < 0) {
      return string;
    }
    return (
      string?.substring(0, index) +
      `<span class="search-word">${string?.slice(index, index + length)}</span>` + // length 33 (33*index)
      string?.substring(index + length)
    );
  };

  const checkSearchKey = (content: string) => {
    const text = content;
    const searchText = query.get('search') || '';

    if (!query.get('search')) {
      return text;
    }
    const indices = getIndicesOf(searchText?.trim(), text);
    let string = text;
    indices.forEach((element: number, index: number) => {
      string = replaceAt(element + 33 * index, string, searchText?.length);
    });
    return string;
  };

  const uiSeries = (series: ISeriesDataRequest) => {
    return (
      <SeriesFrame isParentSeries={!!series?.name}>
        {series?.name && (
          <>
            <SeriesNameFrame
              avatarProperty={{
                dot: true,
                size: 5,
                style: { backGround: 'rgba(144,144,144,1)' },
              }}
              textProperty={{
                content: (
                  <SeriesName>
                    Series:
                    <SpaceStyle padding="1.5px" />
                    <span onClick={() => navigateToPostDetail(series?.master_post_id, data?.group_id)}>
                      {series?.name}
                    </span>
                  </SeriesName>
                ),
                marginLeft: isMobile && !isTablet ? 'unset' : '10px',
              }}
              showAvatar={!isMobile}
            />
          </>
        )}

        {(series?.name || isMobile) && <SpaceStyle padding="5px" />}
        <StickerSeries>
          <span>Series</span>
          {series?.post_quantity !== 0 && (
            <>
              <SpaceStyle padding="6.25px" />
              <img alt="" src={images.series.PostSeriesIcon} />
              <SpaceStyle padding="3.75px" />
              <span>{series?.post_quantity}</span>
            </>
          )}
        </StickerSeries>
      </SeriesFrame>
    );
  };

  return (
    <PostWrapper isHiddenAvatar={props?.avatarProperty?.isHiddenAvatarMobile}>
      {!isMobile && showAvatar && (
        // <AvatarStyle
        //   avatarProperty={{
        //     size: 40,
        //     src: data?.user?.avatar ?? avatar,
        //     shape: 'circle',
        //     onClickAvatar: handleGotoProfile,
        //   }}
        // />
        <FrameAvatar>
          <AuthorAvatar size={41} onClick={handleGotoProfile}>
            <Avatar
              size={40}
              src={<ImageError src={data?.user?.avatar ?? avatar} />}
              icon={<AvatarDefault alt="" src={images.createGroup.profileAvatar} />}
            />
          </AuthorAvatar>
          <SpaceStyle padding="3px" />
        </FrameAvatar>
      )}

      <LeftContentStyle>
        <HeaderContentStyle>
          <HeaderTextStyle>
            {isShowName && (
              <AvatarStyle
                textProperty={{
                  content: (
                    <UserNameStyle
                      disabled={data?.user?.is_deleted === IsDeletedUser.DELETED}
                      onClick={handleGotoProfile}
                      errorUser={!data?.user?.name}
                    >
                      {data?.user?.name || t('common.userError')}
                    </UserNameStyle>
                  ),
                  marginLeft: isMobile && !props?.avatarProperty?.isHiddenAvatarMobile ? 7 : 0,
                }}
                showAvatar={isMobile && !props?.avatarProperty?.isHiddenAvatarMobile}
                avatarProperty={{
                  size: 18,
                  src: data?.user?.avatar ?? avatar,
                  shape: 'circle',
                  onClickAvatar: handleGotoProfile,
                }}
              />
            )}

            {data?.group_name && isShowGroupName && (
              <>
                <TrongStyle>trong</TrongStyle>
                <GroupNameStyle onClick={() => navigateToGroup(data?.admin_group, data?.group_id)}>
                  {data?.group_name ?? ''}
                </GroupNameStyle>
              </>
            )}
          </HeaderTextStyle>

          {!dateProperty?.node ? (
            <PostCreatedAtStyle
              showAvatar={!isMobile && !dateProperty?.isHiddenDot}
              avatarProperty={{ dot: true, size: 5, style: { backGround: 'rgba(144,144,144,1)' } }}
              textProperty={{
                marginLeft: isMobile || dateProperty?.isHiddenDot ? 0 : 10,
                content: `${renderDateText()}: ${data?.created_at?.split(' ')[0]}`,
              }}
              className={dateProperty?.isHiddenDot ? 'hidden-dot' : ''}
            />
          ) : (
            dateProperty?.node
          )}

          {data?.topic_id === ETopic.QUESTION && (
            <QuestionTypeStyle>
              <QuestionAndAnswerStyle>{'Q&A'}</QuestionAndAnswerStyle>
              {data?.is_complete === IsComplete.COMPLETE && (
                <AnsweredStyle>{t('component.post.answered')}</AnsweredStyle>
              )}
            </QuestionTypeStyle>
          )}

          {data?.series && uiSeries(data?.series)}
        </HeaderContentStyle>

        <TitleStyle
          dangerouslySetInnerHTML={{
            __html: checkSearchKey(data?.title) || '',
          }}
          to={linkToPostDetail()}
          // onClick={() => {
          //   if (onClickTitle) {
          //     onClickTitle();
          //   }
          // }}
        />

        <ContentStyle>
          <StatisticalStyle>
            <IconViewStyle
              avatarProperty={{ src: images.component.ViewPost, size: 13, shape: 'square' }}
              textProperty={{
                content: <StatisticalTextStyle>{data?.view_quantity || 0}</StatisticalTextStyle>,
                marginLeft: 5.63,
              }}
            />
            <SaveCountStyle
              avatarProperty={{ src: images.component.SavePost, size: 13, shape: 'square', marginLeft: 13.12 }}
              textProperty={{ content: <StatisticalTextStyle>{saveCount}</StatisticalTextStyle>, marginLeft: 8.13 }}
            />
            <AvatarText
              avatarProperty={{ src: images.component.Post, size: 13, shape: 'square', marginLeft: 11.25 }}
              textProperty={{
                content: <StatisticalTextStyle>{data?.comment_total || 0}</StatisticalTextStyle>,
                marginLeft: 6.25,
              }}
            />
          </StatisticalStyle>

          <CategoryStyle>
            {data?.categories?.map((_item: any, index: number) => {
              return (
                <>
                  {renderCategoryText(_item)}
                  {index !== data?.categories?.length - 1 ? <LineCategory>-</LineCategory> : <></>}
                </>
              );
            })}
          </CategoryStyle>
        </ContentStyle>

        <ListTagStyle>
          {data?.post_hashtags?.map((item: any, index: number) => {
            return (
              <TagItemStyle
                key={item?.id}
                marginRight={index !== data?.post_hashtags?.length - 1 ? '5px' : '0px'}
                onClick={() => navigateToHotTag(item?.hashtag_id, data?.group_id || undefined)}
              >
                {item?.hashtag?.name}
              </TagItemStyle>
            );
          })}
        </ListTagStyle>

        {fromSearch && (
          <ContentPost
            dangerouslySetInnerHTML={{
              __html: checkSearchKey(data?.content) || '',
            }}
          />
        )}

        {data?.comment_total > 0 && data?.topic_id === ETopic.QUESTION && showComment && (
          <AnswerStyle span={24}>
            <AnswerTotalRecordStyle onClick={handleHideAnswer}>
              <span>
                {t('component.post.answer')} ({data?.comment_total})
              </span>
              <Image
                preview={false}
                src={images.group.CaretUp}
                className={`${hideAnswer ? 'answer-total-record-active-btn' : 'answer-total-record-btn'}`}
              />
            </AnswerTotalRecordStyle>

            <AnswerBoxStyle className={`${hideAnswer ? ' answers-box-active' : ''}`}>
              {comments?.map((answer: any) => {
                return (
                  <>
                    <Answer
                      className="answer-component"
                      userAvatar={answer?.avatar ?? images.profile.avatarDefault}
                      userContent={
                        <span className="cursor" onClick={() => navigateToProfile(answer?.user_id)}>
                          {answer?.name ?? t('component.post.user')}
                        </span>
                      }
                      content={answer?.content}
                      content_images={answer?.content_images}
                      dateContent={t('component.post.answerDate', {
                        date: moment(answer?.created_at, 'YYYY-MM-DD hh:mm').format('DD/MM/YYYY'),
                      })}
                    />
                  </>
                );
              })}
            </AnswerBoxStyle>
          </AnswerStyle>
        )}
      </LeftContentStyle>

      {renderIconSave()}
    </PostWrapper>
  );
};

export default Post;
