import { Avatar } from 'antd';
import styled from 'styled-components';

export const FilterHomeWrapper = styled.div``;
export const IconFilter = styled.img`
  cursor: pointer;
`;
export const DoteFilter = styled(Avatar)`
  border-radius: 20px;
  background-color: tomato;
  position: absolute;
  right: 0px;
  margin-right: -4px;
  margin-top: -4px;
  border: none;
`;
