import { Select } from 'antd';
import styled from 'styled-components';

const WrapperStyle = styled.div`
  width: 100%;
  position: relative;
`;

const SelectCustomStyle = styled(Select)<any>`
  width: ${(props) => props?.width || 'unset'};
  border-radius: 5px;
  .ant-select-selector {
    min-height: ${(props) => props?.height + ' !important' || 'unset'};
    border-radius: 5px !important;

    border: ${(props) => (props?.isFocus ? '1px solid #FEA628 !important' : 'unset')};
    .ant-select-selection-overflow {
      max-height: 200px;
      overflow: auto;
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 0px;
        background-color: #f5f5f5;
      }

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: #f5f5f5;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 1px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #555;
      }
      .ant-select-selection-overflow-item {
        z-index: 2;
      }
    }
  }
  input {
    height: 100% !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

const PlaceHolderStyle = styled.div<any>`
  position: absolute;
  top: ${(props) => (!props?.isFocus && !props?.isHaveValue ? '30%' : '-12px')};
  margin-left: ${(props) => (!props?.isFocus && !props?.isHaveValue ? '15px' : 'unset')};
  color: ${(props) => (!props?.isFocus && !props?.isHaveValue ? '#909090' : '#FEA628')};
  font-weight: 400;
  font-size: 14px;
  padding: ${(props) => (!props?.isFocus && !props?.isHaveValue ? 'unset' : '0px 5px')};
  background: ${(props) => (!props?.isFocus && !props?.isHaveValue ? 'unset' : 'white')};
  left: ${(props) => (!props?.isFocus && !props?.isHaveValue ? 'unset' : '5px')};
  transition: all 0.1s linear;
  transform: ${(props) => (!props?.isFocus && !props?.isHaveValue ? 'unset' : 'scale(0.8)')};
`;

export { SelectCustomStyle, PlaceHolderStyle, WrapperStyle };
