import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    border: none;
    background-color: transparent;
  }
  .ant-upload-picture-card-wrapper {
    display: contents;
    width: fit-content;
    height: fit-content;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: fit-content;
    height: fit-content;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    flex-direction: column;
    align-items: flex-start;
  }
  position: relative;
`;

export const TextError = styled.div`
  color: red;
  position: absolute;
  left: 0;
  bottom: -1em;
  width: 20em;
  text-align: left;
`;

const filter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.,';
export const generateFileName = (fileName: string) => {
  let result: string = '';
  for (const character of fileName) {
    if (filter.includes(character)) result += character;
  }
  if (result.length < 3) result += 'img';
  return result;
};
