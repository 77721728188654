import images from 'assets/images-base';
import React, { useEffect, useState } from 'react';
import { ImageErrorStyle, WrapperStyle } from './ImageErrorStyle';

interface IStyle {
  padding?: string;
  borderRadius?: number | string;
}

interface IProps {
  src?: string;
  width?: number | string;
  height?: number | string;
  style?: IStyle;
  onClick?: () => void;
  alt?: string;
}

const ImageError: React.FC<IProps> = (props) => {
  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    if (props?.src) {
      setSrc(props?.src || images.profile.avatarDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.src]);

  return (
    <WrapperStyle style={{ ...props?.style, width: props?.width, height: props?.height }} onClick={props?.onClick}>
      <ImageErrorStyle
        preview={false}
        src={src}
        alt={props?.alt || ''}
        onError={() => setSrc(images.profile.avatarDefault)}
      />
    </WrapperStyle>
  );
};

export default ImageError;
