import DatePickerOne from 'components/common/date-picker/date-picker-one';
import styled from 'styled-components';

export const FilterTimeWrapper = styled.div`
  margin-bottom: 10px;
`;
export const DatePickerOneHome = styled(DatePickerOne)`
  width: 100%;
  .date-picker-body {
    margin-top: 5px;
    width: 100%;
    margin-bottom: 10px;
  }
`;
