import GroupPage from 'pages/Group/GroupPage';
import React from 'react';
const SignUp = React.lazy(() => import('pages/SignUp'));
const WrapCreatePost = React.lazy(() => import('pages/CreateAndEditPost/WrapCreatePost'));
const CreateAndEditPost = React.lazy(() => import('pages/CreateAndEditPost/CreateAndEditPost'));
// const CreateAndEditPost = React.lazy(() => import('pages/CreatePost'));
const Home = React.lazy(() => import('pages/Home'));
const UserManageGroup = React.lazy(() => import('pages/UserManageGroup'));
const Dashboard = React.lazy(() => import('pages/Admin/Dashboard/AdminDashboard'));
const Post = React.lazy(() => import('pages/Admin/Post'));
const Group = React.lazy(() => import('pages/Admin/Group'));
const Master = React.lazy(() => import('pages/Admin/Master'));
const Permission = React.lazy(() => import('pages/Admin/Permission'));
const DetailPermission = React.lazy(() => import('pages/Admin/Permission/DetailPermission'));
const DetailPost = React.lazy(() => import('pages/DetailPost/DetailPost'));
const DetailShare = React.lazy(() => import('pages/Home/DetailShare/DetailShare'));
const DetailGroup = React.lazy(() => import('pages/Group/DetailGroup/DetailGroup'));
const DetailWaiting = React.lazy(() => import('pages/Group/DetailWaiting/DetailWaiting'));
const DetailQuestion = React.lazy(() => import('pages/Home/DetailQuestion/DetailQuestion'));
const DetailUser = React.lazy(() => import('pages/Home/DetailUser/DetailUser'));
const DetailReport = React.lazy(() => import('pages/Group/DetailReport/DetailReport'));
const GroupJoining = React.lazy(() => import('pages/GroupJoining'));
// const EditPost = React.lazy(() => import('pages/EditPost'));
const SearchPage = React.lazy(() => import('pages/Search/SearchPage'));
const SearchGroup = React.lazy(() => import('pages/SearchGroup/SearchGroup'));
const GroupClassification = React.lazy(() => import('pages/GroupClassification'));
const FindPostHashtag = React.lazy(() => import('pages/FindPostHashtag/FindPostHashtag'));
const PreviewPost = React.lazy(() => import('pages/PreviewPost/PreviewPost'));
const CreateAndEditSeries = React.lazy(() => import('pages/SeriesPost/CreateAndEditSeries'));
const WrapperSeriesPost = React.lazy(() => import('pages/SeriesPost/WrapperSeriesPost'));
const ListSeries = React.lazy(() => import('pages/ListSeries/ListSeries'));
const ListSeriesGroup = React.lazy(() => import('pages/ListSeriesGroup'));
const FindPostCategory = React.lazy(() => import('pages/FindPostCategory/FindPostCategory'));
const ProfilePage = React.lazy(() => import('pages/Profile2/ProfilePage'));
const ImportantPost = React.lazy(() => import('pages/ImportantPost/ImportantPost'));
const ImportantPostGroup = React.lazy(() => import('pages/ImportantPostGroup/ImportantPostGroup'));
const ListPost = React.lazy(() => import('pages/ListPosts/ListPosts'));
export interface RouterItemInterface {
  desktop: any;
  path: string;
  exact: boolean;
  layout: any;
  mobile: any;
  private: boolean;
}

export const NewsFeedRoute = [
  {
    desktop: SignUp, // import('pages/SignUp'),
    path: 'register',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: WrapCreatePost, // CreateAndEditPost,
    path: 'create',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailPost, // import('pages/DetailPost'),
    path: 'detail',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },

  {
    desktop: DetailShare, // import('pages/DetailPost'),
    path: 'detail-post/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailQuestion, // import('pages/DetailPost'),
    path: 'detail-question/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: CreateAndEditPost, // EditPost,
    path: 'edit-post/:id',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailUser, // import('pages/detail-user'),
    path: 'detail-user/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: SearchPage, // import('pages/detail-user'),
    path: 'search',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: ProfilePage,
    path: 'my-page/:personId',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: ProfilePage,
    path: 'profile/:personId',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: FindPostHashtag,
    path: 'hot-tag/:tagId',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: PreviewPost,
    path: 'preview-post',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: WrapperSeriesPost,
    path: 'create-series-post',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: CreateAndEditSeries,
    path: 'edit-series-post/:idSeries/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: ListSeries,
    path: 'list-series/:idSeries',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: ImportantPost,
    path: 'important-post',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: Home, // import('pages/Home'),
    path: '',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: ListPost,
    path: 'list-post',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: FindPostCategory,
    path: 'hot-category/:idCategory',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
];
export const HomeItem = {
  desktop: Home, // import('pages/Home'),
  path: '/',
  exact: true,
  layout: null,
  mobile: null,
  private: false,
};

export const GroupRoute = [
  {
    desktop: CreateAndEditPost, // import('pages/CreatePost'),
    path: 'create/',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: CreateAndEditPost, // EditPost,
    path: 'edit-post/:id',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailGroup, // import('pages/detail-report'),
    path: 'detail-group/:id/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailReport, // import('pages/detail-report'),
    path: 'user-manage-group/:id/detail-report/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailWaiting, // import('pages/detail-report'),
    path: 'user-manage-group/:id/detail-waiting/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailReport, // import('pages/detail-report'),
    path: 'classification/:id/detail-report/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailWaiting, // import('pages/detail-report'),
    path: 'classification/:id/detail-waiting/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: GroupJoining, // import('pages/UserManageGroup'),
    path: 'group-joining/:id',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: UserManageGroup, // import('pages/UserManageGroup'),
    path: 'user-manage-group/:id',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: GroupPage,
    path: '',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: SearchGroup,
    path: 'group-joining/:id/search',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: GroupClassification, // import('pages/detail-report'),
    path: 'classification/:id',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: SearchGroup,
    path: 'user-manage-group/:id/search',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: SearchGroup, // import('pages/detail-report'),
    path: 'classification/:id/search',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: CreateAndEditSeries,
    path: 'create-series-post',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: CreateAndEditSeries,
    path: 'edit-series-post/:idGroup/:idSeries/:idPost',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: ListSeriesGroup,
    path: 'list-series/:id/:idSeries',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: ImportantPostGroup,
    path: 'important-post/:id',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: SearchPage,
    path: 'search',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: PreviewPost,
    path: 'preview-post',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
];

export const ManageRoute = [
  {
    desktop: Dashboard, // import('pages/Admin/Dashboard'),
    path: 'dashboard',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: Post, // import('pages/Admin/Post'),
    path: 'posts',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: Group, // import('pages/Admin/Group'),
    path: 'group',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: Master, // import('pages/Admin/Master'),
    path: 'master',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: Permission, // import('pages/Admin/Permission'),
    path: 'decentralization',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: DetailPermission, // import('pages/Admin/Permission/DetailPermission'),
    path: 'decentralization/:id',

    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: () => null, // import('pages/Admin/Dashboard'),
    path: '',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
  {
    desktop: SearchPage, // import('pages/detail-user'),
    path: 'search',
    exact: true,
    layout: null,
    mobile: null,
    private: false,
  },
];
