import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Image, Form, Input } from 'antd';
import React from 'react';
import images from 'assets/images-base';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from 'common/styled-base';
import useWindowSize from 'hooks/useWindow';
import FilterByCategory from './FilterByCategory/FilterByCategory';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import DatePickerOne from 'components/common/date-picker/date-picker-one';
import HashTagList from './FilterByHashtag/FilterByHashtag';
import ButtonCancel from 'components/Cms/Cancel';
import ButtonSave from 'components/Cms/Save';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import ItemFilter from './ItemFilter';
import { successPopup } from 'helper/modal-confirm';
import BaseCheckBox from 'components/common/checkboxGroup';
import devicesBreakpoint from 'helper/devices-breakpoint';
import { EFollow, EIsSeries, ETopic } from 'common';
import FilterCategoryMobile from './FilterByCategory/FilterCategoryMobile/FilterCategoryMobile';
import ModalNormal from 'components/common/modal';

interface IProps {
  drawerClosedByX: () => void;
  closeSearch: () => void;
  form: FormInstance<any>;
  categoriesIds?: { categoriesIds: number[]; onSetCategoriesIds: (value: number[]) => void };
}

interface IFilterCategoryPc {
  onSetCategorySelected: (value: any) => void;
}

const handleFormatData = (values: any) => {
  values.category_id = values.category_id?.map((item: any) => item.id);

  const subCategories = values.category_id?.reduce((newSubCategoriesArr: number[], item: number) => {
    if (values.sub_category_id[item] && values.sub_category_id[item]?.length > 0) {
      const childCategories = values.sub_category_id[item]?.map((sub_item: any) => sub_item?.id);
      return [...newSubCategoriesArr, ...childCategories];
    }
    return [...newSubCategoriesArr, item];
  }, []);

  const removeDuplicates = subCategories.filter((id: number, index: number) => {
    return subCategories.indexOf(id) === index;
  });

  values.sub_category_id = removeDuplicates;

  if (values?.end_date) {
    values.end_date = moment(values.end_date).format('YYYY-MM-DD');
  }
  if (values?.start_date) {
    values.start_date = moment(values.start_date).format('YYYY-MM-DD');
  }
  if (values?.is_following) {
    values.is_following?.includes(EFollow.ALL)
      ? (values.is_following = null)
      : (values.is_following = values.is_following[0]);
  }

  if (values?.topic_id) {
    values.topic_id?.includes(ETopic.All) ? (values.topic_id = null) : (values.topic_id = values.topic_id[0]);
  }

  if (values?.is_series) {
    values.is_series?.includes(EIsSeries.SERI_OR_NOT)
      ? (values.is_series = EIsSeries.SERI_OR_NOT)
      : (values.is_series = values.is_series[0]);
  }

  return values;
};

const ContentFilter: React.FC<IProps> = ({ drawerClosedByX, closeSearch, form, categoriesIds }) => {
  const { t } = useTranslation();
  const query = useQueryClient();

  const [hidden, setHidden] = useState([true, true, true, true, true, true]);
  const [oldFollow, setOldFollow] = useState<number[]>([EFollow.ALL, EFollow.FOLLOWING, EFollow.NOT_FOLLOWING]);
  const [oldSeries, setOldSeries] = useState<number[]>([EIsSeries.SERI_OR_NOT, EIsSeries.SERI, EIsSeries.NOT_SERIES]);
  const [oldTopic, setOldTopic] = useState<number[]>([ETopic.All, ETopic.QUESTION, ETopic.REACT]);
  const showItem = [...hidden];
  const { isMobile } = useWindowSize();

  const handleShowItem = (index: number) => {
    showItem[index] = !showItem[index];
    setHidden(showItem);
  };

  const TYPE_FOLLOW = [
    { label: `${t('group.all')}`, value: EFollow.ALL },
    { label: `${t('group.follow')}`, value: EFollow.FOLLOWING },
    { label: `${t('group.typeNotFollow')}`, value: EFollow.NOT_FOLLOWING },
  ];
  const TYPE_TOPIC = [
    { value: 0, label: `${t('group.all')}` },
    { value: 2, label: `${t('group.QNA')}` },
    { value: 1, label: `${t('group.sharei')}` },
  ];
  const SERIES = [
    { value: EIsSeries.SERI_OR_NOT, label: `${t('group.all')}` },
    { value: EIsSeries.SERI, label: `${t('group.series')}` },
    { value: EIsSeries.NOT_SERIES, label: `${t('group.normalPost')}` },
  ];

  const onFinish = async (values: any) => {
    values = handleFormatData(values);

    const setPostParams: any = query.getQueryDefaults('setPostParams');

    if (setPostParams) setPostParams(values);
    closeSearch();
  };

  const handleValuesChange = () => {
    if (
      form.getFieldValue('start_date') &&
      form.getFieldValue('end_date') &&
      moment(form.getFieldValue('start_date')).valueOf() - moment(form.getFieldValue('end_date')).valueOf() > 0
    ) {
      successPopup({ content: t('group.validDate') });
    }
  };
  const setValueTopic = (value: number[], name: string, setOldValue: (value: number[]) => void) => {
    form.setFieldsValue({ [name]: value });
    setOldValue(value);
  };

  const onChange = (
    value: number[],
    all: number,
    value1: number,
    value2: number,
    name: string,
    { _oldValue, setOldValue }: { _oldValue: number[]; setOldValue: (value: number[]) => void }
  ) => {
    if (value[0] === all) {
      if (_oldValue[0] !== all) {
        setValueTopic([all, value2, value1], name, setOldValue);
      } else if (value.length === 2 && _oldValue.length === 3) {
        value.shift();
        setValueTopic(value, name, setOldValue);
      }
      return;
    }

    if (value.length === 2 && _oldValue[0] === all) {
      setValueTopic([], name, setOldValue);
    } else if (value.length === 2 && _oldValue[0] !== all) {
      setValueTopic([all, value1, value2], name, setOldValue);
    } else {
      setValueTopic(value, name, setOldValue);
    }
  };

  return (
    <Styled>
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
        initialValues={{
          category_id: [],
          is_following: [2, 1, 0],
          topic_id: [0, 2, 1],
          is_series: [EIsSeries.SERI, EIsSeries.NOT_SERIES, EIsSeries.SERI_OR_NOT],
          start_date: undefined,
          end_date: undefined,
          sub_category_id: {},
        }}
      >
        <div className="title-drawer d-flex justify-content-center position-relative mb-50">
          <div className="close cursor" onClick={drawerClosedByX}>
            <img src={images.group.modalClose} alt="close" />
          </div>
          <span>{t('group.filterPost')}</span>
        </div>

        <ItemFilter showItem={showItem[0]} title={t('createPost.topic')} handleShowItem={() => handleShowItem(0)} />
        {showItem[0] &&
          (isMobile ? (
            <FilterCategoryMobile valueParent={categoriesIds?.categoriesIds} form={form} />
          ) : (
            <Form.Item name="category_id">
              <FilterByCategory form={form} categoriesIds={categoriesIds} />
            </Form.Item>
          ))}

        <Form.Item name="sub_category_id" hidden>
          <Input />
        </Form.Item>

        <ItemFilter showItem={showItem[1]} title={t('group.typeFollow')} handleShowItem={() => handleShowItem(1)} />
        {showItem[1] && (
          <Form.Item name="is_following">
            <BaseCheckBox
              options={TYPE_FOLLOW}
              className="checkbox"
              onChange={(e: number[]) =>
                onChange(e, EFollow.ALL, EFollow.FOLLOWING, EFollow.NOT_FOLLOWING, 'is_following', {
                  _oldValue: oldFollow,
                  setOldValue: setOldFollow,
                })
              }
              defaultChecked
            />
          </Form.Item>
        )}

        <div className="line" />

        <ItemFilter showItem={showItem[2]} title={t('group.typePost')} handleShowItem={() => handleShowItem(2)} />
        {showItem[2] && (
          <Form.Item name="topic_id">
            <BaseCheckBox
              options={TYPE_TOPIC}
              className="checkbox"
              onChange={(e: number[]) =>
                onChange(e, ETopic.All, ETopic.QUESTION, ETopic.REACT, 'topic_id', {
                  _oldValue: oldTopic,
                  setOldValue: setOldTopic,
                })
              }
              defaultChecked
            />
          </Form.Item>
        )}

        <div className="line" />

        <ItemFilter showItem={showItem[3]} title={t('group.searchBySeries')} handleShowItem={() => handleShowItem(3)} />
        {showItem[3] && (
          <Form.Item name="is_series">
            <BaseCheckBox
              onChange={(e: number[]) => {
                onChange(e, EIsSeries.SERI_OR_NOT, EIsSeries.SERI, EIsSeries.NOT_SERIES, 'is_series', {
                  _oldValue: oldSeries,
                  setOldValue: setOldSeries,
                });
              }}
              options={SERIES}
              className="checkbox"
              defaultChecked
            />
          </Form.Item>
        )}
        <div className="line" />

        <ItemFilter showItem={showItem[4]} title={t('group.duree')} handleShowItem={() => handleShowItem(4)} />
        {showItem[4] && (
          <div className="date-picker">
            <Form.Item name="start_date">
              <DatePickerOne
                placeholder={t('group.from')}
                onChange={(date: any) => form.setFieldsValue({ start_date: date })}
                format={'DD/MM/YYYY'}
              />
            </Form.Item>
            <Form.Item name="end_date">
              <DatePickerOne
                placeholder={t('group.to')}
                onChange={(date: any) => form.setFieldsValue({ end_date: date })}
                format={'DD/MM/YYYY'}
              />
            </Form.Item>
          </div>
        )}

        <div className="line" />

        <ItemFilter showItem={showItem[5]} title={t('group.hashtag')} handleShowItem={() => handleShowItem(5)} />
        {showItem[5] && (
          <Form.Item name="hashtag">
            <HashTagList form={form} />
          </Form.Item>
        )}

        <div className="line" />
        <div className="buttons">
          <ButtonCancel type="reset" icon={images.group.iconReset} width={220} height={45} label="Reset" />
          <ButtonSave type="submit" icon={images.group.iconFilter} width={220} height={45} label="Lọc" />
        </div>
      </Form>
    </Styled>
  );
};

interface Props {
  className?: string;
  categoriesIds?: {
    categoriesIds: number[];
    onSetCategoriesIds: (value: number[]) => void;
    onSetParamFilter: (value: any) => void;
  };
}
const FilterPost: React.ForwardRefRenderFunction<React.ReactNode, Props> = ({ className, categoriesIds }, ref) => {
  const { isMobile } = useWindowSize();
  const [shown, setShown] = useState(false);
  const [form] = useForm();
  const showSidebar = () => {
    setShown(true);
  };

  const drawerClosedByX = () => {
    setShown(false);
    form.resetFields();

    const params = form.getFieldsValue();
    if (categoriesIds?.onSetParamFilter) {
      const newParams = handleFormatData(params);
      categoriesIds.onSetParamFilter(newParams);
    }
  };
  const handleFilter = () => {
    setShown(false);
  };

  useImperativeHandle(ref, () => {
    return {
      onSetValueTopic: (value: number[]) => form.setFieldsValue({ topic_id: value }),
    };
  });
  return (
    <Styled className={className || ''}>
      <Button
        type="primary"
        onClick={showSidebar}
        icon={<Image preview={false} src={images.group.Filter} className="filter-icon" />}
      />
      <ModalNormal visible={shown} setVisible={() => {}} width={isMobile ? '80%' : '47%'} title="Lọc bài viết">
        <ContentFilter
          categoriesIds={categoriesIds}
          drawerClosedByX={drawerClosedByX}
          closeSearch={handleFilter}
          form={form}
        />
      </ModalNormal>
    </Styled>
  );
};

export default forwardRef(FilterPost);

const Styled = styled.div`
  .mb-50 {
    margin-bottom: 50px;
  }
  .position-relative {
    position: relative;
  }
  .close {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #e0e0e0;
    margin-bottom: 15px;
  }
  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-picker {
    width: 100%;
    max-width: 249px;
  }
  .ant-btn-primary {
    border: none;
    background: none;
    text-shadow: unset;
    box-shadow: unset;
  }
  .title-drawer {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    color: ${colors.colorText};
    text-align: center;
  }
  .date-picker {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    .ant-form-item {
      width: 100%;
      max-width: 249px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .close-dropdown {
    transform: rotate(180deg);
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: center;
    @media ${devicesBreakpoint.DEVICE_BREAKPOINTS_MAX.tabletS_640} {
      display: block;
      button {
        max-width: 100%;
        width: 100%;
        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
    button {
      flex-shrink: 0;
      &:first-child {
        margin-right: 24px;
      }
    }
  }

  @media ${devicesBreakpoint.DEVICE_BREAKPOINTS_MAX.tabletS_640} {
    .date-picker,
    .checkbox {
      display: flex;
      flex-direction: column;
    }
    .title-drawer {
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      justify-content: flex-start;
      padding-left: 30px;
    }
  }
`;
