import React from 'react';
import { Outlet } from 'react-router-dom';
import DefaultLayout from 'components/layout/DefaultLayout';

const ParentComponent: React.FC<{ children: React.ReactNode; parentPath: string }> = ({ children, parentPath }) => {
  const path = window.location.pathname;
  const splitPath = path.split('/');

  if (splitPath.length === 0 || splitPath[1] === parentPath) {
    return <DefaultLayout>{children}</DefaultLayout>;
  }

  return <Outlet />;
};

export default ParentComponent;
