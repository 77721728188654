import { CheckOutlined } from '@ant-design/icons';
import { FormInstance, Image } from 'antd';
import images from 'assets/images-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TreeSelectStyle, {
  ChildsListItemStyle,
  ListItemStyle,
  OptionItemStyle,
  ParentListItemStyle,
} from './treeSelectStyle';

export interface IDataDefault {
  name: string;
  children?: IDataDefault[];
  id: number;
  parentId?: number;
}

interface IChidProperty {
  onPickChild?: (value: IDataDefault) => void;
}
interface ITreeSelect {
  maxParent?: number;
  maxChild?: number;
  placeHolder?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  form?: FormInstance<any>;
  name?: string;
  data?: IDataDefault[];
  dropdownStyle?: React.CSSProperties;
  defaultValueParent?: number[];
  defaultValueChild?: string[];
  required?: boolean;
  valueParent?: number[];
  childProperty?: IChidProperty;
}
const TreeSelectCustom: React.FC<ITreeSelect> = ({
  maxParent,
  maxChild,
  form,
  data,
  defaultValueParent = [],
  defaultValueChild = [],
  valueParent = [],
  required = true,
  ...props
}) => {
  const [pickedId, setPickedId] = useState<number[]>(defaultValueParent);
  const [maxChildrenId, setMaxChildrenId] = useState<string[]>(defaultValueChild);
  const [tagRender, setTagRender] = useState<string[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValueParent?.length > 0) {
      const names = data?.reduce((arrName: string[], item: IDataDefault) => {
        if (defaultValueParent?.includes(item?.id)) return [...arrName, item?.name];
        return arrName;
      }, []);
      setTagRender(names || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValueParent]);

  useEffect(() => {
    if (valueParent?.length > 0) {
      const names = data?.reduce((arrName: string[], item: IDataDefault) => {
        if (valueParent?.includes(item?.id)) return [...arrName, item?.name];
        return arrName;
      }, []);

      setPickedId(valueParent);
      setTagRender(names || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueParent]);

  const handleValidateCategories = (name: string) => {
    const categories = form?.getFieldValue(name) || [];

    if (categories?.length <= 0 && required) {
      form?.setFields([{ name: 'category_id', errors: [t('createPost.category.required')] }]);
    }
  };

  const handlePickerParent = (_data: IDataDefault) => {
    let _values: number[] = [];
    if (props?.name) _values = form?.getFieldValue(props.name) || [];

    if (pickedId?.includes(_data?.id)) {
      const childrenId = maxChildrenId?.reduce((arrId: number[], item: string) => {
        const findDad: number = Number(item?.split('-')[0]);
        if (findDad === _data?.id) return [...arrId, Number(item?.split('-')[1])];
        return arrId;
      }, []);
      const childrens = maxChildrenId?.filter((item: string) => {
        return Number(item?.split('-')[0]) === _data?.id;
      });

      setPickedId(pickedId.filter((id: number) => id !== _data?.id));
      setMaxChildrenId(maxChildrenId.filter((id: string) => !childrens.includes(id)));

      if (props.name) {
        form?.setFieldsValue({
          [props.name || '']: _values.filter((_item: any) => ![...childrenId, _data?.id].includes(_item?.id)),
        });

        handleValidateCategories(props.name);
      }

      setTagRender(tagRender.filter((name: string) => name !== _data?.name));
      return;
    }
    if ((pickedId?.length || 0) < (maxParent || 999999)) {
      setPickedId([...pickedId, _data?.id]);
      if (props.name) form?.setFieldsValue({ [props?.name]: [..._values, _data] });
      setTagRender([...tagRender, _data.name]);
    }
  };

  return (
    <TreeSelectStyle
      dropdownStyle={props?.dropdownStyle}
      className={props.className || ''}
      width={props.width}
      height={props.height}
      placeholder={props.placeHolder || ''}
      value={tagRender.length > 0 ? tagRender.join(',') : null}
      dropdownRender={() => {
        return (
          <>
            {data?.map((item: any) => {
              return (
                <div key={item?.id}>
                  <ListItem
                    name={props.name}
                    form={form}
                    onClick={handlePickerParent}
                    maxChild={maxChild}
                    onSetMaxChildId={setMaxChildrenId}
                    maxChildId={maxChildrenId}
                    preventClick={!pickedId.includes(item?.id) && pickedId.length >= (maxParent || 999999)}
                    data={item}
                    parentId={pickedId}
                    childProperty={props.childProperty}
                  ></ListItem>
                </div>
              );
            })}
          </>
        );
      }}
    ></TreeSelectStyle>
  );
};

interface IListItem {
  data: IDataDefault;
  preventClick?: boolean;
  onSetMaxChildId: (value: string[]) => void;
  maxChildId: string[];
  maxChild?: number;
  onClick?: (value: any) => void;
  form?: FormInstance<any>;
  name?: string;
  parentId?: number[];
  required?: boolean;
  childProperty?: IChidProperty;
}
export const ListItem: React.FC<IListItem> = ({ data, preventClick = false, form, required = true, ...props }) => {
  const [showChilds, setShowChilds] = useState<boolean>(props?.parentId?.includes(data?.id) || false);

  const handleShowChild = () => {
    setShowChilds(!showChilds);
  };

  const { t } = useTranslation();

  const handlePickerParent = () => {
    if (preventClick) return;
    if (props.onClick) props.onClick(data);
    handleShowChild();
  };

  const handleValidateCategories = (name: string) => {
    const categories = form?.getFieldValue(name) || [];

    if (categories?.length <= 0 && required) {
      form?.setFields([{ name: 'category_id', errors: [t('createPost.category.required')] }]);
    }
  };

  const handleChooseItem = (item: IDataDefault) => {
    let _values: number[] = [];
    if (props?.name) _values = form?.getFieldValue(props.name) || [];

    if (props.maxChildId?.includes(data.id + '-' + item.id)) {
      props.onSetMaxChildId(props.maxChildId.filter((id: string) => id !== data.id + '-' + item.id));
      if (props.name)
        form?.setFieldsValue({
          [props.name || '']: _values.filter((_item: any) => _item?.id !== item?.id),
        });

      handleValidateCategories(props.name || '');

      return;
    }
    if ((props.maxChildId?.length || 0) < (props.maxChild || 999999)) {
      props.onSetMaxChildId([...props.maxChildId, data.id + '-' + item.id]);
      if (props.name) form?.setFieldsValue({ [props?.name]: [..._values, item] });
    }

    if (props?.childProperty?.onPickChild) {
      props.childProperty.onPickChild(item);
    }
  };

  return (
    <ListItemStyle>
      <ParentListItemStyle onClick={handlePickerParent}>
        <OptionItem
          isActive={props?.parentId?.includes(data?.id)}
          preventClick={preventClick}
          isParent={(data?.children?.length || 0) > 0}
          text={data?.name}
        />
      </ParentListItemStyle>

      {showChilds && (
        <ChildsListItemStyle>
          {data?.children?.map((item: IDataDefault) => {
            return (
              <div onClick={() => handleChooseItem(item)} key={item?.id}>
                <OptionItem
                  isActive={props.maxChildId?.includes(data?.id + '-' + item?.id)}
                  preventClick={
                    !props.maxChildId.includes(data.id + '-' + item.id) &&
                    props.maxChildId.length >= (props.maxChild || 999999)
                  }
                  isParent={false}
                  text={item?.name}
                />
              </div>
            );
          })}
        </ChildsListItemStyle>
      )}
    </ListItemStyle>
  );
};

interface IOptionItem {
  isParent?: boolean;
  text?: string;
  preventClick?: boolean;
  isActive?: boolean;
}
export const OptionItem: React.FC<IOptionItem> = ({
  isParent = true,
  text,
  preventClick = false,
  isActive = false,
}) => {
  const [active, setActive] = useState<boolean>(isActive);

  const handleShowChilds = () => {
    setActive(!active);
  };
  return (
    <OptionItemStyle
      active={active}
      onClick={() => !preventClick && handleShowChilds()}
      className={active ? 'active' : ''}
    >
      {active && <CheckOutlined className="check-icon" />}
      <div className="content">
        <span className="title">{text}</span>
        {isParent && <Image preview={false} src={images.group.CaretUp} />}
      </div>
    </OptionItemStyle>
  );
};

export default TreeSelectCustom;
