import React from 'react';
import i18n from 'helper/i18n';
import { message } from 'antd';
import { ServerErrorCode } from 'api/networkConstant';

type MyProps = {};
type MyState = {};
class NetworkError extends React.Component<MyProps, MyState> {
  static instance: NetworkError | null = null;
  constructor(props: MyProps) {
    super(props);
    NetworkError.instance = this;
  }

  show(errorCode: number, response: any): void {
    switch (Number(errorCode)) {
      case ServerErrorCode.BAD_REQUEST:
        message.info(response.data?.message || i18n.t('error.badRequest'));
        break;
      case ServerErrorCode.BAD_GATEWAY:
        message.info(response.data?.message || i18n.t('error.badGateway'));
        break;
      case ServerErrorCode.FORBIDDEN:
        message.info(response.data?.message || i18n.t('error.forbidden'));
        break;
      case ServerErrorCode.INTERNAL_SERVER_ERROR:
        message.info(response.data?.message || i18n.t('error.internalServerError'));
        break;
      case ServerErrorCode.NOT_FOUND:
        message.info(response.data?.message || i18n.t('error.notfound'));
        break;
      case ServerErrorCode.TIMEOUT:
        message.info(response.data?.message || i18n.t('error.timeout'));
        break;
      case ServerErrorCode.WRONG_DATA_REQUEST:
        message.info(i18n.t('error.wrongDataRequest'));
        break;
      default:
        message.info(response.data?.message || i18n.t('error.errorSystem'));
        break;
    }
  }

  render() {
    return null;
  }
}

export default NetworkError;
