import { useNavigate } from 'react-router-dom';
import SessionStorageUtil, { COMMON_KEY } from './SessionStorageUtil';

const myInfo = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {});
enum ETypeData {
  GROUP = 'group',
  DASHBOARD = 'dashboard',
}
const useNavigateFunction = () => {
  const navigate = useNavigate();

  const navigateToProfile = (userId: number) => {
    if (myInfo?.user_id === userId) {
      navigate(`/home/my-page/${myInfo?.user_id}`);
    } else {
      navigate(`/home/profile/${userId}`);
    }
  };

  const navigateToPostDetail = (postId: number, groupId?: number) => {
    if (!groupId) {
      navigate(`/home/detail-post/${postId}`);
    } else {
      navigate(`/group/detail-group/${groupId}/${postId}`);
    }
  };
  const linkToPostDetail = (postId: number, groupId?: number) => {
    if (!groupId) {
      return `/home/detail-post/${postId}`;
    } else {
      return `/group/detail-group/${groupId}/${postId}`;
    }
  };

  const navigateToGroup = (adminGroupId?: number, groupId?: number) => {
    if (adminGroupId) {
      if (Number(myInfo?.user_id) === adminGroupId) {
        navigate(`/group/user-manage-group/${groupId}`);
        return;
      }
      navigate(`/group/group-joining/${groupId}`);
    }
    return navigate(`/group/classification/${groupId}`);
  };

  const navigateToHotTag = (idTag: number, groupId?: number) => {
    return navigate(`/home/hot-tag/${idTag}?groupId=${groupId}`);
  };

  const navigateToHotCategory = (
    idCategory: number,
    groupId?: number,
    type?: 'group' | 'dashboard' | null | ETypeData
  ) => {
    return navigate(`/home/hot-category/${idCategory}?groupId=${groupId}&&type=${type}`);
  };

  return {
    navigateToProfile,
    navigateToPostDetail,
    navigateToGroup,
    navigateToHotTag,
    navigateToHotCategory,
    linkToPostDetail,
  };
};

export default useNavigateFunction;
