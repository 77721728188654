import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React from 'react';
import ButtonIconStyle, { AvatarStyle, ButtonParentStyle, TextStyle } from './buttonIconStyle';

interface IIconProp {
  src?: any;
  size?: number;
  disabledIcon?: string;
  shape?: 'circle' | 'square';
}

interface ITextStyle {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
}
interface ITextProp {
  text?: string;
  style?: ITextStyle;
}

interface IButtonStyle {
  borderRadius?: number | string;
  backGroundColor?: string;
  width?: string | number;
  height?: number | string;
  color?: string;
  padding?: number[];
  margin?: string;
  border?: string;
  flex?: number;
}
interface IButtonProp {
  style?: IButtonStyle;
  type?: string;
}
interface Props {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  showIcon?: boolean;
  sizeButton?: SizeType;
  loading?: boolean;
  preventClick?: boolean;
  hideText?: boolean;
  iconProperty?: IIconProp;
  textProperty?: ITextProp;
  buttonProperty?: IButtonProp;
}
const ButtonIconCustom = ({
  onClick,
  disabled = false,
  className,
  showIcon = true,
  sizeButton = 'large',
  loading = false,
  preventClick = false,
  iconProperty,
  textProperty,
  buttonProperty,
  ...props
}: Props) => {
  return (
    <ButtonIconStyle className={className || ''} {...buttonProperty?.style} showIcon={showIcon} isLoading={loading}>
      <ButtonParentStyle
        size={sizeButton}
        disabled={disabled}
        type
        {...buttonProperty?.style}
        onClick={() => {
          if (!loading && onClick && !preventClick) onClick();
        }}
        loading={loading}
      >
        {showIcon && (
          <AvatarStyle
            isLoading={loading}
            shape={iconProperty?.shape}
            size={iconProperty?.size}
            src={disabled ? iconProperty?.disabledIcon || iconProperty?.src : iconProperty?.src}
          />
        )}
        {!props.hideText && (
          <TextStyle
            className="button-icon-custom-text"
            {...textProperty?.style}
            disabled={disabled}
            showIcon={showIcon}
            isLoading={loading}
          >
            {textProperty?.text}
          </TextStyle>
        )}
      </ButtonParentStyle>
    </ButtonIconStyle>
  );
};

export default ButtonIconCustom;
