import { colors } from 'common/styled-base';
import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
}

export const ButtonSaveWrapper = styled.button<Props>`
  min-width: ${(props) => (props?.width ? `${props?.width}px` : '100%')};
  height: ${(props) => (props?.height ? `${props?.height}px` : '')};
  cursor: pointer;
  font-weight: 500;
  background-color: ${(props) => (!props?.disabled ? colors.mainColor100 : colors.gray2)};
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  img {
    margin-right: 10px;
  }
  span {
    font-size: 14px;
  }
  .loading {
    width: 30px;
    height: 30px;
  }
`;
