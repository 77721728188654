import images from 'assets/images-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDateFilter } from '../FilterContent';
import { Title } from '../filterContentStyle';
import { DatePickerOneHome, FilterTimeWrapper } from './timeFilterStyle';

const TimeFilter = ({
  dateFilter,
  setDateFilter,
}: {
  dateFilter: IDateFilter;
  setDateFilter: (value: IDateFilter) => void;
}) => {
  const { t } = useTranslation();
  return (
    <FilterTimeWrapper>
      <Title>{t('home.timeLine')}</Title>
      <DatePickerOneHome
        placeholder={t('home.startDate')}
        className="picker"
        value={dateFilter?.start_date}
        suffixIcon={images.search.calendarIcon}
        onChange={(date: any) => setDateFilter({ ...dateFilter, start_date: date })}
        format={'DD/MM/YYYY'}
      />
      <DatePickerOneHome
        placeholder={t('home.endDate')}
        className="picker"
        value={dateFilter?.end_date}
        suffixIcon={images.search.calendarIcon}
        onChange={(date: any) => setDateFilter({ ...dateFilter, end_date: date })}
        format={'DD/MM/YYYY'}
      />
    </FilterTimeWrapper>
  );
};

export default TimeFilter;
