import styled from 'styled-components';

interface ChatBotProps {
  readonly suggestions?: any;
  readonly isOpen?: boolean;
}

export const TextLoading = styled.img`
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

export const ChatBotWrapper = styled.div<ChatBotProps>`
  z-index: 2;
  position: relative;
  font-family: 'Roboto', sans-serif !important;

  .sc-launcher {
    bottom: 10px;
    right: 10px;
  }

  .sc-launcher .sc-open-icon,
  .sc-launcher .sc-closed-icon {
    bottom: 10px;
    right: 10px;
  }

  .sc-header {
    padding: 0;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(254, 166, 40, 1) 0%, rgba(246, 196, 27, 1) 100%);
    min-height: 0px;
  }
  .sc-header--close-button {
    background-color: transparent;
  }

  .sc-message--text {
    padding: 6px 10px;
    font-weight: normal;
    width: fit-content !important;
    border-radius: 20px;
  }

  .sc-message--content.sent .sc-message--text {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(254, 166, 40, 1) 0%, rgba(246, 196, 27, 1) 100%);
  }

  .sc-header--team-name {
    font-weight: 700;
    font-size: 20px;
  }

  .sc-chat-window {
    font-family: inherit;
    bottom: 80px;
  }

  .sc-message {
    width: 350px;
  }

  .sc-message-list {
    padding: 10px 0;
  }

  .sc-message--avatar {
    background-image: url('/logo.svg') !important;
    background-size: contain;
  }
  .sc-message:last-child {
    margin-bottom: ${(props) => (props.suggestions ? `calc(${props.suggestions} * 32px)` : '0px')};
  }

  .sc-user-input--file-icon-wrapper {
    display: none;
  }

  .detail {
    font-weight: bold;
  }

  .sc-message-list,
  .sc-user-input {
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /* -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5); */
      background-color: #ccc;
      border: none;
      outline: none;
    }
  }

  .sc-user-input--button {
    align-self: center;
  }

  div[role='button'] {
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    } */

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /* -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5); */
      background-color: #ccc;
      border: none;
      outline: none;
    }
  }
`;

export const SuggestionWrapper = styled.div<ChatBotProps>`
  position: fixed;
  bottom: 140px;
  right: 35px;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  font-size: 14px;
  gap: 4px;

  .tag-suggestion {
    padding: 4px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    border-radius: 28px;
    cursor: pointer;
    background-color: white;
  }
`;
