import { Tabs } from 'antd';
import TabCustomStyle, { TabColNameStyle, TabRowStyle } from './tabCustomStyle';
import React from 'react';

interface ITab {
  name: string | React.ReactNode;
  content?: any;
  count?: number;
  offset?: any;
  countNode?: React.ReactNode;
  key?: string;
}

interface IStyle {
  height?: string;
}
interface Props {
  tabs?: ITab[];
  defaultActiveKey?: string | undefined;
  onChange?: (value: string) => void;
  className?: string;
  activeKey?: string;
  style?: IStyle;
}
const { TabPane } = Tabs;

const TabCustom: React.FunctionComponent<Props> = ({
  tabs,
  defaultActiveKey = '0',
  onChange,
  className,
  activeKey,
  ...props
}) => {
  const renderCountUi = (count?: number, name?: string | React.ReactNode, countNode?: React.ReactNode) => {
    return (
      <TabRowStyle>
        <TabColNameStyle>{name}</TabColNameStyle>
        {(count || count === 0) && (countNode ? countNode : <span>{`(${count})`}</span>)}
      </TabRowStyle>
    );
  };
  if (activeKey) {
    return (
      <TabCustomStyle className={className} style={props?.style}>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          onChange={(value: string) => {
            if (onChange) onChange(value);
          }}
          activeKey={activeKey}
        >
          {tabs?.map((item: ITab, index: number) => (
            <TabPane tab={renderCountUi(item?.count, item?.name, item?.countNode)} key={item?.key || index}>
              {item?.content}
            </TabPane>
          ))}
        </Tabs>
      </TabCustomStyle>
    );
  }
  return (
    <TabCustomStyle className={className} style={props?.style}>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        onChange={(value: string) => {
          if (onChange) onChange(value);
        }}
      >
        {tabs?.map((item: ITab, index: number) => (
          <TabPane tab={renderCountUi(item?.count, item?.name, item?.countNode)} key={index}>
            {item?.content}
          </TabPane>
        ))}
      </Tabs>
    </TabCustomStyle>
  );
};

export default TabCustom;
