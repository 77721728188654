import { colors } from 'common/styled-base';
import styled from 'styled-components';

export const FilterMobileWrapper = styled.div`
  width: 100%;
`;

interface ICateGoryButton {
  borderYellow: boolean;
}
export const CategoryButton = styled.div<ICateGoryButton>`
  width: 100%;
  border: 1px solid ${(props) => (props?.borderYellow ? colors.mainColor2 : '#c4c4c4')};
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  margin-bottom: 25px;
`;
export const Nodata = styled.div`
  width: 100%;
  color: #c4c4c4;
`;
export const CategoryWrapper = styled.div`
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
export const ContainerModal = styled.div`
  height: calc(90vh - 50px);
  padding: 0 20px;
  padding-bottom: 10px;
  width: 100%;
  overflow-y: scroll;
`;
