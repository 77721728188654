import { colors } from 'common/styled-base';
import styled from 'styled-components';

const HeaderMobileStyle = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  top: 0;
  margin-top: 55px;
  padding-left: 21px;
`;

export const TabMenuStyle = styled.div`
  margin-top: 30px;
  .ant-tabs-content-holder {
    display: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fea628;
    font-weight: 500;
    font-size: 16px;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: #fea628;
    pointer-events: none;
  }
  .ant-tabs-tab {
    font-size: 1.15em;
    color: ${colors.textCaption};
    height: 57px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const AvatarStyle = styled.div`
  margin-top: 50px;
  .text-avatar {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #223354;
  }
`;

export const LogoutStyle = styled.div`
  margin-top: 15px;
  padding: 15px 0;
  .text-avatar {
    color: #909090;
    font-weight: 400;
    font-size: 16px;
  }
`;

export default HeaderMobileStyle;
