import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider, Button } from 'antd';
import Cropper from 'react-easy-crop';
import { Wrapper, PreviewFrame, SliderWrapper, ButtonZoom, Line, Footer, Blank } from './cropImageHeicStyle';
import getCroppedImg from './cropImage';

interface Props {
  minZoom: number;
  maxZoom: number;
  aspect: number;
  quality: number;
  urlHeic: string;
  beforeUpload: any;
  onCancel: any;
}

const PostRanking: React.FC<Props> = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(props.maxZoom >= 1 && props.minZoom <= 1 ? 1 : (props.maxZoom + props.minZoom) / 2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageResult, setCroppedImageResult] = useState('');

  const { t } = useTranslation();

  const croppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(props.urlHeic, croppedAreaPixels, 0);
      setCroppedImageResult(URL.createObjectURL(croppedImage));
      const fileOfBlob = new File([croppedImage], 'cropImage.jpeg');
      props.beforeUpload(fileOfBlob);
      props.onCancel(); // only for close
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const zoomUp = () => {
    if (zoom <= props.maxZoom - 0.1) {
      setZoom(zoom + 0.1);
    }
  };

  const zoomDown = () => {
    if (zoom >= props.minZoom + 0.1) {
      setZoom(zoom - 0.1);
    }
  };

  return (
    <Wrapper id="Cropper">
      <PreviewFrame>
        <Cropper
          image={props.urlHeic}
          crop={crop}
          rotation={0}
          zoom={zoom}
          aspect={props.aspect}
          onCropChange={setCrop}
          // onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </PreviewFrame>
      <SliderWrapper>
        <ButtonZoom onClick={zoomDown}>-</ButtonZoom>
        <Slider
          value={zoom}
          min={props.minZoom}
          max={props.maxZoom}
          step={0.05}
          onChange={(value: any) => {
            setZoom(value);
          }}
        />
        <ButtonZoom onClick={zoomUp}>+</ButtonZoom>
      </SliderWrapper>
      <Line />
      <Footer>
        <Button onClick={props.onCancel}>{t('Cancel')}</Button>
        <Blank width={1} />
        <Button type="primary" onClick={croppedImage}>
          {t('OK')}
        </Button>
      </Footer>

      <img src={croppedImageResult} alt="result" />
    </Wrapper>
  );
};

export default PostRanking;
