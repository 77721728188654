import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Navigate } from 'react-router-dom';

import { getToken } from '../../helper/storage';

const RenderPrivateComp = ({ children }: { children: any }) => {
  const hasToken = getToken();
  return hasToken ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, Layout, ...rest }: { component: any; Layout: any }) => {
  return (
    <Route
      {...rest}
      element={
        <RenderPrivateComp>
          <Layout>
            <Suspense fallback={<div></div>}>
              <Component />
            </Suspense>
          </Layout>
        </RenderPrivateComp>
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default PrivateRoute;
