import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NewsFeedRoute, ManageRoute, RouterItemInterface, HomeItem, GroupRoute } from 'wrappers/AuthWrapper';
import CustomRoute from 'wrappers/AuthWrapper/CustomRouter';
import PrivateRoute from 'wrappers/AuthWrapper/privateRouter';
import { DefaultLayout } from 'components/layout/index';
import EmptyLayout from 'components/layout/EmptyLayout';
import Admin from 'pages/Admin';
import ParentComponent from './ParentComponent';
import NotFound from 'pages/NotFound';
import Login from 'pages/Login';
import HomeParent from 'pages/Home/HomeParent';
import NetworkError from 'helper/NetworkError';
import GroupParent from 'pages/Group/GroupParent';
import { isMobile } from 'react-device-detect';
import { useMutation, useQueryClient } from 'react-query';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import { BackToTopStyle } from 'pages/Home/styled';
import images from 'assets/images-base';
import { fetchUserInfo } from 'api/axios';
import { storeSetIsAdminSystem, storeSetMyInfo, storeSetPermission } from 'store/auth-reducer';
import { useDispatch } from 'react-redux';
import RolePermission, { Permission, Role } from 'helper/permissions/RolePermission';

const homePage = (
  <ParentComponent parentPath="home">
    <HomeParent />
  </ParentComponent>
);

const groupPage = (
  <ParentComponent parentPath="group">
    <GroupParent />
  </ParentComponent>
);

const adminPage = (
  <ParentComponent parentPath="admin">
    <Admin />
  </ParentComponent>
);

(window as any).OneSignal = (window as any)?.OneSignal || [];
// prettier-ignore
const OneSignal = (window as any)?.OneSignal;
// prettier-ignore
OneSignal.push(() => {
  console.log('--- init OneSignal');
  OneSignal.init({
    appId: process.env.REACT_APP_DOMAIN_APP_API,
    safari_web_id: process.env.REACT_APP_DOMAIN_SAFARI_WEB_ID,
    notifyButton: {
      enable: false,
    },
    promptOptions: {
      /* Change bold title, limited to 30 characters */
      siteName: 'OneSignal Documentation',
      /* Subtitle, limited to 90 characters */
      actionMessage: "We'd like to show you notifications for the latest news and updates.",
      /* Example notification title */
      exampleNotificationTitle: 'Example notification',
      /* Example notification message */
      exampleNotificationMessage: 'This is an example notification',
      /* Text below example notification, limited to 50 characters */
      exampleNotificationCaption: 'You can unsubscribe anytime',
      /* Accept button text, limited to 15 characters */
      acceptButtonText: 'ALLOW',
      /* Cancel button text, limited to 15 characters */
      cancelButtonText: 'NO THANKS',
      autoAcceptTitle: 'Click Allow',
    },
  });
});

export default function AppWrapper() {
  const dispatch = useDispatch();

  const createRoute = (item: RouterItemInterface, index: number) => {
    const Component: any = isMobile ? item.mobile || item.desktop : item.desktop;
    const Layout: any = item.layout || DefaultLayout;
    if (item.private) {
      return <React.Fragment key={index}>{PrivateRoute({ component: Component, Layout, ...item })}</React.Fragment>;
    } else {
      return <React.Fragment key={index}>{CustomRoute({ component: Component, Layout, ...item })}</React.Fragment>;
    }
  };

  const QueryClient = useQueryClient();

  const userId = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.user_id;

  React.useEffect(() => {
    if (userId) {
      // OneSignal.push(() => {
      //   OneSignal.init({
      //     appId: process.env.REACT_APP_DOMAIN_APP_API,
      //     safari_web_id: process.env.REACT_APP_DOMAIN_SAFARI_WEB_ID,
      //     notifyButton: {
      //       enable: true,
      //     },
      //     promptOptions: {
      //       /* Change bold title, limited to 30 characters */
      //       siteName: 'OneSignal Documentation',
      //       /* Subtitle, limited to 90 characters */
      //       actionMessage: "We'd like to show you notifications for the latest news and updates.",
      //       /* Example notification title */
      //       exampleNotificationTitle: 'Example notification',
      //       /* Example notification message */
      //       exampleNotificationMessage: 'This is an example notification',
      //       /* Text below example notification, limited to 50 characters */
      //       exampleNotificationCaption: 'You can unsubscribe anytime',
      //       /* Accept button text, limited to 15 characters */
      //       acceptButtonText: 'ALLOW',
      //       /* Cancel button text, limited to 15 characters */
      //       cancelButtonText: 'NO THANKS',
      //       autoAcceptTitle: 'Click Allow',
      //     },
      //   });
      // });
      OneSignal.push(() => {
        OneSignal.isPushNotificationsEnabled((isEnabled: any) => {
          if (isEnabled) console.log('Push notifications are already enabled!');
          else console.log('Push notifications are not enabled yet.');
          OneSignal.showHttpPrompt();
        });
      });
      OneSignal.push(() => {
        OneSignal.setExternalUserId(String(userId));
      });
      OneSignal.push(() => {
        OneSignal.getExternalUserId().then();
      });
      OneSignal.push(() => {
        OneSignal.on('notificationDisplay', (event: any) => {
          console.log('success******');
          QueryClient.refetchQueries(['get_list_system']);
          QueryClient.refetchQueries(['get_list_notification']);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const createRouteChild = (item: RouterItemInterface, index: number) => {
    const Component: any = isMobile ? item.mobile || item.desktop : item.desktop;
    const Layout: any = item.layout || EmptyLayout;
    if (item.private) {
      return <React.Fragment key={index}>{PrivateRoute({ component: Component, Layout, ...item })}</React.Fragment>;
    } else {
      return <React.Fragment key={index}>{CustomRoute({ component: Component, Layout, ...item })}</React.Fragment>;
    }
  };

  const handleScrollTop = () => {
    document.body.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  useEffect(() => {
    fetchUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: fetchUserInformation } = useMutation(async () => fetchUserInfo(), {
    onSuccess: async (res: any) => {
      SessionStorageUtil.setItemObject(COMMON_KEY?.userInfo, res?.data);

      dispatch(storeSetMyInfo(res?.data));
      RolePermission.createInstance({
        role: res?.data?.role,
        permission: res?.data?.hr_permission,
      });
      handleCheckPermission();
      dispatch(storeSetMyInfo(res?.data));
    },
    onError: (_res: any) => {
      console.log('onError res = ', _res);
    },
  });

  const handleCheckPermission = () => {
    const checkPermission =
      RolePermission.checkPermission(Role.Dashboard, Permission.View) ||
      RolePermission.checkPermission(Role.ManagerGroup, Permission.View) ||
      RolePermission.checkPermission(Role.MasterDataRole, Permission.View) ||
      RolePermission.checkPermission(Role.MasterDataCategory, Permission.View) ||
      RolePermission.checkPermission(Role.MasterDataTopic, Permission.View) ||
      RolePermission.checkPermission(Role.MasterDataTemplate, Permission.View) ||
      RolePermission.checkPermission(Role.ListPosts, Permission.View) ||
      RolePermission.checkPermission(Role.ListPostsReport, Permission.View);
    dispatch(storeSetIsAdminSystem(checkPermission));

    const permission = {
      dashboard: RolePermission.checkPermission(Role.Dashboard, Permission.View),
      group: [
        RolePermission.checkPermission(Role.ManagerGroup, Permission.View),
        RolePermission.checkPermission(Role.ManagerGroup, Permission.Edit),
      ],
      role: RolePermission.checkPermission(Role.MasterDataRole, Permission.View),
      master:
        RolePermission.checkPermission(Role.MasterDataCategory, Permission.View) ||
        RolePermission.checkPermission(Role.MasterDataTopic, Permission.View) ||
        RolePermission.checkPermission(Role.MasterDataTemplate, Permission.View) ||
        RolePermission.checkPermission(Role.MasterDataBanner, Permission.View),
      post: RolePermission.checkPermission(Role.ListPosts, Permission.View),
      // || RolePermission.checkPermission(Role.ListPostsReport, Permission.View),
    };
    dispatch(storeSetPermission(permission));
  };

  return (
    <div className="root-wrapper">
      <NetworkError />
      <Routes>
        {createRoute(HomeItem, 0)}
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={homePage}>
          {NewsFeedRoute.map(createRouteChild)}
        </Route>
        <Route path="/group" element={groupPage}>
          {GroupRoute.map(createRouteChild)}
        </Route>
        <Route path="/admin" element={adminPage}>
          {ManageRoute.map(createRouteChild)}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <BackToTopStyle>
        {visible && <img onClick={handleScrollTop} src={images.group.iconScrollUp} alt="scroll" />}
      </BackToTopStyle>
    </div>
  );
}
