import { sendGet } from 'api/axios';
import { useQuery } from 'react-query';

export interface IUsersParams {
  list_id?: number[];
  key_word?: string;
  list_except?: any;
}

const useGetListUserByIds = (params?: IUsersParams, isFetch?: boolean) =>
  useQuery(
    ['get_list_user_by_ids', params],
    async () => {
      const res = await sendGet('/user/list', { ...params, inactive: 0 });
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 0,
      enabled: isFetch,
    }
  );

export default useGetListUserByIds;
