import { colors } from 'common/styled-base';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const NotificationWrapper = styled.div`
  width: ${isMobile ? '90vw' : '745px'};
  color: ${colors.second1};
`;
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
`;
