import styled from 'styled-components';

interface Props {
  height?: string | number;
  disabled?: boolean;
}
const EditorWrapper = styled.div<Props>`
  white-space: pre-line;
  .editor-container {
    textarea {
      height: ${(props) =>
        typeof props?.height === 'number'
          ? `${props?.height || 500}px !important`
          : props?.height || '500px !important'};
      cursor: ${(props) => (props?.disabled ? 'not-allowed' : 'unset')};
    }
  }
  .section-container {
    height: ${(props) =>
      typeof props?.height === 'number' ? `${props?.height || 500}px !important` : props?.height || '500px !important'};
  }
  word-break: break-word;
  h1 {
    font-size: 32px;
    color: #223354;
    font-weight: 500;
  }
  h2 {
    font-size: 24px;
    color: #223354;
    font-weight: 500;
  }
  h3 {
    font-size: 18px;
    color: #223354;
    font-weight: 500;
  }
  h4 {
    font-size: 16px;
    color: #223354;
    font-weight: 500;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
    line-height: normal !important;
  }
  p {
    margin: 0;
    line-height: normal !important;
  }
  a {
    color: #2c8ce4 !important;
  }
  .custom-html-style {
    padding-top: 10px;
    p {
      margin: 0;
    }
    img {
      margin: auto;
      display: block;
      width: auto;
      height: auto;
    }
  }
  h3 {
    display: block;
    font-size: 18px !important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
  }
  span {
    font-size: 1em !important;
    color: inherit;
  }
  ul,
  ol {
    white-space: normal;
  }
  li {
    font-size: 16px;
  }
`;

export default EditorWrapper;

export const Div = styled.div`
  .icon {
    width: 15px;
    height: 15px;
  }
`;
