import React, { useEffect, useState } from 'react';
import HeaderMobileStyle, { AvatarStyle, LogoutStyle, TabMenuStyle } from './headerMobileStyle';
import AvatarText from 'components/AvatarText';
import images from 'assets/images-base';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { getPathIcon } from 'helper/image';
import { useLocation, useNavigate } from 'react-router-dom';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import RolePermission, { Permission, Role } from 'helper/permissions/RolePermission';
import useWindowSize from 'hooks/useWindow';

let interval: any = null;

interface Props {
  userData?: any;
  onCloseMenu?: (value: boolean) => void;
  checkLogOut: () => void;
}
const { TabPane } = Tabs;
const HeaderMobile: React.FC<Props> = ({ userData, onCloseMenu, checkLogOut }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isMobile } = useWindowSize();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState<string>('');
  const [isShowAdminTab, setIsShowAdminTab] = useState(false);

  useEffect(() => {
    interval = setInterval(() => {
      if (RolePermission.current !== null) {
        const checkPermission =
          RolePermission.checkPermission(Role.Dashboard, Permission.View) ||
          RolePermission.checkPermission(Role.ManagerGroup, Permission.View) ||
          RolePermission.checkPermission(Role.MasterDataRole, Permission.View) ||
          RolePermission.checkPermission(Role.MasterDataCategory, Permission.View) ||
          RolePermission.checkPermission(Role.MasterDataTopic, Permission.View) ||
          RolePermission.checkPermission(Role.MasterDataTemplate, Permission.View) ||
          RolePermission.checkPermission(Role.ListPosts, Permission.View) ||
          RolePermission.checkPermission(Role.ListPostsReport, Permission.View);
        setIsShowAdminTab(checkPermission);
        clearInterval(interval);
      }
    }, 100);
  }, []);

  const handleFindTab = () => {
    const isGroup = location?.pathname.includes('/group');
    const isAdmin = location?.pathname.includes('/admin');
    if (isGroup) return '/group';
    else if (isAdmin) return '/admin';
    return '/';
  };

  useEffect(() => {
    setActiveKey(handleFindTab());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = (url: string) => {
    navigate(url);
    if (onCloseMenu) onCloseMenu(false);
  };

  return (
    <HeaderMobileStyle>
      <AvatarStyle
        onClick={() => {
          navigate(`/home/my-page/${SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {}).user_id}`);
          if (onCloseMenu) onCloseMenu(false);
        }}
      >
        <AvatarText
          textProperty={{ content: userData?.name }}
          avatarProperty={{ size: 36, src: userData?.avatar || images.profile.avatarDefault }}
        />
      </AvatarStyle>

      <TabMenuStyle>
        <Tabs
          tabPosition="right"
          // onChange={(value: string) => {
          //   navigate(value);
          //   if (onCloseMenu) onCloseMenu(false);
          // }}
          activeKey={activeKey}
        >
          <TabPane tab={<span onClick={() => handleNavigate('/')}>{t('header.newsfeed')}</span>} key="/"></TabPane>
          <TabPane
            tab={<span onClick={() => handleNavigate('/group')}>{t('header.group')}</span>}
            key="/group"
          ></TabPane>
          {isShowAdminTab && !isMobile ? <TabPane tab={t('header.manage')} key="/admin"></TabPane> : null}
        </Tabs>
      </TabMenuStyle>

      <LogoutStyle
        onClick={() => {
          checkLogOut();
        }}
      >
        <AvatarText
          avatarProperty={{ src: getPathIcon('logout.svg') }}
          textProperty={{ content: t('header.mobile.logout') }}
        />
      </LogoutStyle>
    </HeaderMobileStyle>
  );
};

export default HeaderMobile;
