import { ESearchDetail, ESystemId } from 'common';
import { images } from 'assets/images-base';
import configs from 'config';

const TYPE_LIST = [ESearchDetail.USER, ESearchDetail.GROUP, ESearchDetail.POST, ESearchDetail.SERIES];
const TYPE_GROUP = [ESearchDetail.USER, ESearchDetail.POST, ESearchDetail.SERIES];
const RANGE_LIST = [ESearchDetail.FOLLOWING, ESearchDetail.NOT_FOLLOWING];
const RANGE_LIST_GROUP = [ESearchDetail.FOLLOWING, ESearchDetail.NOT_FOLLOWING];
const TOPIC_LIST = [ESearchDetail.SHARE, ESearchDetail.QUESTION, ESearchDetail.SERIES];

const SYSTEM_SELECT = {
  [ESystemId.ME]: {
    name: 'me',
    desc: 'descMe',
    avatar: images.notification.acmsMe,
    url: configs.DOMAIN_AMS_ME,
  },
  [ESystemId.BO]: {
    name: 'bo',
    desc: 'descBo',
    avatar: images.notification.acmsBo,
    url: configs.DOMAIN_AMS_BO,
  },
  [ESystemId.WORKFLOW]: {
    name: 'workFlow',
    desc: 'descWorkFlow',
    avatar: images.notification.acmsWorkFlow,
    url: configs.DOMAIN_AMS_WORKFLOW,
  },
  [ESystemId.WIKI]: {
    name: 'wiki',
    desc: 'descWiki',
    avatar: images.notification.acmsWiki,
    url: configs.DOMAIN_AMS_WIKI,
  },
  [ESystemId.RECRUIT]: {
    name: 'recruit',
    desc: 'descRecruit',
    avatar: images.notification.acmsRecruit,
    url: configs.DOMAIN_AMS_RECRUIT,
  },
  [ESystemId.PROJECT]: {
    name: 'project',
    desc: 'descProject',
    avatar: images.notification.acmsProject,
    url: configs.DOMAIN_AMS_PROJECT,
  },
};

const defaultConst = { TYPE_LIST, RANGE_LIST, TOPIC_LIST, TYPE_GROUP, SYSTEM_SELECT, RANGE_LIST_GROUP };
export default defaultConst;
