import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${(props) => (props.isMobile ? '100%' : '83.46%')};
  min-width: ${isMobile ? 'unset' : '1140px'};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${(props) => (props.isMobile ? '20px' : '0')};
  padding-right: ${(props) => (props.isMobile ? '20px' : '0')};
  background: #f0f2f5;
`;

export const ScreenName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  color: #fea628;
  width: fit-content;
  text-align: left;
  font-weight: 500;
  margin-top: ${isMobile ? 23 : 18}px;
`;

export const IconEdit = styled.img`
  width: 36px;
  margin-right: 10px;
`;

export const AlignLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SpaceTop = styled.div`
  margin-top: ${isMobile ? 20 : 30}px;
`;

export const ErrorText = styled.div`
  color: red;
  text-align: left;
  max-width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Column = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: ${(props) => props.flex || 'unset'};
  width: ${(props) => props.width || 'unset'};
  padding-right: ${(props) => props.right || 'unset'};
`;

export const FieldName = styled.div`
  color: #464646;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const RedText = styled.div`
  color: red;
  margin-left: 3px;
`;

export const Blank = styled.div<any>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const FooterBar = styled.div<any>`
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #f3f3f4;

  .button-icon {
    margin-right: ${(props) => (props.hideText ? 'auto' : '7.5px')} !important;
  }

  .button-create {
    width: ${(props) => (props.isMobile ? '65px' : '164px')} !important;
    height: 45px !important;
    background-color: #fea628 !important;
    border-radius: 5px !important;
    .text-button {
      font-size: 18px !important;
      line-height: 21px !important;
    }
    :hover,
    :focus {
      border: none;
    }
  }
  .button-save-draft,
  .button-save-preview {
    background: #fff6e9 !important;
    border: 1px solid #fea628 !important;
    border-radius: 5px !important;
    width: ${(props) => (props.isMobile ? '65px' : '164px')} !important;
    height: 45px !important;
    margin-right: 15px !important;
    .text-button {
      font-size: 18px !important;
      line-height: 21px !important;
      color: #fea628 !important;
    }
  }
`;

export const copyDataToObject = (source: any, dest: any) => {
  if (!source || !dest) return;
  const listKey = Object.keys(source);

  for (const item of listKey) {
    dest[item] = source[item];
  }
};

export const NotifyText = styled.div`
  color: #909090;
  text-align: left;
  max-width: 100%;
  margin-bottom: 10px;
  font-size: 12px;
`;

export const LineCreate = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 1px;
  background: #e0e0e0;
  left: 0;
  /* width: 100vw; */
  margin-top: 217px;
  right: 0;
`;
