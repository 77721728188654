import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { AdminWrapper, CustomTabPane, CustomTabs, Space, NoPermission, GrayWrapper } from './styled';
import { getTabSelected } from 'components/layout/Header';
import { useQueryClient } from 'react-query';
import { EDefaultActiveAdmin } from 'common';
import { useAppSelector } from 'hooks/use-Selector/useAppSelector';
export interface ICheckPermissionTab {
  dashboard: boolean;
  group: boolean[];
  role: boolean;
  master: boolean;
  post: boolean;
}

export enum EIsEditOrView {
  VIEW = 0,
  EDIT = 1,
}

enum EKeyTab {
  DASH_BOARD = '1',
  POST = '2',
  GROUP = '3',
  MASTER = '4',
  PERMISSION = '5',
  NOT_FOUND = '-1',
}

const AdminPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const param: any = useParams();

  const { permissionCheck } = useAppSelector((state: any) => state?.auth);

  const idCategory = param?.idCategory;

  const isHiddenAdminTabs = location.pathname?.includes('/home/hot-category');

  const TAB = [
    {
      key: EKeyTab.DASH_BOARD,
      url: '/admin/dashboard',
      title: t('admin.dashboard'),
      permissions: '',
    },
    {
      key: EKeyTab.POST,
      url: '/admin/posts',
      title: t('admin.managePosts'),
      permissions: '',
    },
    {
      key: EKeyTab.GROUP,
      url: '/admin/group',
      title: t('admin.manageGroup'),
      permissions: '',
    },
    {
      key: EKeyTab.MASTER,
      url: '/admin/master',
      title: t('admin.masterData'),
      permissions: '',
    },
    {
      key: EKeyTab.PERMISSION,
      url: '/admin/decentralization',
      title: t('admin.decentralization'),
      permissions: '',
    },
  ];

  const [isHiddenAll, setIsHiddenAll] = useState(true);
  const queryClient = useQueryClient();

  const suggestTab = (permission: ICheckPermissionTab) => {
    if (isHiddenAdminTabs) {
      navigate(`/home/hot-category/${idCategory}`);
      return;
    }

    if (tabSelected !== EKeyTab.NOT_FOUND) {
      // no suggest
      return;
    }

    if (permission.dashboard) {
      // permission view dashboard
      navigate(TAB[0].url);
      return;
    }

    if (permission.post) {
      // permission view post
      navigate(TAB[1].url);
      return;
    }

    if (permission.group[0]) {
      // permission view group
      navigate(TAB[2].url);
      return;
    }

    if (permission.master) {
      // permission view master data
      navigate(TAB[3].url);
      return;
    }

    if (permission.role) {
      // permission view Permission
      navigate(TAB[4].url);
    }
  };

  useEffect(() => {
    if (permissionCheck !== null) {
      const checkVisibleAdminTab =
        permissionCheck.dashboard ||
        permissionCheck.group ||
        permissionCheck.role ||
        permissionCheck.master ||
        permissionCheck.post;
      setIsHiddenAll(!checkVisibleAdminTab);
      queryClient.setQueryDefaults('checkPermissionTabs', permissionCheck as any);

      suggestTab(permissionCheck);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, permissionCheck]);

  const onChangeTab = (key: string) => {
    const index = parseInt(key) - 1;
    navigate(TAB[index].url);
  };

  const tabSelected = getTabSelected(TAB);

  if (isHiddenAll)
    return (
      <AdminWrapper id="admin">
        <NoPermission>{permissionCheck !== null ? t('common.noPermission') : null}</NoPermission>
      </AdminWrapper>
    );

  return (
    <div className="bg-admin">
      <GrayWrapper>
        <AdminWrapper id="admin" hidden={isHiddenAdminTabs}>
          {permissionCheck && (
            <CustomTabs
              defaultActiveKey={EDefaultActiveAdmin.PAGE}
              activeKey={tabSelected === EKeyTab.NOT_FOUND ? EKeyTab.DASH_BOARD : tabSelected}
              onChange={onChangeTab}
            >
              {permissionCheck?.dashboard && <CustomTabPane tab={t('admin.dashboard')} key={EKeyTab.DASH_BOARD} />}
              {permissionCheck?.post && <CustomTabPane tab={t('admin.managePosts')} key={EKeyTab.POST} />}
              {permissionCheck?.group[EIsEditOrView.VIEW] && (
                <CustomTabPane tab={t('admin.manageGroup')} key={EKeyTab.GROUP} />
              )}
              {permissionCheck?.master && <CustomTabPane tab={t('admin.masterData')} key={EKeyTab.MASTER} />}
              {permissionCheck?.role && <CustomTabPane tab={t('admin.decentralization')} key={EKeyTab.PERMISSION} />}
            </CustomTabs>
          )}

          <Space />
          <Space />
        </AdminWrapper>
      </GrayWrapper>
      <Outlet />
    </div>
  );
};

export default AdminPage;
