import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  users: [],
};

const searchSlice = createSlice({
  name: 'userManageGroup',
  initialState,
  reducers: {
    storeSetUsers: (state: any, { payload }: PayloadAction<any>) => {
      state.users = payload;
    },
  },
});

export const { storeSetUsers } = searchSlice.actions;

export default searchSlice.reducer;
