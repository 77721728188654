import { colors } from 'common/styled-base';
import styled from 'styled-components';

const FilterWrapper = styled.div`
  .title {
    font-size: 18px;
    font-weight: 500;
    color: ${colors.textCaption};
  }
  .checkbox {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      margin-bottom: 16px;
    }
  }
`;

export default FilterWrapper;
