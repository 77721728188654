import { Col, Row } from 'antd';
import images from 'assets/images-base';
import AvatarText from 'components/AvatarText';
import FrameData from 'components/Profile/Component/FrameData/FrameData';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import useGetOtherPublicGroupList, { IParamOtherPublicGroupList } from 'data/useGetOtherPublicGroupList';
import useNavigateFunction from 'helper/navigate';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { AllGroupSystemStyle, FrameGroupItem, FrameGroups } from './allGroupSystemStyle';
import GroupInfoItem from './GroupInfoItem';

interface IListGroupItem {
  id: number;
  name: string;
  avatar_image: string;
  member_quantity: number;
  post_quantity: number;
  created_at: string;
}

const AllGroupSystem = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<IParamOtherPublicGroupList>({ page: 1, per_page: 30 });
  // page 2 nằm trong per+page 30
  const [listGroups, setListGroups] = useState<IListGroupItem[]>([]);
  const { data: groups } = useGetOtherPublicGroupList(params);

  const { navigateToGroup } = useNavigateFunction();

  useEffect(() => {
    if (groups) {
      const isMoreThanOne = (params?.page || 1) > 1;

      if (isMoreThanOne) {
        setListGroups([...listGroups, ...(groups?.other_public_groups || [])]);
        return;
      }
      setListGroups(groups?.other_public_groups || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  const handleSeeMore = () => {
    setParams({ ...params, page: (params?.page || 1) + 1, per_page: 30 });
  };

  return (
    <AllGroupSystemStyle>
      <AvatarText
        textProperty={{
          content: t('group.allGroup'),
          style: { color: '#FEA628', fontSize: '20px', fontWeight: 500 },
          marginLeft: 13,
        }}
        avatarProperty={{ size: 28, src: images.group.PostsIcon, shape: 'square' }}
      />

      <SpaceStyle padding="10.5px" />

      <FrameGroups>
        <FrameData
          onSeeMore={handleSeeMore}
          style={{ padding: '0' }}
          isShowSeeMore={groups?.total > listGroups?.length}
          textSeeMore={t('group.seeMoreGroup')}
        >
          <Row gutter={[20, 20]}>
            {listGroups?.map((item: IListGroupItem) => (
              <Col
                style={{ cursor: 'pointer' }}
                span={isMobile ? 24 : 8}
                onClick={() => navigateToGroup(undefined, item?.id)}
                key={item?.id}
              >
                <FrameGroupItem>
                  <GroupInfoItem {...item} />
                </FrameGroupItem>
              </Col>
            ))}
          </Row>
          <SpaceStyle padding={isMobile ? '34.5px' : '24px'} />
        </FrameData>
      </FrameGroups>
    </AllGroupSystemStyle>
  );
};

export default AllGroupSystem;
