import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { isMobile } from 'react-device-detect';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { sendPost } from 'api/axios';
import { useTranslation } from 'react-i18next';
import { Wrapper, TextError, generateFileName } from './uploadCropImageStyled';
import ModalNormal from '../modal';
import CropImageHEIC from './CropImageHEIC';
import useHEIC from './useHEIC';
import heic2any from 'heic2any';

const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

interface UploadProps {
  defaultUrl?: string;
  previewComponent?: any;
  onUploadSuccess?: (url: string, message?: string) => void;
  onUploadError?: (error: any, message?: string) => void;
  upDateErrorText?: (message: string | null) => void;
  onUploadManual?: (file: any) => void;
  rotate?: boolean;
  minZoom?: number;
  maxZoom?: number;
  aspect?: number;
  minWidth?: number;
  quality?: number;
  cropTitle?: string;
  disabled?: boolean;
  fillColor?: string;
}

const getCropWindowSize = () => {
  if (isMobile) {
    return window.innerWidth * 0.9;
  }
  const width = window.innerWidth * 0.7;
  if (width > 900) return 900;
  return width;
};

const UploadCropImage = (props: UploadProps) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.defaultUrl);
  const [errorText, setErrorText] = useState<string | null>(null);
  const { t } = useTranslation();
  const { isShowPopUpCropHEIC, setIsShowPopUpCropHEIC, urlHeic, setUrlHeic } = useHEIC();

  const handleChange = (info: any) => {
    if (props.disabled) return;
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url: any) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const validateFile = (file: any, callback: () => void) => {
    const _URL = window.URL || window.webkitURL;
    const objectUrl = _URL.createObjectURL(file);
    const img = new Image();
    img.src = objectUrl;
    img.onload = () => {
      const imageWidth = img.width;
      _URL.revokeObjectURL(objectUrl);
      img.remove();

      let isWarning = false;

      if (file.size / 1024 / 1024 >= 20) {
        setErrorText(t('createPost.uploadMax'));
        if (props.upDateErrorText) props.upDateErrorText(t('createPost.uploadMax'));
        return false;
      }

      if (props.minWidth) {
        if (imageWidth < props.minWidth) {
          setErrorText(t('common.imageTooSmall', { minWidth: props.minWidth }));
          if (props.upDateErrorText) props.upDateErrorText(t('common.imageTooSmall', { minWidth: props.minWidth }));
          isWarning = true;
        }
      }

      if (props.upDateErrorText && !isWarning) props.upDateErrorText(null);

      callback();
    };
  };

  const uploadFile = async (file: any) => {
    const fileStandard = new File([file], generateFileName(file?.name || 'aaaaa.jpg'));
    if (props.onUploadManual) {
      props.onUploadManual(fileStandard);
      return;
    }
    const data = new FormData();
    data.append('file', fileStandard);
    data.append('type', '2');
    try {
      await sendPost(`/upload-file`, data).then((res) => {
        setImageUrl(res.data.url_file);
        if (props.onUploadSuccess) props.onUploadSuccess(res.data.url_file);
      });
    } catch {
      setErrorText(t('editor.errorSystem'));
      if (props.upDateErrorText) props.upDateErrorText(t('editor.errorSystem'));
    }
  };

  const beforeUpload = async (file: any) => {
    if (props.disabled) return;
    validateFile(file, () => uploadFile(file));
  };

  const checkFormatBeforeCrop = (file: any) => {
    const fileExtension: string = file.name.split('.').pop();
    const checkHeicFile = 'heic' === fileExtension.toLowerCase();
    if (checkHeicFile) {
      heic2any({ blob: file, toType: 'image/jpg', quality: 1 }).then((newImage: any) => {
        const url = URL.createObjectURL(newImage);
        setUrlHeic(url);
        setIsShowPopUpCropHEIC(true);
      });
      return false;
    }

    const isValidType = /(png|jpg|jpeg|heic|jfif)+$/.test(file.type);
    if (!isValidType) {
      setErrorText(t('createPost.isValidTypeUpload'));
      if (props.upDateErrorText) props.upDateErrorText(t('createPost.isValidTypeUpload'));
      return false;
    }

    return true;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('common.uploadImage')}
      </div>
    </div>
  );

  const defaultPreview = imageUrl ? (
    <img
      src={imageUrl}
      alt="avatar"
      style={{
        width: '100%',
      }}
    />
  ) : (
    uploadButton
  );

  return (
    <Wrapper id="uploadImage">
      <ImgCrop
        cropperProps={{ restrictPosition: false }}
        rotate={props.rotate || false}
        minZoom={props.minZoom || 0.5}
        maxZoom={props.maxZoom || 3}
        aspect={props.aspect || 1}
        quality={props.quality || 0.7}
        modalWidth={getCropWindowSize()}
        modalTitle={props.cropTitle || t('common.cropImage')}
        beforeCrop={checkFormatBeforeCrop}
        fillColor={props?.fillColor || 'white'}
      >
        <Upload
          listType="picture-card"
          showUploadList={false}
          accept=".jpg,.jpeg,.png,.jfif,.heic"
          beforeUpload={beforeUpload}
          onChange={handleChange}
          disabled={props.disabled !== null && props.disabled !== undefined ? props.disabled : false}
        >
          {props.previewComponent || defaultPreview}
          {errorText && props.upDateErrorText === undefined ? <TextError>{errorText}</TextError> : null}
        </Upload>
      </ImgCrop>
      <ModalNormal
        visible={isShowPopUpCropHEIC}
        setVisible={setIsShowPopUpCropHEIC}
        title={t('common.cropImage')}
        width={getCropWindowSize()}
        padding="unset"
        preventCloseModal={true}
        onCancel={() => setIsShowPopUpCropHEIC(false)}
      >
        <CropImageHEIC
          urlHeic={urlHeic}
          minZoom={props.minZoom || 0.5}
          maxZoom={props.maxZoom || 3}
          aspect={props.aspect || 1}
          quality={props.quality || 0.7}
          beforeUpload={beforeUpload}
          onCancel={() => {
            setIsShowPopUpCropHEIC(false);
          }}
        />
      </ModalNormal>
    </Wrapper>
  );
};

export default UploadCropImage;
