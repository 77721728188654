import { Image } from 'antd';
import { colors } from 'common/styled-base';
import styled from 'styled-components';
const ImageErrorStyle = styled(Image)<any>`
  background-color: ${colors.grey3};
`;

const WrapperStyle = styled.div<any>`
  border-radius: ${(props) => props?.style?.borderRadius || '50%'};
  padding: ${(props) => props?.style?.padding || '0'};
  width: ${(props) => (typeof props?.style?.width === 'number' ? `${props?.style?.width}px` : props?.style?.width)};
  height: ${(props) => (typeof props?.style?.height === 'number' ? `${props?.style?.height}px` : props?.style?.height)};
  .ant-image {
    padding: ${(props) => props?.padding || '0'};
    border-radius: ${(props) => props?.borderRadius || '50%'};
    width: 100%;
    height: 100%;
    img {
      border-radius: ${(props) => props?.borderRadius || '50%'};
      width: 100%;
      height: 100%;
    }
  }
`;

export { ImageErrorStyle, WrapperStyle };
