import { Col } from 'antd';
import styled from 'styled-components';

const HotTagWrapper = styled.div``;

export default HotTagWrapper;

interface IProp {
  fromHome?: boolean;
}
export const TextTag = styled.span<IProp>`
  color: #464646;
  font-size: ${(props) => (props?.fromHome ? '16px' : '14px')};
  font-weight: 400;
  border-radius: 5px;
`;

export const ListHashTagStyle = styled.div<IProp>`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div {
    margin-bottom: ${(props) => (props?.fromHome ? '0' : '4px')};
  }
  .cursor {
    cursor: pointer;
  }
  .tags {
    max-width: 270px;
    padding: ${(props) => (props?.fromHome ? '5px 10px 5px 10px' : '2px 10px 2px 10px')};
    border-radius: 5px !important;
    margin-right: 10px;
    color: #464646;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    :hover {
      background-color: #c4c4c4 !important;
    }
  }
  .ant-tag {
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    .checked {
      background-color: red;
    }
  }
  .empty-data {
    text-align: center;
    width: 100% !important;
    color: #909090;
  }
`;

export const TitleStyle = styled(Col)`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #223354;
  display: flex;
  align-items: center;
  span {
    margin-left: 13px;
  }
`;
