import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const HeaderWrapper = styled.div`
  width: ${isMobile ? '100%' : '745px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;
export const NotifiTitle = styled.div`
  font-size: ${isMobile ? '20px' : '32px'};
  font-weight: 500;
`;
export const DeleteAll = styled.div`
  color: #2c8ce4;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: #007aff;
  }
  :active {
    color: #fea628;
    font-size: 18px;
  }
`;
