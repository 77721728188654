import { IUser } from 'pages/DetailPost/interface';
export const checkIsTag = (text: string, listUser: IUser[]) => {
  const findUser = listUser?.find((userItem: IUser) => userItem.name === text?.replaceAll('-', ' ')?.replace('@', ''));
  if (text && text[0] === '@' && findUser) {
    return true;
  }
  return false;
};
export const getListUser = (text: string, listUser: IUser[]) => {
  let mystr = text;
  mystr = mystr?.substring(1);
  if (!mystr) {
    return [];
  }
  const array = mystr.split(' @');
  const returnArr = [];
  for (const a of array) {
    const obj: any = {};
    const value: any = a.substring(a.lastIndexOf('[') + 1, a.lastIndexOf(']'));
    const key = a.substring(a.lastIndexOf('(') + 1, a.lastIndexOf(')'));
    obj[`imageName`] = value;
    obj[`id`] = key;
    if (isNaN(Number(key)) === false)
      returnArr.push(obj);
  }
  return returnArr.map((element) => element?.id).filter((elementFilter) => elementFilter);
};
