import ListSuggestCategory from 'components/ListSuggestCategory';
import styled from 'styled-components';
import FilterPost from 'pages/Group/FilterPost/FilterPost';

export const FilterPostByCategoryStyle = styled.div`
  width: 100%;
`;

export const TopicFilterStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: ${(props) => (props.theme?.isMobile ? '30px' : '0')};
  cursor: pointer;
  span:first-child {
    margin-right: 10px;
    color: #909090;
  }
  .text-topic {
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: #223354 !important;
  }
  .home-main-content-amount {
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #909090;
    .ant-avatar {
      margin-right: 3px;
    }
  }
`;

export const CategoryFilterStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.theme?.isMobile ? '0' : '30px')};
`;

export const FilterButtonStyle = styled(FilterPost)`
  button {
    border: none !important;
    height: 100%;
    margin-right: 0;
  }
`;

export const ListSuggestCategoryStyle = styled(ListSuggestCategory)`
  max-width: unset;
  width: calc(100% - 50px);
  button {
    margin-right: 10px;
    color: #516487;
    outline: none;
    border: 1px solid #c9d5eb;
    :hover {
      color: #516487;
      border: 1px solid #c9d5eb;
    }
    :focus {
      color: #516487;
      border: 1px solid #c9d5eb;
    }
  }
  .active-button {
    background-color: #223354;
    color: white;
    :focus,
    :hover {
      color: white !important;
    }
  }
`;
