import { images } from 'assets/images-base';
import { colors } from 'common/styled-base';
import devicesBreakpoint from 'helper/devices-breakpoint';
import styled from 'styled-components';

const StyledUpload = styled.div`
  .upload-error {
    color: red;
    position: absolute;
    left: 2%;
  }
  .upload-error-avatar {
    color: red;
    position: absolute;
    width: fit-content;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .cover-img {
    .ant-form-item-explain-error {
      margin-left: 2%;
    }
  }
  .avatar-img {
    .ant-form-item-explain-error {
      margin-left: 38%;
    }
  }
  .label-input {
    position: relative;
    max-width: 166px;
    height: 166px;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: -10%;
    .icon-camera {
      background-image: url(${images.createGroup.cameraAvatar});
      background-repeat: no-repeat;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 14px;
      bottom: 9px;
    }
    .input-image {
      opacity: 0;
      width: 166px;
      height: 166px;
      position: absolute;
      cursor: pointer;
    }
  }
  .selected-image {
    width: 166px;
    height: 166px;
    background-image: ${(props) =>
      props?.theme?.avatarBg ? `url(${props.theme.avatarBg})` : `url(${images.createGroup.profileAvatar})`};
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: contain;
    border-radius: 100%;
    overflow: hidden;
  }
  .default-avatar {
    width: 166px;
    height: 166px;
    background-color: ${colors.bgInfo};
    background-image: url(${images.createGroup.profileAvatar});
    background-repeat: no-repeat;
    object-fit: contain;
    border-radius: 100%;
    overflow: hidden;
    background-position: center;
  }

  // banner
  .label-input-banner {
    height: 250px;
    background: ${colors.grey3};
    border: 2px dashed #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    .text-banner {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      color: ${colors.textCaption};
      position: absolute;
      width: 200px;
    }
    .icon-import {
      background-image: url(${images.createGroup.iconUploadBanner});
      width: 30px;
      height: 30px;
      position: absolute;
      background-repeat: no-repeat;
      top: 29%;
    }
    .input-image-banner {
      opacity: 0;
      width: 100%;
      height: 250px;
      position: absolute;
      cursor: pointer;
    }
  }
  .selected-image-banner {
    width: 944px;
    height: 250px;
    background-color: ${colors.bgInfo};
    background-image: ${(props) => (props?.theme?.coverBg ? `url(${props.theme.coverBg})` : null)} !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: contain;
  }
  .banner-title {
    margin-top: 30px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  @media ${devicesBreakpoint.DEVICE_BREAKPOINTS_MAX.tabletS_640} {
    .label-input {
      margin-left: 28%;
      margin-top: -20%;
      .icon-camera {
        margin-left: 33%;
        margin-top: 33%;
      }
    }
    .upload-error-avatar {
      left: 21%;
    }
    .upload-error {
      top: 60%;
      left: 22%;
    }
    .ant-form-item-with-help .ant-form-item-explain {
      z-index: 4900;
    }
    .avatar-img {
      .ant-form-item-explain-error {
        margin-left: 3%;
      }
    }
  }
`;

export const WrapperImageCoverCrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const PreviewCoverFake = styled.div`
  top: 0;
  left: 0;
  right: 0;
  height: 250px;
  position: absolute;
  cursor: pointer;
`;

export const WrapperImageAvatarCrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const PreviewAvatarFake = styled.div`
  top: 0;
  left: 0;
  right: 0;
  height: 166px;
  position: absolute;
  cursor: pointer;
`;

export default StyledUpload;
