import { sendGet } from 'api/axios';
import { ISearchCategory } from 'pages/Admin/interface';
import { useQuery } from 'react-query';

const useAllCategoryList = (params?: ISearchCategory) =>
  useQuery(
    ['get_all_category_user', params],
    async () => {
      const res = await sendGet('/category/all', params);
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export default useAllCategoryList;
