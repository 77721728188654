// @ts-ignore
import { Launcher } from 'react-chat-window';
import React, { useState } from 'react';
import images from 'assets/images-base';
import { getAnswers } from 'api/chatBot';
import { ChatBotWrapper, SuggestionWrapper, TextLoading } from './styled';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

type Message = {
  author: string;
  type: string;
  data: any;
};

type MessageList = {
  messages: Message[];
  newMessageCount?: number;
};

const Loading: React.FC = () => <TextLoading src={images.chatbot.TextLoading} />;

const messageLoading: Message = {
  author: 'them',
  type: 'text',
  data: { text: <Loading /> },
};

const ChatBot = () => {
  const { t } = useTranslation();
  const initialSuggestions = [t('chatbot.suggestion-1'), t('chatbot.suggestion-2')];

  const initialMessage: MessageList = {
    messages: [
      {
        author: 'them',
        type: 'text',
        data: { text: t('chatbot.welcome') },
      },
    ],
  };
  const [messageList, setMessageList] = useState<MessageList>(initialMessage);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  const onMessageWasSent = (message: Message) => {
    setSuggestions([]);

    const newMessage: Message = {
      author: message.author,
      type: message.type,
      data: {
        text: message.data.text.trim(),
      },
    };

    if (newMessage.data.text.length > 0) {
      setMessageList({
        ...messageList,
        messages: [...messageList.messages, newMessage, messageLoading],
      });

      getAnswers(message.data.text).then((response) => {
        setSuggestions(response?.data.suggest_question);
        const { answer, id } = response?.data;

        if (answer) {
          sentMessage(answer, message, id);
        } else {
          if (response?.data.suggest_question.length > 0)
            sentMessage(`${t('chatbot.noAnswer')}. ${t('chatbot.chooseSuggestion')}`, newMessage, id);
          else sentMessage(`${t('chatbot.noAnswer')}. ${t('chatbot.tryQuestion')}`, newMessage, id);
        }
      });
    }
  };

  const onFilesSelected = (fileList: any) => {
    const objectURL = window.URL.createObjectURL(fileList[0]);

    setMessageList({
      ...messageList,
      messages: [
        ...messageList.messages,
        {
          author: 'me',
          type: 'file',
          data: {
            url: objectURL,
            fileName: fileList[0].name,
          },
        },
      ],
    });
  };

  const sentMessage = (text: string, message: Message, id: string) => {
    if (text.trim().length > 0) {
      setMessageList({
        ...messageList,
        messages: [
          ...messageList.messages,
          message,
          {
            author: 'them',
            type: 'text',
            data: {
              text: (
                <>
                  {text.trim()}
                  {isNumeric(id) && (
                    <a
                      href={`https://wiki.amela.vn/home/detail-post/${id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="detail"
                    >
                      {t('chatbot.detail')}
                    </a>
                  )}
                </>
              ),
            },
          },
        ],
      });
    }
  };

  const handelClick = () => {
    setMessageList({ ...messageList, newMessageCount: 0 });
    setIsOpen((prev) => !prev);
    if (!isOpen && messageList.messages.length <= 1) setSuggestions(initialSuggestions);

    if (isOpen && messageList.messages.length <= 1) setSuggestions([]);
  };

  const handleChooseSuggestion = (suggestion: string) => {
    const messageSuggestion = { author: 'me', type: 'text', data: { text: suggestion } };
    onMessageWasSent(messageSuggestion);
  };

  return (
    <ChatBotWrapper suggestions={suggestions.length}>
      <Launcher
        agentProfile={{
          teamName: 'Wiki Chatbot',
          imageUrl: images.chatbot.ChatBotAva,
        }}
        onMessageWasSent={onMessageWasSent}
        onFilesSelected={onFilesSelected}
        messageList={messageList.messages}
        showEmoji={false}
        mute={false}
        handleClick={handelClick}
        isOpen={isOpen}
      />

      <SuggestionWrapper isOpen={isOpen}>
        {suggestions &&
          suggestions.map((suggestion, index) => {
            return (
              suggestion && (
                <Tag
                  key={index}
                  className="tag-suggestion"
                  color="blue"
                  onClick={() => handleChooseSuggestion(suggestion)}
                >
                  {suggestion}
                </Tag>
              )
            );
          })}
      </SuggestionWrapper>
    </ChatBotWrapper>
  );
};

export default ChatBot;
