import React, { useEffect } from 'react';
import images from 'assets/images-base';
import CheckBoxSingle from 'components/common/CheckBoxSingle';
import CategoryFilterWrapper, { IconOpenSub } from './categoryFilterHomeStyle';
import { useTranslation } from 'react-i18next';

const CategoryFilterHome = React.memo(
  ({
    dataFilter,
    setDataFilter,
    getSubCategory,
    disabled,
  }: {
    dataFilter: any;
    setDataFilter: any;
    getSubCategory: ({ idParent, callback }: { idParent: number; callback: (res: any) => void }) => void;
    disabled: boolean;
  }) => {
    const { t } = useTranslation();
    const onShowSub = (id: number, fillData: any) => {
      const dataFilterClone = fillData.map((element: any) => ({
        ...element,
        hiddenSub: element.id === id ? !element.hiddenSub : element.hiddenSub,
      }));
      setDataFilter(dataFilterClone);
    };

    const checkSubChecked = (dataFilterCheck: any) => {
      let count: number = 0;
      dataFilterCheck.forEach((element: any) => {
        if (element.isChecked) {
          count++;
        }
      });
      if (count > 0) return true;
      return false;
    };

    const onchangeChecked = (isChecked: boolean, idChecked: number) => {
      let dataFilterClone: any = [...dataFilter];

      dataFilterClone = dataFilterClone.map((element: any) => ({
        ...element,
        isChecked: element.id === idChecked ? isChecked : element.isChecked,
        subLabel:
          element.id === idChecked
            ? element?.subLabel?.map((subElement: any) => ({
                ...subElement,
                isChecked,
              }))
            : element?.subLabel,
      }));
      setDataFilter(dataFilterClone);
    };

    const onchangeSubChecked = (isChecked: boolean, idChecked: number, idSub: number) => {
      let dataFilterClone: any = [...dataFilter];
      const subCheckedActive: any = dataFilterClone
        .find((element: any) => element.id === idChecked)
        ?.subLabel.map((subElement: any) => ({
          ...subElement,
          isChecked: subElement?.id === idSub ? isChecked : subElement.isChecked,
        }));

      dataFilterClone = dataFilterClone.map((element: any) => {
        if (element.id === idChecked) {
          return {
            ...element,
            isChecked: checkSubChecked(subCheckedActive),
            subLabel: subCheckedActive,
          };
        }
        return element;
      });
      setDataFilter(dataFilterClone);
    };

    useEffect(() => {
      if (disabled) {
        const dataFilterClone = [...dataFilter].map((element: any) => ({
          ...element,
          hiddenSub: false,
        }));
        setDataFilter(dataFilterClone);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    return (
      <CategoryFilterWrapper>
        {dataFilter.map((element: any, index: number) => (
          <div className="checkbox-container" key={String(index)}>
            <div>
              <CheckBoxSingle
                className="checkbox"
                checked={element.isChecked}
                onChange={(e: any) => onchangeChecked(e?.target?.checked, element.id)}
                disabled={disabled}
              >
                {/* <span className="tooltip">{element.name}</span> */}
                {element.name}
              </CheckBoxSingle>
              {element?.sub_category_quantity > 0 && (
                <IconOpenSub
                  alt={t('home.openSub')}
                  src={disabled ? images.group.CaretUpDisable : images.group.CaretUpSearch}
                  className={`${element.hiddenSub ? 'title-active-btn' : 'title-un-active-btn'}`}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    if (element?.sub_category_quantity > 0 && !element?.subLabel) {
                      getSubCategory({
                        idParent: element.id,
                        callback: (res) => {
                          onShowSub(element.id, res);
                        },
                      });
                    } else {
                      onShowSub(element.id, dataFilter);
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
            {element.hiddenSub &&
              element?.subLabel?.map((subElement: any, indexSub: number) => (
                <div className="sub-checkbox-container" key={String(indexSub)}>
                  <CheckBoxSingle
                    className="sub-checkbox"
                    checked={subElement.isChecked}
                    onChange={(e: any) => onchangeSubChecked(e?.target?.checked, element.id, subElement.id)}
                    disabled={disabled}
                  >
                    {subElement.name}
                  </CheckBoxSingle>
                </div>
              ))}
          </div>
        ))}
      </CategoryFilterWrapper>
    );
  }
);

export default CategoryFilterHome;
