import { sendGet } from 'api/axiosNotifications';
import { ESystemId } from 'common';
import { useQuery } from 'react-query';

export interface INoticeParams {
  user_id: number;
  system_id: ESystemId.ME | ESystemId.BO | ESystemId.RECRUIT | ESystemId.WORKFLOW | ESystemId.WIKI | ESystemId.PROJECT;
  page: number;
  size: number;
}
export interface IResNotice {
  created_at: string;
  group_id: any | null;
  id: number;
  is_read: number;
  is_viewed: number;
  message: string;
  post_id: any | null;
  receiver: number;
  request_id: any | null;
  request_name: any | null;
  sender_avatar: string;
  sender_position: any | null;
  series_id: any | null;
  system_id: number;
  time_passed: string;
  type: number;
  updated_at: string;
  user_avatar: number;
  comment?: string;
  detail?: any | null;
  project_id: any | null;
}
const useNotice = (params: INoticeParams) =>
  useQuery(
    ['get_list_notification', params],
    async () => {
      return await sendGet('/notifications', params);
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export default useNotice;
