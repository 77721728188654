import { colors } from 'common/styled-base';
import styled from 'styled-components';

export const ImgComment = styled.img`
  max-width: 200px;
  height: auto;
  margin: 4 0px;
`;
const AnswerWrapper = styled.div`
  .answer-component-info-author {
    display: flex;
    align-items: center;
  }
  .answer-component-created-at {
    margin-left: 10px;
    .text-avatar {
      color: rgba(144, 144, 144, 1);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .answer-component-author {
    max-width: 270px;
    .text-avatar {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 32px;
      color: #2c8ce4;

      /* display: block; */
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tagStyle {
    color: ${colors.second4};
    font-weight: 400;
    cursor: pointer;
  }
  .mr-10 {
    margin-right: 3px;
  }
  .answer-component-content {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #464646;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    word-break: break-word;
  }
`;
export default AnswerWrapper;
