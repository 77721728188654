import { isMobile } from 'react-device-detect';
import Search from 'antd/lib/input/Search';
import AvatarText from 'components/AvatarText';
import styled from 'styled-components';
import { colors } from 'common/styled-base';
import images from 'assets/images-base';
const StyledGroupPage = styled.div`
  padding-top: 35px;
  background-color: white;
`;

const WrapperSidebar = styled.div``;

const BoxSearchStyle = styled.div`
  background-color: ${isMobile ? '#F3F3F4' : 'unset'};
  width: ${isMobile ? '100vw' : 'unset'};
  margin-left: ${isMobile ? '-20px' : 'unset'};
  margin-top: ${isMobile ? '-20px' : 'unset'};
  padding: ${isMobile ? '20px' : 'unset'};
`;

const TitleStyle = styled(AvatarText)``;

const InputSearchStyle = styled(Search)<any>`
  /* height: 44px; */
  width: ${isMobile ? '80%' : '100% '};
  .ant-input-wrapper {
    height: 100%;
    input {
      height: 44px;
      background: #fcfcfc;
      :focus {
        border: 1px solid #c4c4c4;
      }
      :hover {
        border: 1px solid #c4c4c4;
      }
    }
    button {
      height: 100%;
      border-radius: 0 5px 5px 0 !important;
      width: 54px;
      /* background-color: ${(props) => (props?.value ? colors.mainColor2 : '#c4c4c4')}; */
      svg {
        color: white;
      }
    }
  }
  .ant-input-search-button {
    background-color: ${(props) => (props?.value ? colors.mainColor2 : '#c4c4c4')};
    border: 1px solid ${(props) => (props?.value ? colors.mainColor2 : '#c4c4c4')};
    background-image: url(${images.table.search});
    background-repeat: no-repeat;
    background-position: center;
    svg {
      display: none;
    }
  }
  input {
    height: 100%;
    border-radius: 5px 0 0 5px;
    :focus,
    :hover {
      border: 1px solid #c4c4c4;
    }
  }
`;

const TabListStyle = styled.div<any>`
  background: ${(props) => (props?.isFocus ? ' #E8F0F9' : 'transparent')};
  border-radius: 5px;
  padding: ${isMobile ? '8px 0 5px 0' : '8px 0 8px 13px'};
  display: flex;
  align-items: center;
  flex-direction: ${isMobile ? 'column' : 'unset'};
  cursor: pointer;
  width: ${isMobile ? '49%' : '100%'};
`;

const FrameTabList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${!isMobile ? 'column' : 'unset'};
`;

const ImageTab = styled.img``;

const TextTab = styled.span`
  font-weight: 400;
  font-size: ${isMobile ? '15px' : '16px'};
  line-height: 25px;
  color: #464646;
`;

const FrameDisplay = styled.div<any>`
  display: ${(props) => (props?.display ? 'auto' : 'none')};
`;

export {
  WrapperSidebar,
  TitleStyle,
  InputSearchStyle,
  BoxSearchStyle,
  TabListStyle,
  ImageTab,
  TextTab,
  FrameDisplay,
  FrameTabList,
};

export default StyledGroupPage;
