import { colors } from 'common/styled-base';
import styled from 'styled-components';

export const AcmsSystemWrapper = styled.div`
  width: 600px;
  color: ${colors.second1};
  padding: 10px;
  height: 630px;
`;
export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 8px;
  border-bottom: 1px solid #e7e7e7;
`;
export const UserDesc = styled.div`
  margin-left: 10px;
  div:nth-child(1) {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
  }
  div:nth-child(2) {
    color: #8d8d8d;
    line-height: 18px;
  }
  div:nth-child(3) {
    color: #8d8d8d;
    line-height: 18px;
  }
`;
export const SystemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const SystemElementWrapper = styled.a<any>`
  cursor: pointer;
  width: 100%;
  display: flex;
  color: ${colors.second1};
  align-items: center;
  flex-direction: column;
  transition: all 0.15s linear;
  padding: 10px;
  border-radius: 20px;
  :hover {
    background-color: #e0e0e0;
    color: ${colors.second1};
  }
`;
export const WrapperElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 25%;
  padding: 8px;
  height: fit-content;
`;
export const SystemName = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
`;
export const SystemDesc = styled.div`
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
`;
