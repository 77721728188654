import { combineReducers } from '@reduxjs/toolkit';
import commentReducer from './comment-reducer';
import searchReducer from './search-reducer';
import authReducer from './auth-reducer';
import UserManageGroupReducer from './user-manage-group';
const rootReducer = combineReducers({
  comment: commentReducer,
  search: searchReducer,
  auth: authReducer,
  userManageGroup: UserManageGroupReducer,
});

export default rootReducer;
