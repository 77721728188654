/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Spin } from 'antd';
import { sendPut } from 'api/axiosNotifications';
import images from 'assets/images-base';
import { ESystemId, EViewNotice } from 'common';
import configs from 'config';
import { INoticeParams, IResNotice } from 'data/notification/useNotice';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Title } from '../System/SystemStyle';
import defaultConst from 'common/default';
import {
  AvatarDefault,
  HeightSpace,
  ListNotificationWrapper,
  Nodata,
  NoticeItem,
  RightNotice,
  SpaceNotRead,
  SpinWrapper,
} from './ListNotificationStyle';
import {
  getUrlNotificationToProject,
  getUrlNotificationToRecruit,
  getUrlNotificationToWiki,
} from 'helper/notification';

interface INoticeItem {
  senderAvatar?: string;
  click: (idItem: number) => void;
  isLoading: boolean;
  element: IResNotice;
  systemId: ESystemId.ME | ESystemId.BO | ESystemId.RECRUIT | ESystemId.WORKFLOW | ESystemId.WIKI | ESystemId.PROJECT;
}
const Item = ({ senderAvatar, click, isLoading, element, systemId }: INoticeItem) => {
  const { t } = useTranslation();

  const getUrl = (type: number, requestID: number) => {
    if (!type && !requestID) return null;
    switch (systemId) {
      case ESystemId.ME:
        return configs.DOMAIN_AMS_ME + '/timekeeping/time-sheets';
      case ESystemId.RECRUIT:
        const linkToRecruit = getUrlNotificationToRecruit(type, element);
        if (linkToRecruit) {
          return configs.DOMAIN_AMS_RECRUIT + linkToRecruit;
        }
        return null;
      case ESystemId.WORKFLOW:
        return configs.DOMAIN_AMS_WORKFLOW + '/request?request_id=' + requestID;
      case ESystemId.WIKI:
        return getUrlNotificationToWiki(type, element);
      case ESystemId.PROJECT:
        const linkToProject = getUrlNotificationToProject(type, element);
        return configs.DOMAIN_AMS_PROJECT + linkToProject;
      default:
        return null;
    }
  };

  return (
    <NoticeItem
      target="_blank"
      href={getUrl(element?.type, element?.request_id)}
      onClick={() => click(element?.id)}
      className={isLoading ? 'cursor-wait' : ''}
    >
      {element?.is_read === 0 ? <Avatar size={10} className="color-yellow" /> : <SpaceNotRead />}
      <Avatar
        size={50}
        src={senderAvatar}
        icon={
          <AvatarDefault
            src={element?.user_avatar ? images.profile.avatarDefault : defaultConst.SYSTEM_SELECT[systemId]?.avatar}
            alt={t('avatar')}
          />
        }
      />
      <RightNotice>
        <div>{element?.request_name || ''}</div>
        <div dangerouslySetInnerHTML={{ __html: element?.message }} />
        <div>{moment(element?.updated_at).format('hh:mm DD/MM/YYYY')}</div>
      </RightNotice>
    </NoticeItem>
  );
};

interface IListNotification {
  ListNotice: {
    list_noti: IResNotice[];
    countNotView: number;
    current_page: number;
    limit_from: number;
    limit_to: number;
    total: number;
    total_row: number;
  };
  paramsNotice: INoticeParams;
  listNoticeState: IResNotice[] | null;
  isLoading: boolean;
  setListNoticeState: (list: IResNotice[]) => void;
  setParamsNotice: (params: INoticeParams) => void;
}
const ListNotification = ({
  ListNotice,
  paramsNotice,
  isLoading,
  listNoticeState,
  setListNoticeState,
  setParamsNotice,
}: IListNotification) => {
  const { t } = useTranslation();
  const listInnerRef = useRef<any>();
  const userId = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.user_id;

  useEffect(() => {
    if (ListNotice && ListNotice?.list_noti?.length > 0) {
      const listNoticeClone = listNoticeState || [];
      setListNoticeState(listNoticeClone.concat(ListNotice.list_noti));
    }
  }, [ListNotice, setListNoticeState]);

  const noticeToday = useMemo(() => {
    return (
      listNoticeState?.filter(
        (element: IResNotice) => moment(element?.updated_at).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
      ) || []
    );
  }, [listNoticeState]);
  const noticeNotToday = useMemo(() => {
    return (
      listNoticeState?.filter(
        (element: IResNotice) => moment(element?.updated_at).format('DD/MM/YYYY') !== moment().format('DD/MM/YYYY')
      ) || []
    );
  }, [listNoticeState]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (
        scrollTop + clientHeight >= scrollHeight - 400 &&
        ListNotice &&
        !isLoading &&
        listNoticeState?.length !== ListNotice.total_row
      ) {
        setParamsNotice({
          ...paramsNotice,
          page: paramsNotice.page + 1,
        });
      }
    }
  };

  const { mutate: viewNotice, isLoading: isLoadingView } = useMutation(
    async (idNotice: number) => {
      return sendPut('/update-read', {
        id: idNotice,
        user_id: userId,
      });
    },
    {
      onSuccess: (_, params) => {
        if (!listNoticeState) {
          return;
        }
        const cloneList = [...listNoticeState];
        setListNoticeState(
          cloneList.map((element: IResNotice) => {
            return { ...element, is_read: element.id === params ? EViewNotice.VIEW : element.is_read };
          })
        );
      },
      onError: () => {
        /* TODO document why this method 'onError' is empty */
      },
    }
  );

  const checkNotice = (idNotice: number, isRead: number) => {
    if (isRead === EViewNotice.NOT_VIEW) {
      viewNotice(idNotice);
    }
  };

  return (
    <ListNotificationWrapper ref={listInnerRef} onScroll={onScroll}>
      {listNoticeState && !isLoading && listNoticeState?.length === 0 && <Nodata>{t('common.noData')}</Nodata>}
      {noticeToday?.length > 0 && <Title>{t('notification.today')}</Title>}
      {noticeToday?.map((element: IResNotice, index: number) => (
        <div key={String(index)}>
          <Item
            senderAvatar={element.sender_avatar}
            click={(e) => checkNotice(e, element.is_read)}
            isLoading={isLoadingView}
            element={element}
            systemId={paramsNotice.system_id}
          />
        </div>
      ))}
      {isLoading && (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )}
      <HeightSpace />
      {noticeNotToday?.length > 0 && <Title>{t('notification.old')}</Title>}
      {noticeNotToday?.map((element: IResNotice, index: number) => (
        <div key={String(index)}>
          <Item
            senderAvatar={element.sender_avatar}
            click={(e) => checkNotice(e, element.is_read)}
            isLoading={isLoadingView}
            element={element}
            systemId={paramsNotice.system_id}
          />
        </div>
      ))}
    </ListNotificationWrapper>
  );
};

export default ListNotification;
