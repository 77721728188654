import { Col } from 'antd';
import styled from 'styled-components';

const BodyModalSettingGroupStyle = styled.div`
  .ant-form-item {
    width: 100%;
  }
  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #223354;
    margin-bottom: 30px;
  }
  .area {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .title-rule {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #909090;
    margin-bottom: 20px;
  }
  .group-name {
    .l-design-widht {
      max-width: 100%;
    }
  }
  .field-introduce-group {
    .l-design-widht {
      max-width: 100%;
    }
    textarea {
      width: 100%;
      height: 150px;
    }
  }
  .field-rules-group {
    .mkeditor {
      textarea {
        height: 475px !important;
      }
    }
  }

  .button-action {
    .button-cancel {
      background: #fff6e9;
      border: 1px solid #fea628;
      border-radius: 5px;
      width: 100%;
      height: 45px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #fea628;
    }
    .button-submit {
      background: #fea628;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      width: 100%;
      height: 45px;
    }
  }

  @media (max-width: 991px) {
    padding: 0;
    .button-cancel-box {
      order: 1;
      margin-top: 20px;
    }
  }
`;

const TitleStyle = styled.div<any>`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #464646;
  width: ${(props) => props?.width || 'unset'};
  padding: ${(props) => (props?.padding ? props?.padding : 'unset')};
`;

const AddAdminStyle = styled(Col)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export { TitleStyle, AddAdminStyle };

export default BodyModalSettingGroupStyle;
