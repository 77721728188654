/* eslint-disable react-hooks/exhaustive-deps */
import images from 'assets/images-base';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FixButton,
  PaddingModalSearch,
  StyleAvatarSearch,
  StyleDivTitle,
  StyleMarginQuickSearch,
} from '../component/styled';

interface Props {
  title?: string;
  listApi?: any;
  className?: string;
  onclick?: any;
  active: number | null;
}
const ListQuickSearchGroups: React.FC<Props> = ({ title, listApi, className, active }) => {
  const navigation = useNavigate();
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        const findElement = listApi.find((_: any, index: number) => index + 1 === active);
        if (findElement && active) {
          navigation(`/group/classification/${findElement.id}`);
        }
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [active]);

  return (
    <PaddingModalSearch>
      <StyleDivTitle>{title}</StyleDivTitle>
      <div>
        {listApi?.map((e: any, index: number) => {
          return (
            <StyleMarginQuickSearch key={e.id}>
              <div className={`${active && active === index + 1 ? 'bg-gray' : ''}`}>
                {e.avatar_image == null ? (
                  <StyleAvatarSearch src={images.profile.avatarDefault} />
                ) : (
                  <StyleAvatarSearch src={e?.avatar_image || e?.avatar} alt={e?.name} />
                )}{' '}
                <FixButton
                  onClick={() => {
                    navigation(`/group/classification/${e.id}`);
                  }}
                  className={className}
                  type="text"
                >
                  {' '}
                  {e?.name || e?.title}{' '}
                </FixButton>
              </div>
            </StyleMarginQuickSearch>
          );
        })}
      </div>
    </PaddingModalSearch>
  );
};
export default ListQuickSearchGroups;
