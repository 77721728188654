import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  listHasTag: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    storeSetListHasTag: (state: any, { payload }: PayloadAction<any>) => {
      state.listHasTag = payload;
    },
  },
});

export const { storeSetListHasTag } = searchSlice.actions;

export default searchSlice.reducer;
