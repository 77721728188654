import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import AvatarTextWrapper, { AvatarStyle, TextAvatarStyle } from './styled';
import images from 'assets/images-base';

interface IAvatarStyle {
  backGround?: string;
  cursor?: string;
}
interface IAvatarProp {
  size?: number;
  shape?: 'circle' | 'square';
  dot?: boolean;
  onClickAvatar?: () => void;
  src?: string;
  marginLeft?: string | number;
  style?: IAvatarStyle;
}

interface ITextStyle {
  cursor?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  flex?: number;
}
interface ITextProp {
  content?: any;
  onClickText?: () => void;
  marginLeft?: number | string;
  style?: ITextStyle;
}

interface Props {
  className?: string;
  onError?: any;
  showAvatar?: boolean;
  defaultImage?: string;
  marginLeftTextAvatar?: number | string;
  showContent?: boolean;
  avatarProperty?: IAvatarProp;
  textProperty?: ITextProp;
}

const AvatarText: React.FC<Props> = ({
  className,
  showContent = true,
  showAvatar = true,
  defaultImage = `${images.profile.avatarDefault}`,
  avatarProperty,
  textProperty,
  ...props
}) => {
  const [img, setImg] = useState<string>(defaultImage);
  useEffect(() => {
    if (avatarProperty?.src) setImg(avatarProperty?.src);
    else setImg(defaultImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarProperty?.src]);
  return (
    <AvatarTextWrapper className={className || ''}>
      {showAvatar && (
        <AvatarStyle
          {...avatarProperty?.style}
          shape={avatarProperty?.shape || 'circle'}
          size={avatarProperty?.size || 20}
          src={
            !avatarProperty?.dot ? (
              <Image
                preview={false}
                src={img}
                onError={() => setImg(defaultImage)}
                onClick={avatarProperty?.onClickAvatar}
              />
            ) : (
              ''
            )
          }
          marginLeft={avatarProperty?.marginLeft}
        />
      )}
      {showContent && (
        <TextAvatarStyle
          className="text-avatar"
          onClick={(e: any) => {
            if (textProperty?.onClickText) {
              e.stopPropagation();
              e.preventDefault();
              textProperty?.onClickText();
            }
          }}
          marginLeft={textProperty?.marginLeft}
          {...textProperty?.style}
        >
          {textProperty?.content}
        </TextAvatarStyle>
      )}
    </AvatarTextWrapper>
  );
};

export default AvatarText;
