import styled from 'styled-components';
import { colors } from 'common/styled-base';

const InputWrapper = styled.div`
  article:focus-within {
    .input {
      &__field {
        outline: none;
        border: 1px solid ${colors.mainColor2};
      }
      &__fieldErr {
        outline: none;
        border: 1px solid ${colors.red100};
      }
    }
  }
  .must {
    color: red;
  }

  --color-light: white;
  --color-dark: #212121;
  --color-signal: ${colors.mainColor2};

  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);

  --size-bezel: 0.5rem;
  --size-radius: 4px;

  line-height: 1.4;

  font-family: 'Inter', sans-serif;
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
  /* padding: 0 calc(var(--size-bezel) * 3); */

  .l-design-widht {
    max-width: 753px;
  }
  .input {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    /* align-items: center; */

    /* &.input__field {
      bor
    } */

    &__label {
      position: absolute;
      left: 0;
      top: -5%;
      padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
      margin: calc(var(--size-bezel) * 0.75 + 4px) calc(var(--size-bezel) * 0.5);
      background: pink;
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: var(--color-background);
      transition: transform 120ms ease-in;
      line-height: 1.2;
      color: #909090;
      margin-left: 12px;
    }
    &__labelErr {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
      margin: calc(var(--size-bezel) * 0.75 + 2px) calc(var(--size-bezel) * 0.5);
      background: pink;
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: var(--color-background);
      transition: transform 120ms ease-in;
      line-height: 1.2;
      color: #909090;
    }
    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 1px solid ${colors.gray2};
      padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
      color: currentColor;
      background: transparent;
      border-radius: var(--size-radius);

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.5rem, -65%) scale(0.85);
          color: var(--color-accent);
        }
        & + .input__labelErr {
          transform: translate(0.5rem, -65%) scale(0.85);
          color: ${colors.red100};
        }
      }
    }
    &__fieldErr {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 1px solid red;
      padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
      color: currentColor;
      background: transparent;
      border-radius: var(--size-radius);

      &:focus,
      &:not(:placeholder-shown) {
        & + .input__label {
          transform: translate(0.5rem, -65%) scale(0.85);
          color: var(--color-accent);
        }
        & + .input__labelErr {
          transform: translate(0.5rem, -65%) scale(0.85);
          color: ${colors.red100};
        }
      }
    }
  }
  .error {
    font-size: 0.75rem;
    color: ${colors.red100};
    margin-top: 2px;
  }
`;

export const InputFullRowWrapper = styled(InputWrapper)<any>`
  opacity: ${(props) => (props?.disabled ? '0.6' : 'unset')};
  width: 100%;
  .l-design-widht {
    max-width: 100%;
  }
  background: transparent;
  label {
    background-color: white;
  }
`;

export default InputWrapper;
