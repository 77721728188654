/* eslint-disable react-hooks/exhaustive-deps */
import { images } from 'assets/images-base';
import ButtonSave from 'components/Cms/Save';
import GroupList from 'components/GroupList/GroupList';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import useGetListGroupJoin from 'data/GroupGeneral/useGetGroupJoin';
import useGetListGroupManage from 'data/GroupGeneral/useGetManageGroupList';
import { renderCountTab } from 'helper';
import useNavigateFunction from 'helper/navigate';
import CreateGroup from 'pages/CreateGroup';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
export interface IValues {
  id: number;
  name: string;
  avatar_image: string;
}

interface IProps {
  classNameSideBar?: string;
  person_id?: number;
  isShowCreateGroup?: boolean;
}

const SideBar: FunctionComponent<IProps> = ({ classNameSideBar, person_id, isShowCreateGroup = true }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [params, setParams] = useState({ page: 1, per_page: 7, person_id });

  const [loadingManage, setLoadingManage] = useState<any>([]);
  const [loadingJoin, setLoadingJoin] = useState<any>([]);
  const totalGroupRecord = useRef<{ manage: number; join: number }>({ manage: 0, join: 0 });

  const { data: listGroupManage } = useGetListGroupManage(params);
  const { data: listGroupJoin } = useGetListGroupJoin(params);

  useEffect(() => {
    if (person_id) {
      setParams({ ...params, person_id });
    }
  }, [person_id]);

  useEffect(() => {
    if (listGroupManage) {
      totalGroupRecord.current.manage = listGroupManage?.data?.total;
      setLoadingManage([...(listGroupManage?.data?.manage_groups || [])]);
    }
  }, [listGroupManage]);

  useEffect(() => {
    if (listGroupJoin) {
      totalGroupRecord.current.join = listGroupJoin?.data?.total;
      setLoadingJoin([...(listGroupJoin?.data?.joining_groups || [])]);
    }
  }, [listGroupJoin]);

  const handleShowModalAddGroup = () => {
    setOpenModal(true);
  };

  const handleScroll = () => {
    setParams({ ...params, per_page: params.per_page + 20 });
  };

  const handleScrollJ = () => {
    setParams({ ...params, per_page: params.per_page + 20 });
  };
  return (
    <StyledSideBar className={classNameSideBar}>
      {isShowCreateGroup && (
        <ButtonStyle>
          <ButtonSave
            type="submit"
            label={t('group.addGroup')}
            icon={images.plus}
            height={45}
            click={handleShowModalAddGroup}
            className="button-add"
          />
        </ButtonStyle>
      )}

      <CreateGroup openModal={openModal} setOpenModal={setOpenModal} />

      <SpaceStyle padding="5px 0" />

      {loadingJoin?.length === 0 && loadingManage?.length === 0 ? (
        <NoDataStyle>
          <span>{t('group.noDataGroupManage')}</span>
        </NoDataStyle>
      ) : (
        <>
          {loadingManage?.length > 0 && (
            <ListGroup
              title={t('group.groupManage')}
              totalRecord={totalGroupRecord.current.manage}
              data={loadingManage}
              onScroll={handleScroll}
            />
          )}

          {loadingJoin?.length > 0 && (
            <ListGroup
              title={t('group.groupJoin')}
              totalRecord={totalGroupRecord.current.join}
              data={loadingJoin}
              onScroll={handleScrollJ}
              isJoinGroup={true}
            />
          )}
        </>
      )}
    </StyledSideBar>
  );
};

export { SideBar };

interface IListGroup {
  totalRecord: number;
  data: IValues[];
  onScroll?: () => void;
  title?: string;
  isJoinGroup?: boolean;
}
export const ListGroup: React.FC<IListGroup> = (props) => {
  const { navigateToGroup } = useNavigateFunction();
  const { t } = useTranslation();

  const handleScroll = (e: any) => {
    const diffDistance = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;

    if (diffDistance >= -0.5 && diffDistance <= 0.5 && props?.totalRecord > props?.data?.length) {
      props?.onScroll && props.onScroll();
    }
  };

  return (
    <ListGroupStyle>
      <GroupStyle>
        <TextStyle>{props?.title + renderCountTab(props?.totalRecord ?? 0)}</TextStyle>
      </GroupStyle>
      <GroupManageListStyle onScroll={handleScroll}>
        {props?.data?.length !== 0 ? (
          props?.data?.map((item: IValues) => (
            <div key={item?.id} onClick={() => navigateToGroup(undefined, item?.id)}>
              <GroupList
                id={item.id}
                name={item.name}
                avatar_image={item.avatar_image}
                isBorderYellow={!props?.isJoinGroup}
              />
            </div>
          ))
        ) : (
          <NoDataStyle>
            <span>{props?.isJoinGroup ? t('group.noDataGroupJoin') : t('group.noDataGroupManage')}</span>
          </NoDataStyle>
        )}
      </GroupManageListStyle>
    </ListGroupStyle>
  );
};

export const NoDataStyle = styled.div`
  background-image: url(${images.group.NoDataGroup});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 96px;
  display: flex;
  span {
    color: #909090;
    font-size: 12px;
    margin: auto auto 10px auto;
  }
`;
const ListGroupStyle = styled.div``;

const GroupStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const GroupManageListStyle = styled.div`
  max-height: 340px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #bdc3c7;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bdc3c7;
  }
`;

const StyledSideBar = styled.div`
  .join {
    padding-top: 10px;
  }
  .button-add {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const ButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const TextStyle = styled.div<any>`
  color: #223354;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;
