import React, { useEffect, useState } from 'react';
import BaseCheckBox from 'components/common/checkboxGroup';
import FilterWrapper from './filterRangeHomeStyle';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ESearchDetail } from 'common';

const FilterRangeHome = ({
  title,
  checkedList,
  typeTopic,
  setCheckedList,
  disabled,
}: {
  title: string;
  checkedList: CheckboxValueType[];
  typeTopic: CheckboxValueType[];
  setCheckedList: (checkList: CheckboxValueType[]) => void;
  disabled?: boolean;
}) => {
  const [isAll, setIsAll] = useState<CheckboxValueType[]>([ESearchDetail.ALL]);

  const onChange = (list: CheckboxValueType[]) => {
    if (list.length !== typeTopic.length) {
      setIsAll([]);
    } else {
      setIsAll([ESearchDetail.ALL]);
    }
    setCheckedList(list);
  };
  const onChangeAll = (Alls: CheckboxValueType[]) => {
    setIsAll(Alls);
    if (Alls.length > 0) {
      setCheckedList(typeTopic);
    } else {
      setCheckedList([]);
    }
  };

  useEffect(() => {
    if (checkedList.length === 0) {
      setIsAll([]);
    }
  }, [checkedList]);

  useEffect(() => {
    if (checkedList.length === typeTopic.length) {
      setIsAll([ESearchDetail.ALL]);
    } else {
      setIsAll([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  return (
    <FilterWrapper>
      <BaseCheckBox
        options={[ESearchDetail.ALL]}
        className="checkbox"
        value={isAll}
        onChange={onChangeAll}
        disabled={disabled}
      />
      <BaseCheckBox
        options={typeTopic}
        className="checkbox"
        value={checkedList}
        onChange={onChange}
        disabled={disabled}
      />
    </FilterWrapper>
  );
};

export default FilterRangeHome;
