import React, { useState } from 'react';
import { Radio } from 'antd';
import 'antd/dist/antd.css';
import { FixButton, CustomRow, PaddingModalSearch } from '../component/styled';

interface Props {
  title?: string;
  listSearch?: any;
  className?: string;
  changeHistorySearch?: any;
  notPaddingVertical?: boolean;
}
const ListButton: React.FC<Props> = ({ title, listSearch, className, changeHistorySearch, notPaddingVertical }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [placement, setPlacement] = useState();

  const placementChange = (e: any) => {
    changeHistorySearch(e.target.value);
  };
  return (
    <PaddingModalSearch notPaddingVertical={notPaddingVertical}>
      <CustomRow>
        <Radio.Group value={placement} onChange={placementChange}>
          {listSearch.map((e: any) => {
            return (
              <FixButton className={className} key={e.key} value={e.key}>
                {e.key}
              </FixButton>
            );
          })}
        </Radio.Group>
      </CustomRow>
    </PaddingModalSearch>
  );
};
export default ListButton;
