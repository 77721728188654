import { Button } from 'antd';
import images from 'assets/images-base';
import { colors } from 'common/styled-base';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IProps {
  showItem: boolean;
  title: string;
  handleShowItem: any;
}

const ItemFilter: FunctionComponent<IProps> = ({ showItem, title, handleShowItem }) => {
  return (
    <StyledItem>
      <div className="item">
        <span className="title">{title}</span>
        <Button
          icon={
            <img
              src={images.group.CaretUp}
              alt="dropdown"
              className={`dropdown ${!showItem ? '' : 'close-dropdown'}`}
            />
          }
          onClick={handleShowItem}
        />
      </div>
    </StyledItem>
  );
};
export default ItemFilter;

const StyledItem = styled.div`
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: ${colors.colorText};
  }

  .ant-btn {
    border: none;
    box-shadow: unset;
    margin-left: 5px;
    transition: unset;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: unset;
  }
`;
