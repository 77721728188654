import { colors } from 'common/styled-base';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const SystemWrapper = styled.div`
  padding: 13px 20px;
  width: ${isMobile ? '100%' : '270px'};
  background-color: ${colors.grey3};
`;
export const MobileWrapperSystem = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'row' : 'column'};
`;
export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
`;
export const SystemItem = styled.div<{ isSelect: boolean }>`
  background-color: ${(props) => (props.isSelect ? '#e8f0f9' : '')};
  cursor: pointer;
  padding: ${isMobile ? '0' : '12px 10px'};
  margin-right: ${isMobile ? '25px' : 'unset'};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  :hover {
    background-color: #e8f0f9;
    border-radius: 5px;
  }
`;
export const LeftItem = styled.div`
  display: flex;
`;
export const SystemRightAvatar = styled.div`
  margin-left: 10px;
  div:nth-child(1) {
    font-weight: 500;
  }
  div:nth-child(2) {
    color: #8d8d8d;
  }
`;
export const CountNotification = styled.div`
  color: white;
  background-color: #d83c2c;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;
export const MobileCount = styled.div`
  position: absolute;
  margin-left: 25px;
  margin-top: -5px;
  color: white;
  background-color: #d83c2c;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;
