import { sendGet } from 'api/axios';
import { useQuery } from 'react-query';

interface IProps {
  per_page: number;
  keyword: string;
}

const useSearchCategories = (onSuccess: any) =>
  useQuery(
    ['search_list_category'],
    async () => {
      const body = {
        per_page: 1000,
        page: 1,
        top: 1,
        group_id: null,
      };
      const res = await sendGet('/category/list', body);
      return res.data;
    },
    {
      onSuccess: onSuccess || null,
      staleTime: 0,
      cacheTime: 0,
    }
  );

export default useSearchCategories;
