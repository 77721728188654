import { Col } from 'antd';
import { images } from 'assets/images-base';
import styled from 'styled-components';

const TopPostGroupWrapper = styled.div`
  display: ${(props) => (props.theme.display ? 'none' : 'block')};
  background-image: url(${images.group.BGTopGroup});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 20px 0 0 15px;
  border-radius: 5px;

  .title-item {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
  }
`;

export const TitleStyle = styled(Col)`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  color: #223354;
`;

export const EmptyDataStyle = styled.div`
  text-align: center;
  width: 100% !important;
  color: #909090;
  font-size: 12px;
  padding: 5px 10px 0 0;
  font-size: 14px;
`;

export const PostTopItemStyle = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
`;

export const DividerStyle = styled.div`
  width: 100%;
  border: 1px dashed #e0e0e0;
  margin: 0;
`;

export const PostTopItemContentStyle = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0 15px 17px 0;
  .text-post-avatar-content {
    margin-top: 10px;
  }
  .text-avatar {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #2c8ce4;
    cursor: pointer;
  }
  .text-body {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #223354;

    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ranking {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8d8d8d;
    width: 30px;
    padding: 0 5px;
  }
  .ranking1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fea628;
  }
  .ranking2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #77a5ff;
  }
  .ranking3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #b67008;
  }
`;

export default TopPostGroupWrapper;
