import { sendGet } from 'api/axios';
import { useQuery } from 'react-query';

const useUserList = (listIdGroup?: number | null) =>
  useQuery(
    ['user_list', listIdGroup],
    async () => {
      const res = await sendGet('/user/list', { list_id: listIdGroup });
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export default useUserList;
