enum STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

enum ETypeGroup {
  PUBLIC = 1,
  PRIVATE,
}

enum EStatusGroup {
  INACTIVE = 0,
  ACTIVE,
}

enum ETypeCateGoryTop {
  NO_TOP = 0,
  TOP,
}

enum EOwnPost {
  INDIVIDUAL = 1,
  GROUP,
}

enum EStatusPost {
  INACTIVE = 0,
  ACTIVE,
}

enum EPermissionToPost {
  FREE = 1,
  APPROVE = 2,
}

enum ETypePost {
  DRAFT = 1,
  PUBLISH,
  WAIT_APPROVE,
  APPROVE,
  REJECT,
  REPORT,
}

enum EStatusSavePost {
  UNSAVE = 0,
  SAVED = 1,
}

enum EStatusPinPost {
  NOT_PIN = 0,
  PIN = 1,
}

enum EStateGroup {
  GUEST = 1,
  MEMBER = 2,
  ADMIN = 3,
  MEMBER_WAITING_APPROVE = 4,
}

const MAX_LENGTH_CATEGORY = 50;

enum ETopic {
  All = 0,
  REACT = 1,
  QUESTION = 2,
  NULL = -1,
  SERIES = 3,
}

enum ETopicString {
  REACT = 'Chia sẻ',
  QUESTION = 'Hỏi đáp',
}

enum EReactionType {
  LIKE = 1,
  HEART = 2,
  SMILING = 3,
  SOAKED = 4,
  SAD = 5,
  ANGRY = 6,
}
enum ETopCategory {
  FALSE = 0,
  TOP_GOLBAL = 1,
  TOP_IN_ALL_POSTS = 2,
  TOP_IN_ALL_GROUPS = 3,
  TOP_IN_GROUP = 4,
}

enum ETopHotTag {
  TOP_IN_ALL_POSTS = 2,
  TOP_IN_ALL_GROUPS = 3,
  TOP_IN_GROUP = 4,
}

enum EFollow {
  ALL = 2,
  FOLLOWING = 1,
  NOT_FOLLOWING = 0,
  NOT = -1,
}

enum EPinType {
  UN_PIN = 0,
  PIN_TIME = 1,
  PIN_NO_TIME = 2,
}

enum ESearchDetail {
  USER = 'Thành viên',
  GROUP = 'Nhóm',
  POST = 'Bài đăng',
  SERIES = 'Series',
  FOLLOWING = 'Đang theo dõi',
  NOT_FOLLOWING = 'Không theo dõi',
  SHARE = 'Chia sẻ',
  QUESTION = 'Hỏi đáp',
  ALL = 'Tất cả',
}

enum ETypeView {
  HOME = 1,
  GROUP = 2,
  DASH_BOARD = 3,
}

enum EKeyCode {
  DOWN = 40,
  ENTER = 13,
  UP = 38,
}

enum EMaxLength {
  SEARCH = 20,
}

enum EIsSeries {
  NO_DATA = -1,
  NOT_SERIES = 0,
  SERI = 1,
  SERI_OR_NOT = 2,
}
enum EProfile {
  OWN = 1,
}

enum EPermission {
  VIEW = 2,
  EDIT = 3,
  DELETE = 4,
}
enum EViewNotice {
  VIEW = 1,
  NOT_VIEW = 0,
}
enum ETypeLink {
  DETAIL_POST = 3,
  DETAIL_POST_GROUP = 4,
  DETAIL_REPORT = 5,
  SERIES = 6,
  GROUP_APPROVE_MEMBER = 7,
  GROUP_APPROVE_POST = 8,
  GROUP_LIST_REPORT = 9,
  DETAIL_COMMENT = 10,
}
enum ETypeLinkRecruit {
  DETAIL_APPLY_INTERVIEW_LEADER = 11,
  DETAIL_INTERVIEW_SCHEDULE = 12,
  LIST_INTERVIEW_SCHEDULE = 13,
  DETAIL_ORDER_LEADER = 14,
  DETAIL_JOB = 15,
  DETAIL_ORDER_HR = 16,
  DETAIL_APPLY_INTERVIEW_HR = 17,
  DETAIL_APPLY_TEST_HR = 18,
  NOT_REDIRECT = 20,
  LIST_KPI = 21,
  DETAIL_KPI = 22,
  LIST_REF_BONUS = 23,
  LIST_COST_RECRUIT = 24,
  DETAIL_CANDIDATE_HR = 25,
}
enum ETabGroup {
  NEW_FEED = 0,
  LIST_WAITING = 1,
  LIST_REPORT = 2,
  REQUEST_JOIN = 3,
}
enum EDefaultActiveAdmin {
  PAGE = '0',
}
enum ESystemId {
  ME = 1,
  BO = 2,
  RECRUIT = 3,
  WORKFLOW = 4,
  WIKI = 5,
  PROJECT = 6,
}
enum EComplete {
  CLOSED = 1,
  NOT_CLOSE = 0,
}

enum EHomeTab {
  QUESTION = '1',
  REACT = '2',
}

enum ESizeHome {
  WIDTH_CONTENT = '1140px',
}

enum EPreview {
  PREVIEW = 'preview',
}

enum ENotice {
  APP_API = '639795dc-2dbc-4061-b019-f174bffd78d8',
  SAFARI_WEB_ID = 'web.onesignal.auto.11512f5d-61af-48e1-99c6-cc09fe5cc2c2',
}

enum EIsSystemAdmin {
  NO = 0,
  YES = 1,
}

enum EDetailType {
  REPORT = 'report',
  APPROVE = 'approve',
}
enum ESearchTab {
  POST = '0',
  GROUP = '1',
  USER = '2',
  NULL = 'null',
}

enum ESeriesType {
  PARENT = 1,
  CHILD = 2,
}

const OVER_SCROLL = 10;

enum ESeriesFrom {
  EDIT = 1,
  DETAIL = 2,
}

enum EIsPublicPost {
  NO = 0,
  YES = 1,
}

enum EIsGroupMember {
  NO = 0,
  YES = 1,
}

enum ECreateType {
  SERIES = 2,
  NORMAL = 1,
}

enum ETypeUploadFile {
  ALL_FILE = '1',
  IMAGE_ONLY = '2',
}
enum EAdmin {
  SYSTEM_ADMIN = '1',
}

export {
  STATUS,
  MAX_LENGTH_CATEGORY,
  ETypeGroup,
  ETypeCateGoryTop,
  EStatusGroup,
  EOwnPost,
  EStatusPost,
  EPermissionToPost,
  ETypePost,
  EStatusSavePost,
  EStatusPinPost,
  EStateGroup,
  ETopic,
  ETopicString,
  EReactionType,
  ETopCategory,
  ETopHotTag,
  EFollow,
  EPinType,
  ESearchDetail,
  ETypeView,
  EKeyCode,
  EMaxLength,
  EIsSeries,
  EProfile,
  EPermission,
  EViewNotice,
  ETypeLink,
  ETypeLinkRecruit,
  ETabGroup,
  EDefaultActiveAdmin,
  ESystemId,
  EComplete,
  EHomeTab,
  ESizeHome,
  EPreview,
  ENotice,
  EIsSystemAdmin,
  EDetailType,
  ESearchTab,
  OVER_SCROLL,
  ESeriesType,
  ESeriesFrom,
  EIsPublicPost,
  EIsGroupMember,
  ECreateType,
  ETypeUploadFile,
  EAdmin,
};
