import { sendGet } from 'api/axios';
import { useQuery } from 'react-query';

interface IParams {
  per_page: number;
  page: number;
}

const useGetListGroupManage = (params?: IParams) =>
  useQuery(
    ['list group manage user', params],
    async () => {
      const res = await sendGet(`/group/list/manage`, params);
      return res;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export default useGetListGroupManage;
