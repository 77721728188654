import { listHashtag } from 'data/useCreatePost';
import React, { FunctionComponent, useRef, useState } from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import images from 'assets/images-base';
import { colorsTag } from './colorTag';

interface IValue {
  id: number;
  name: string;
}

interface IProps {
  form: any;
}

const HashTagList: FunctionComponent<IProps> = ({ form }) => {
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const randomColors = useRef<string[]>([]);
  const { data } = listHashtag({ per_page: 20, top: 3 });

  const handleClick = (id: number) => {
    if (selectedTags?.includes(id)) {
      selectedTags.splice(selectedTags.indexOf(id), 1);
    } else {
      selectedTags.push(id);
    }
    setSelectedTags([...selectedTags]);
    form.setFieldsValue({ hashtag: selectedTags });
  };

  return (
    <Styled className="tags">
      {data &&
        data?.hashtags.map((item: IValue, index: number) => {
          if (!randomColors.current[index]) {
            randomColors.current.push(colorsTag[index % colorsTag.length]);
          }
          return (
            <div className="" onClick={() => handleClick(item.id)}>
              <Tag className="" color={randomColors.current[index % colorsTag.length]} key={item.id}>
                <span>{item.name}</span>
                {selectedTags.includes(item.id) && (
                  <div className="checked">
                    <img src={images.group.groupTagChecked} alt="checked" />
                  </div>
                )}
              </Tag>
            </div>
          );
        })}
    </Styled>
  );
};

export default HashTagList;

const Styled = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .cursor {
    cursor: pointer;
  }
  .tags {
    margin-top: 30px;
    width: 100%;
    span {
      font-weight: 500;
    }
  }
  .ant-tag {
    border-radius: 15px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    .checked {
      background: currentColor;
      position: absolute;
      top: -8px;
      right: -3px;
      border-radius: 50%;
      width: 15.5px;
      height: 15.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 10.5px;
        height: 10.5px;
      }
    }
  }
`;
