import { sendGet } from 'api/axios';
import { useQuery } from 'react-query';

export interface IParamOtherPublicGroupList {
  per_page?: number;
  page?: number;
}

const useGetOtherPublicGroupList = (params: IParamOtherPublicGroupList) =>
  useQuery(
    ['get_other_public_group_list', params],
    async () => {
      const res = await sendGet('/group/list/other-public', params);
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: 7200000,
    }
  );

export default useGetOtherPublicGroupList;
