import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { DeleteAll, HeaderWrapper, NotifiTitle } from './HeaderStyle';
import SessionStorageUtil, { COMMON_KEY } from 'helper/SessionStorageUtil';
import { IResSystem } from 'data/notification/useListSystem';
import { sendPut } from 'api/axiosNotifications';

const Header = ({ listSystem }: { listSystem: IResSystem[] | undefined }) => {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const userId = SessionStorageUtil.getItemObject(COMMON_KEY.userInfo, {})?.user_id;

  const { mutate: viewSystemAll } = useMutation(
    async () => {
      return sendPut('/update-view', {
        system_id: null,
        user_id: userId,
      });
    },
    {
      onSuccess: async () => {
        QueryClient.refetchQueries(['get_list_system']);
      },
      onError: () => {
        /* TODO document why this method 'onError' is empty */
      },
    }
  );

  const checkSystem = () => {
    const find = listSystem?.find((element: IResSystem) => element.total);
    if (find) {
      viewSystemAll();
    }
  };

  return (
    <HeaderWrapper>
      <NotifiTitle>{t('notification.notice')}</NotifiTitle>
      <DeleteAll onClick={checkSystem}>{t('notification.seeAll')}</DeleteAll>
    </HeaderWrapper>
  );
};

export default Header;
