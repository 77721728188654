import React, { FunctionComponent } from 'react';
import { ButtonCancelWrapper } from './styled';

interface TableMasterProps {
  label?: string;
  click?: () => void;
  icon?: string;
  className?: string;
  width?: number | string;
  height?: number;
  noBg?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

const ButtonCancel: FunctionComponent<TableMasterProps> = ({
  label,
  click,
  icon,
  width,
  height,
  className,
  noBg,
  type = 'button',
}) => {
  return (
    <ButtonCancelWrapper
      width={String(width)}
      height={String(height)}
      className={className}
      onClick={click}
      noBg={noBg}
      type={type || 'button'}
    >
      <img src={icon ?? ''} alt="" />
      <span>{label}</span>
    </ButtonCancelWrapper>
  );
};

export default ButtonCancel;
