import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { CustomTabs, CustomTabPane } from 'pages/Admin/styled';
import { colors } from 'common/styled-base';
import images from 'assets/images-base';
interface Props {
  isMobile?: boolean;
}
export const UserDataDefault = {
  address: '',
  avatar: images.profile.avatarDefault,
  birthday: '',
  code: 'A1234',
  created_at: '',
  deleted_at: null,
  description: '',
  division_name: 'Bonne',
  email: 'abc@amela.com.vn',
  gender: 1,
  hr_permission: null,
  id: 1,
  is_division_leader: false,
  is_vice_leader: false,
  level_id: 1,
  marital: 0,
  name: 'name',
  phone: '0985 123 456',
  position_id: 0,
  position_name: 'position',
  provider_id: '',
  role: 0,
  social_insurance_code: '',
  special_timesheet_id: null,
  status: 1,
  tax_code: '',
  temp_address: '',
  updated_at: '',
  user_id: 1,
};

export const HeaderTabs = styled(CustomTabs)`
  font-size: 14px;
  height: inherit;
  width: fit-content;
  .ant-tabs-tab-btn {
    font-weight: 400;
    color: white;
  }
  .ant-tabs-tab {
    margin-left: 0px;
    font-size: 14px;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #f9f9f9;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: #434552;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 400;
    color: ${colors.mainColor2};
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${colors.mainColor2};
    pointer-events: none;
    height: 3px !important;
  }
  .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs-nav {
    margin: 0;
  }
`;

export const HeaderTabPane = styled(CustomTabPane)``;
export const TotalNotification = styled.div`
  color: white;
  background-color: #d83c2c;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: absolute;
  margin-top: -30px;
  margin-left: 29px;
`;

export const Space = styled.div`
  flex: 1;
`;

export const TabName = styled.div`
  width: 85px;
  text-align: center;
  font-weight: 500;
  font-size: 1em;
  line-height: 22px;
`;
export const IconLogout = styled.img`
  cursor: pointer;
  height: 18px;
  width: 21px;
  margin-left: 1.7em;
`;

const StyleHeader = styled.div<Props>`
  position: fixed;
  height: 55px;
  top: 0;
  &.home-header {
    z-index: 16;
  }
  width: 100vw;
  z-index: ${isMobile ? 5 : 4};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Roboto;
  background: #222433;
  align-items: center;
  padding: 0 24px 0 17px;
  .logo {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    cursor: pointer;
    .image {
      height: 31px;
      width: 31px;
    }
    .name-project {
      font-size: 25px;
      font-weight: 700;
      line-height: 32px;
      margin-right: 15px;
      margin-left: 6px;
    }
  }

  .list-item {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .input-search {
      cursor: pointer;
      margin-right: ${(props) => (props?.isMobile ? '0' : '20px')};
    }
    .item {
      cursor: pointer;
      margin-left: 1.357em;
      /* height: 24px;
      width: 24px; */
    }
    .ant-image {
      margin-left: ${(props) => (props?.isMobile ? '20px' : '0')};
    }
    .close-menu-mobile {
      margin-left: 20px;
      color: #fea628;
    }
  }
`;
export default StyleHeader;
