/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, FunctionComponent, SetStateAction, useRef } from 'react';
import MdEditor from 'react-markdown-editor-lite';
import EditorWrapper from './styled';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import { UserManual } from './UserManual';
import { sendPost } from 'api/axios';
import ModalUserManual from './ModalUserManual';
import { message } from 'antd';
import { formatUrl } from 'helper';
import { useTranslation } from 'react-i18next';
import { showConfirm } from 'helper/modal-confirm';
import AlignCenter from './CustomeTextAlign/AlignCenter';
import AlignLeft from './CustomeTextAlign/AlignLeft';
import AlignRight from './CustomeTextAlign/AlignRight';
import { formatText } from './CustomeTextAlign/formatText';
import heic2any from 'heic2any';
import { REGEX_ACCEPT_FILE_UPLOAD_EXCEPT_IMAGE, REGEX_ACCEPT_FILE_UPLOAD_IMAGE_ONLY } from 'helper/regex';
import { ETypeUploadFile } from 'common';
const insert = require('markdown-it-ins');

interface Props {
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  name?: string;
  maxFileUpload?: number;
  fieldName?: string;
  onChangeText?: any;
  height?: string | number;
  disabled?: boolean;
  onUploadImages?: (urls: string[], reason: string) => void;
  onRemoveImage?: (urls: string[]) => void;
  changeUploadingCount?: (value: number) => void;
}

MdEditor.use(UserManual);
MdEditor.use(AlignCenter);
MdEditor.use(AlignLeft);
MdEditor.use(AlignRight);

const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true })
  .use(insert)
  .use(require('markdown-it-center-text'));
const EditorNoFormValue: FunctionComponent<Props> = ({
  value = '',
  name = '',
  maxFileUpload = 3,
  fieldName = '',
  disabled,
  ...props
}) => {
  const div = useRef<HTMLDivElement>(document.createElement('div'));
  const counterFileUpload = useRef<number>(0);
  const refEditor = useRef<any>(null);
  const dropCurrentContent = useRef<string>('');

  const arrImageUrl = useRef<string[]>([]);

  const { t } = useTranslation();
  const changeUploadingCount = (value: number) => {
    if (props.changeUploadingCount) props.changeUploadingCount(value);
  };

  div.current.className = 'd-none';
  div.current.innerHTML = refEditor.current?.nodeMdPreview?.current?.props?.html;
  const plugins = [
    'code_block',
    'header',
    'link',
    'image',
    'font-italic',
    'strike-through',
    'block-quote',
    'font-bold',
    'font-underline',
    'block-code-inline',
    'list-unordered',
    'list-ordered',
    // 'user-manual',
    'logger',
    'mode-toggle',
    'align-left',
    'align-center',
    'align-right',
  ];

  const error = (msg: string) => {
    message.error(msg);
  };

  const uploadFile = async (file: any, typeUpload?: ETypeUploadFile) => {
    const typeUploadAfterFormat = typeUpload as string;
    const data = new FormData();
    data.append('file', file);
    data.append('type', typeUploadAfterFormat || '2');
    changeUploadingCount(1);
    const res = await sendPost(`/upload-file`, data);
    counterFileUpload.current += 1;
    arrImageUrl.current = [...arrImageUrl.current, formatUrl(res?.data?.url_file)];
    if (props.onUploadImages) props.onUploadImages(arrImageUrl.current, 'uploadNewFile');
    changeUploadingCount(-1);
    return formatUrl(res?.data?.url_file);
  };

  const uploadHeic = (file: any, resolve?: (value: any) => void) => {
    heic2any({ blob: file, toType: 'image/png', quality: 1 })
      .then(async (newImage: any) => {
        const fileOfBlob = new File([newImage], file.name + '.png');
        return uploadFile(fileOfBlob)
          .then((url: any) => {
            resolve && resolve({ url: url || '', text: '' });
            return url;
          })
          .catch(() => {
            error(t('editor.errorSystem'));
            changeUploadingCount(-1);
            return '';
          });
      })
      .catch(() => {
        message.error(t('createPost.errorUploadFile'));
      });
  };

  const uploadFileByType = async (
    file: any,
    typeFile: RegExp,
    typeUpload?: ETypeUploadFile,
    resolve?: (value: any) => void
  ) => {
    if (typeFile.test(file.type)) {
      return uploadFile(file, typeUpload)
        .then((url: any) => {
          resolve && resolve({ url: url || '', text: '' });
          return url;
        })
        .catch(() => {
          error(t('editor.errorSystem'));
          changeUploadingCount(-1);
          return '';
        });
    } else {
      message.error(t('createPost.errorUploadFile'));
      return '';
    }
  };

  const handleCustomUpload = (): Promise<{ url: string; text: '' }> => {
    return new Promise((resolve) => {
      if (disabled) return;
      const input = document.createElement('input');
      input.type = 'file';
      input.click();
      input.addEventListener('change', async (e: any) => {
        const file = e.target.files[0];

        if (file.size / 1024 / 1024 >= 20) {
          showConfirm({
            content: t('createPost.uploadMax'),
            hideCancel: true,
          });
          return;
        }

        if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
          uploadHeic(file, resolve);
          return;
        }

        uploadFileByType(file, /(png|jpg|jpeg|jfif)+$/, undefined, resolve);
      });
    });
  };

  function handleEditorChange(
    { html, text }: { html: string; text: string },
    event?: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    if (disabled) return;

    if (props.onChangeText) {
      props.onChangeText(text);
    }
    const newArrImageUrl = arrImageUrl.current.reduce((newArr: string[], url: string) => {
      if (text.indexOf(url) !== -1) return [...newArr, url];
      return newArr;
    }, []);

    arrImageUrl.current = newArrImageUrl;
    if (props.onUploadImages) props.onUploadImages(arrImageUrl.current, 'updateText');
  }

  const handleUploadDrop = async (file: any) => {
    if (disabled) return;

    if (file.size / 1024 / 1024 >= 20) {
      showConfirm({
        content: t('createPost.uploadMax'),
        hideCancel: true,
      });
      return;
    }
    if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
      return uploadHeic(file);
    }

    return uploadFileByType(file, /(png|jpg|jpeg|jfif)+$/);
  };

  const uploadDrag = async (file: any) => {
    if (disabled) return;

    if (file.size / 1024 / 1024 >= 20) {
      showConfirm({
        content: t('createPost.uploadMax'),
        hideCancel: true,
      });
      return;
    }

    if (REGEX_ACCEPT_FILE_UPLOAD_EXCEPT_IMAGE.test(file.type)) {
      const url = await uploadFile(file, ETypeUploadFile.ALL_FILE);
      if (url?.length > 0) {
        props?.onChangeText(dropCurrentContent.current + ' ' + url);
        dropCurrentContent.current = dropCurrentContent.current + ' ' + url;
      }
    } else if (REGEX_ACCEPT_FILE_UPLOAD_IMAGE_ONLY.test(file.type)) {
      const url = await handleUploadDrop(file);
      if (url && url?.length > 0) {
        const linkUrl = `![${file?.name}](${url})`;
        props?.onChangeText(dropCurrentContent.current + ' ' + linkUrl);
        dropCurrentContent.current = dropCurrentContent.current + ' ' + linkUrl;
      }

      // return;
    } else {
      message.error(t('createPost.errorUploadFile'));
    }
  };

  const dragFileToContent = (event: any) => {
    event?.preventDefault();
    dropCurrentContent.current = value;
    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach(async (item) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          uploadDrag(file);
        }
      });
    } else {
      [...event.dataTransfer.files].forEach(async (file) => {
        uploadDrag(file);
      });
    }
  };

  return (
    <>
      <ModalUserManual />
      <EditorWrapper
        height={props.height}
        disabled={disabled}
        onDrop={dragFileToContent}
        // className={counterFileUpload.current >= maxFileUpload ? 'disabled-upload' : ''}
      >
        <MdEditor
          ref={refEditor}
          {...props}
          config={{
            view: { html: false, menu: !disabled },
            canview: { html: false, menu: true },
          }}
          // onImageUpload={handleUploadDrop}
          allowPasteImage={true}
          onCustomImageUpload={handleCustomUpload}
          plugins={disabled ? [] : plugins}
          onChange={handleEditorChange}
          value={value}
          renderHTML={(text) => {
            const lastText = formatText(mdParser.render(text));
            return lastText;
          }}
          readOnly={disabled}
        />
      </EditorWrapper>
    </>
  );
};

export default EditorNoFormValue;
