import { Select } from 'antd';
import styled from 'styled-components';

interface ITreeSelectStyle {
  width?: string | number;
  height?: string | number;
}
const TreeSelectStyle = styled(Select)<ITreeSelectStyle>`
  border-radius: 5px;
  width: ${(props) => (typeof props?.width === 'number' ? `${props?.width}px` : props?.width)};
  height: ${(props) => (typeof props?.height === 'number' ? `${props?.height}px` : props?.height)};
  border-radius: 5px;
  .body-content {
    max-height: 300px !important;
  }
  .ant-select-selector {
    height: 100% !important;
    border-radius: 5px !important;
    .ant-select-selection-search {
      input {
        height: 100% !important;
      }
    }
    .ant-select-selection-placeholder {
      align-self: center;
    }
  }
  .ant-select-selection-item {
    align-self: center;
    display: block !important;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 400 !important;

    color: #464646 !important;
  }
`;

export const ListItemStyle = styled.div`
  padding: 0 2.5px;
`;
export const ParentListItemStyle = styled.div``;

export const ChildsListItemStyle = styled.div`
  margin: 0 16px 0 25px;
`;

interface OptionStyle {
  active?: boolean;
}
export const OptionItemStyle = styled.div<OptionStyle>`
  display: flex;
  align-items: center;
  padding: 9px 15px 9px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  &.active {
    background-color: #fff6e9;
    .content {
      .title {
        color: #fea628 !important;
      }
    }
  }
  .check-icon {
    width: 17px;
    height: 17px;
    color: #fea628;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: ${(props) => (props.active ? '8px' : 0)};
    flex: 1;
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #464646;
      flex: 1;

      align-self: center;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* số dòng hiển thị */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ant-image {
      transform: ${(props) => (props.active ? 'rotate(180deg)' : 'unset')};
      width: 14px;
      height: 14px;
    }
  }
`;

export default TreeSelectStyle;
