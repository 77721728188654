import { sendGet } from 'api/axios';
import useSearchCategories from 'data/Search/useListCategory';
import { useState } from 'react';
import { useMutation } from 'react-query';

function useFilterCategoryHome() {
  const [dataFilter, setDataFilter] = useState<any>([]);

  useSearchCategories((res: any) => {
    let listFilterCategory = res?.categories;
    listFilterCategory = listFilterCategory.map((cateElement: any) => {
      return {
        ...cateElement,
        hiddenSub: false,
        isChecked: false,
      };
    });
    setDataFilter(listFilterCategory);
  });
  const { mutate: getSubCategory } = useMutation(
    async ({ idParent, callback }: { idParent: number; callback: (res: any) => void }) => {
      return sendGet(`/category/${idParent}`);
    },
    {
      onSuccess: (res, params) => {
        const subResponse = res?.data?.sub_categories;
        const newFilterData = [...dataFilter].map((filterElement: any) => ({
          ...filterElement,
          subLabel:
            params?.idParent === filterElement.id
              ? subResponse.map((subElement: any) => ({ ...subElement, isChecked: filterElement.isChecked }))
              : filterElement?.subLabel || null,
        }));
        params.callback(newFilterData);
      },
      onError: () => {},
    }
  );

  const paramSetFilter = (listId: number[] | any) => {
    const listFilterCategory = [...dataFilter].map((cateElement: any) => {
      const find = listId?.find((element: number) => element === cateElement.id);
      const checkSub = listId?.find((element: number) =>
        cateElement?.subLabel?.find((subElement: any) => subElement.id === element)
      );
      return {
        ...cateElement,
        isChecked: checkSub || find ? true : false,
        subLabel: cateElement?.subLabel?.map((subElement: any) => {
          const findSub = listId?.find((element: number) => element === subElement.id);
          return {
            ...subElement,
            isChecked: findSub ? true : false,
          };
        }),
      };
    });
    setDataFilter(listFilterCategory);
  };
  const resetFilter = () => {
    const listFilterCategory = [...dataFilter].map((cateElement: any) => {
      return {
        ...cateElement,
        isChecked: false,
        subLabel: cateElement?.subLabel?.map((subElement: any) => {
          return {
            ...subElement,
            isChecked: false,
          };
        }),
      };
    });
    setDataFilter(listFilterCategory);
  };

  return { dataFilter, setDataFilter, getSubCategory, paramSetFilter, resetFilter };
}

export default useFilterCategoryHome;
