import { EFollow, ESearchDetail } from 'common';
import { findCheckbox } from 'helper/find-checkbox';
import styled from 'styled-components';
import { IPropsIsMobile } from 'pages/DetailPost/MenuSetting/EditPost/editPostStyle';

export const FilterContentWrapper = styled.div``;
export const Container = styled.div<IPropsIsMobile>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props?.isMobile ? 'column' : 'row')};
`;
export const Title = styled.div`
  color: #464646;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;
export const TopicWrapper = styled.div<IPropsIsMobile>`
  padding-right: ${(props) => (props?.isMobile ? 'unset' : '15px')};
  width: ${(props) => (props?.isMobile ? '100%' : '50%')};
`;
export const RangeWrapper = styled.div<IPropsIsMobile>`
  padding-left: ${(props) => (props?.isMobile ? 'unset' : '15px')};
  width: ${(props) => (props?.isMobile ? '100%' : '50%')};
`;
export const TopicFilterContainer = styled.div`
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 0 8px 8px 8px;
  height: 750px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 2px;
  }
`;
export const RangeContainer = styled.div``;
export const ButtonWrapper = styled.div<IPropsIsMobile>`
  margin-top: 16px;
  display: flex;
  flex-direction: ${(props) => (props?.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  .right-20 {
    width: ${(props) => (props?.isMobile ? '100%' : 'row')};
    margin-right: ${(props) => (props?.isMobile ? 'unset' : '12px')};
  }
  .left-20 {
    margin-top: ${(props) => (props?.isMobile ? '20px' : 'unset')};
    width: ${(props) => (props?.isMobile ? '100%' : 'row')};
    margin-left: ${(props) => (props?.isMobile ? 'unset' : '12px')};
  }
`;

export const getIdFilter = (dataFilter: any) => {
  if (dataFilter.length > 0) {
    const arr: number[] = [];
    dataFilter?.forEach((element: any) => {
      if (element?.isChecked && element?.id !== -1) {
        if (!element?.subLabel?.find((elementSub: any) => elementSub?.isChecked)) {
          arr.push(element.id);
        }
      }
      if (element?.subLabel && element?.subLabel.length) {
        element?.subLabel?.forEach((elementSub: any) => {
          if (elementSub.isChecked && elementSub?.id !== -1) {
            arr.push(elementSub.id);
          }
        });
      }
    });

    return arr?.length > 0 ? arr : [];
  }
  return [];
};

export const checkIsFollow = (rangeList: any) => {
  const arr: number[] = [];
  if (findCheckbox(ESearchDetail.FOLLOWING, rangeList)) {
    arr.push(EFollow.FOLLOWING);
  }
  if (findCheckbox(ESearchDetail.NOT_FOLLOWING, rangeList)) {
    arr.push(EFollow.NOT_FOLLOWING);
  }
  if (arr.length === 0) {
    return EFollow.NOT;
  }
  if (arr.length === 1) {
    return arr[0];
  }
  if (arr.length === 2) {
    return EFollow.ALL;
  }
};
export const paramsSetRange = (range: any) => {
  if (!range && range !== 0) {
    return [ESearchDetail.FOLLOWING, ESearchDetail.NOT_FOLLOWING];
  }
  if (range === -1) {
    return [];
  }
  if (range === 2) {
    return [ESearchDetail.FOLLOWING, ESearchDetail.NOT_FOLLOWING];
  } else {
    if (range === 0) {
      return [ESearchDetail.NOT_FOLLOWING];
    }
    return [ESearchDetail.FOLLOWING];
  }
};
