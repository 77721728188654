import { useTranslation } from 'react-i18next';
import {
  CountStyle,
  CreatedAtGroup,
  DotStyle,
  FlexBox,
  FrameCountGroup,
  FrameNameAndAvatar,
  GroupInfoItemStyle,
  NameGroupStyle,
  TextCount,
} from './allGroupSystemStyle';
import { AuthorAvatar, AvatarDefault } from 'pages/Home/TopRanking/AuthorRanking/AuthorRankingStyle';
import { IListGroupItem } from './type';
import React from 'react';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import { Avatar, Tooltip } from 'antd';
import images from 'assets/images-base';

const GroupInfoItem: React.FC<IListGroupItem> = (props) => {
  const { t } = useTranslation();

  return (
    <GroupInfoItemStyle>
      <FrameNameAndAvatar>
        <SpaceStyle width="3.33px" />
        <AuthorAvatar isTop={true} size={35.33}>
          <Avatar
            size={33.33}
            src={props?.avatar_image}
            icon={<AvatarDefault alt="" src={images.profile.avatarDefault} />}
          />
        </AuthorAvatar>
        <SpaceStyle padding="6.5px" />

        <Tooltip title={props?.name}>
          <NameGroupStyle>{props?.name}</NameGroupStyle>
        </Tooltip>
      </FrameNameAndAvatar>

      <SpaceStyle padding="5px" />

      <CreatedAtGroup onClick={(e: any) => e?.stopPropagation()}>
        {t('userManageGroup.banner.dateCreateGroup', { date: props?.created_at?.split(' ')[0] })}
      </CreatedAtGroup>

      <SpaceStyle padding="5px" />

      <FrameCountGroup onClick={(e: any) => e?.stopPropagation()}>
        <FlexBox>
          <TextCount>{t('userManageGroup.banner.post')}</TextCount>
          <SpaceStyle padding="2.5px" />
          <CountStyle>{props?.post_quantity}</CountStyle>
        </FlexBox>
        <SpaceStyle padding="5px" />

        <FlexBox>
          <DotStyle />
          <SpaceStyle padding="5px" />
          <TextCount>{t('userManageGroup.banner.member')}</TextCount>
          <SpaceStyle padding="2.5px" />
          <CountStyle>{props?.member_quantity}</CountStyle>
        </FlexBox>
      </FrameCountGroup>
    </GroupInfoItemStyle>
  );
};

export default GroupInfoItem;
