import { sendGet } from 'api/axios';
import images from 'assets/images-base';
import { EIsSeries, ESeriesType, ETopic, ETypeView } from 'common';
import Post from 'components/Post';
import TabCustom from 'components/TabCustom/TabCustom';
import { SeeMoreStyle } from 'components/userManageGroup/ListReport/Sibar/Hidden/hiddentSibarStyle';
import { EmptyDataStyle } from 'components/userManageGroup/NewFeed/Sibar/TopPostGroup/styled';
import { SpaceStyle } from 'components/userManageGroup/NewFeed/styled';
import { renderCountTab } from 'helper';
import useNavigateFunction from 'helper/navigate';
import moment from 'moment';
import FilterPostByCategory from 'pages/Home/Component/FilterPostByCategory/FilterPostByCategory';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IParamsPost } from 'types/interface';
import StyledGeneralGroup, { TabPostGroupStyle, TitleStyle } from './StyledGeneralGroup';

interface IGeneralGroup {
  onCalculatorTotalPost: (value: any, firstSet: boolean) => void;
  currentTab?: ETopic | null;
  onSetIsLoadingPost?: (value: boolean) => void;
  reCallApi?: boolean;
}
enum ETab {
  ALL = '0',
  QUESTION = '1',
  SHARE = '2',
  SERIES = '3',
}

const GeneralGroup: React.FC<IGeneralGroup> = ({
  onCalculatorTotalPost,
  currentTab,
  onSetIsLoadingPost,
  reCallApi,
}) => {
  const { t } = useTranslation();

  const setValueIsSeries = (topicId?: ETopic | null) => {
    if (!topicId || topicId === ETopic.REACT) return EIsSeries.SERI_OR_NOT;

    if (ETopic.QUESTION === topicId) return undefined;

    return EIsSeries.SERI;
  };

  const setValueSeriesType = (topicId?: ETopic | null) => {
    if (ETopic.QUESTION === topicId) return undefined;

    if (!topicId || topicId === ETopic.REACT) return ESeriesType.CHILD;

    return ESeriesType.PARENT;
  };

  const [params, setParams] = useState<IParamsPost>({
    page: 1,
    per_page: 14,
    type_view: ETypeView.GROUP,
    is_series: setValueIsSeries(currentTab),
    topic_id: currentTab === ETopic.SERIES ? null : currentTab,
    series_type: setValueSeriesType(currentTab),
  });

  const { navigateToPostDetail, navigateToProfile, navigateToHotCategory } = useNavigateFunction();

  const [listPosts, setListPost] = useState<any[]>([]);

  const totalPostREf = useRef<number>(0);
  const countCallApi = useRef<number>(0);

  const isUpdateData = useRef<boolean>(false);

  const { mutate: getListPost, isLoading } = useMutation(
    async (_params: IParamsPost) => {
      return sendGet(`/post/list`, {
        ..._params,
        start_date: _params?.start_date ? moment(_params?.start_date).format('YYYY-MM-DD') : null,
        end_date: _params?.end_date ? moment(_params?.end_date).format('YYYY-MM-DD') : null,
      });
    },
    {
      onSuccess: (res: any) => {
        const posts = res?.data;
        totalPostREf.current = posts?.post_list?.total;
        countCallApi.current = countCallApi.current + 1;

        if (isUpdateData.current) {
          setListPost(posts?.post_list?.posts);
          isUpdateData.current = false;
          return;
        }

        if (posts?.post_list) {
          setListPost((post) => {
            if ((params?.page || 0) > 1) return [...post, ...posts?.post_list?.posts];

            return posts?.post_list?.posts;
          });

          countCallApi.current > 1 && onCalculatorTotalPost(posts, false);
          countCallApi.current === 1 && onCalculatorTotalPost(posts, true);
          return;
        }
        setListPost([]);
      },
      onError: () => {},
    }
  );

  useEffect(() => {
    onSetIsLoadingPost && onSetIsLoadingPost(isLoading);
  });

  useEffect(() => {
    getListPost(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (countCallApi.current >= 1) {
      isUpdateData.current = true;
      getListPost({ ...params, page: 1, per_page: 14 * (params?.page || 1) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reCallApi]);

  const handleSeeMore = () => {
    setParams((param) => {
      return { ...param, page: (param?.page || 0) + 1 };
    });
  };

  return (
    <StyledGeneralGroup>
      <SpaceStyle padding="7px 0" />
      <FilterPostByCategory isSeries={currentTab === ETopic.SERIES} params={params} onSetParams={setParams} />

      <div className="group-posts">
        {listPosts.length === 0 ? (
          <EmptyDataStyle>{t('common.noPost')}</EmptyDataStyle>
        ) : (
          listPosts?.map((post: any) => {
            return (
              <Post
                key={post?.id}
                showPostPin={false}
                data={post}
                fallback={images.profile.avatarDefault}
                onClickTitle={() => navigateToPostDetail(post?.id, post?.group_id)}
                onClickUserName={() => navigateToProfile(post?.user?.id)}
                onClickCategory={(categoryId: number) => navigateToHotCategory(categoryId, undefined, 'group')}
              />
            );
          })
        )}
      </div>

      <div className="post-view-more">
        {totalPostREf.current > listPosts.length && (
          <SeeMoreStyle onClick={handleSeeMore}>{t('userManageGroup.newFeed.buttonSeeMore')}</SeeMoreStyle>
        )}
      </div>
    </StyledGeneralGroup>
  );
};

const TabPostGroup = () => {
  const { t } = useTranslation();
  const [countPost, setCountPost] = useState<{
    question: number;
    share: number;
    total: number;
    page: number;
    series: number;
  }>({
    question: 0,
    share: 0,
    total: 0,
    page: 1,
    series: 0,
  });

  const currentTopic = useRef<ETopic | null>(null);
  const [currentTab, setCurrentTab] = useState<ETab>(ETab.ALL);

  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(false);

  const countFilter = useRef<number>(0);

  const div = useRef<HTMLDivElement>(null);

  const [reCallApiPost, setReCallApiPost] = useState<{
    all: boolean;
    share: boolean;
    question: boolean;
    series: boolean;
  }>({
    all: false,
    share: false,
    question: false,
    series: false,
  });

  const handleChangeTab = (value: string) => {
    if (value === ETab.QUESTION && !isLoadingPost) {
      currentTopic.current = ETopic.QUESTION;
      setIsLoadingPost(true);
      setCurrentTab(ETab.QUESTION);
      setReCallApiPost({ ...reCallApiPost, question: !reCallApiPost.question });
      return;
    }
    if (value === ETab.SHARE && !isLoadingPost) {
      currentTopic.current = ETopic.REACT;
      setIsLoadingPost(true);
      setCurrentTab(ETab.SHARE);
      setReCallApiPost({ ...reCallApiPost, share: !reCallApiPost.share });
      return;
    }
    if (value === ETab.ALL && !isLoadingPost) {
      currentTopic.current = null;
      setIsLoadingPost(true);
      setCurrentTab(ETab.ALL);
      setReCallApiPost({ ...reCallApiPost, all: !reCallApiPost.all });
      return;
    }

    currentTopic.current = ETopic.SERIES;
    setIsLoadingPost(true);
    setCurrentTab(ETab.SERIES);
    setReCallApiPost({ ...reCallApiPost, series: !reCallApiPost?.series });
  };

  const handleCalculatorTotalPost = (data: any, firstSet: boolean) => {
    const findPostQuestion = data?.topics.find((item: any) => item?.id === ETopic.QUESTION);
    const findPostShare = data?.topics.find((item: any) => item?.id === ETopic.REACT);

    const checkSetQuestion = firstSet
      ? currentTopic.current === ETopic.QUESTION || currentTopic.current === null
      : currentTopic.current === ETopic.QUESTION;

    const checkSetShare = firstSet
      ? currentTopic.current === ETopic.REACT || currentTopic.current === null
      : currentTopic.current === ETopic.REACT;

    const checkSetSeries = firstSet
      ? currentTopic.current === ETopic.SERIES || currentTopic.current === null
      : currentTopic.current === ETopic.SERIES;

    setCountPost((_data: any) => {
      return {
        ..._data,
        question: findPostQuestion && checkSetQuestion ? findPostQuestion?.post_quantity : _data?.question,
        share: findPostShare && checkSetShare ? findPostShare?.post_quantity : _data?.share,
        total: currentTopic.current === null ? data?.post_list?.total : _data?.total,
        page: data?.post_list?.page,
        series: checkSetSeries || currentTopic.current === ETopic.SERIES ? data?.series : _data?.series,
      };
    });
  };

  const tabs = [
    {
      name: t('home.allPost') + renderCountTab(countPost.total),
      content: (
        <GeneralGroup
          onSetIsLoadingPost={setIsLoadingPost}
          currentTab={null}
          onCalculatorTotalPost={handleCalculatorTotalPost}
          reCallApi={reCallApiPost?.all}
        />
      ),
    },
    {
      name: t('home.question') + renderCountTab(countPost.question),
      content: (
        <GeneralGroup
          onSetIsLoadingPost={setIsLoadingPost}
          currentTab={ETopic.QUESTION}
          onCalculatorTotalPost={handleCalculatorTotalPost}
          reCallApi={reCallApiPost?.question}
        />
      ),
    },
    {
      name: t('home.share') + renderCountTab(countPost.share),
      content: (
        <GeneralGroup
          onSetIsLoadingPost={setIsLoadingPost}
          currentTab={ETopic.REACT}
          onCalculatorTotalPost={handleCalculatorTotalPost}
          reCallApi={reCallApiPost?.share}
        />
      ),
    },
    {
      name: 'Series' + renderCountTab(countPost.series),
      content: (
        <GeneralGroup
          onSetIsLoadingPost={setIsLoadingPost}
          currentTab={ETopic.SERIES}
          onCalculatorTotalPost={handleCalculatorTotalPost}
        />
      ),
    },
  ];

  useEffect(() => {
    if (div && !isLoadingPost) {
      if (countFilter.current > 1 && isMobile && countPost.page <= 1)
        div.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      countFilter.current = countFilter.current + 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPost]);

  return (
    <TabPostGroupStyle>
      <TitleStyle
        textProperty={{
          content: t('group.posts'),
          style: { color: '#FEA628', fontSize: '20px', fontWeight: 500 },
          marginLeft: 13,
        }}
        avatarProperty={{ size: 28, src: images.group.PostsIcon, shape: 'square' }}
      />
      <SpaceStyle padding={isMobile ? '10px 0' : '11px 0'} />
      <div ref={div}></div>
      <TabCustom tabs={tabs} onChange={handleChangeTab} activeKey={currentTab} />
    </TabPostGroupStyle>
  );
};

export default TabPostGroup;
