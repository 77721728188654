import { Modal } from 'antd';
import React from 'react';
const { confirm } = Modal;
interface Iprops {
  title?: string;
  width?: number;
  className?: string;
  onOk?: () => void;
  onCancel?: () => void;
  content?: string | React.ReactNode;
  hideCancel?: boolean;
}
export const showConfirm = ({ ...props }: Iprops) => {
  confirm({
    title: props.title,
    width: props.width || 378,
    className: `confirmCustom ${props.className} ${props.hideCancel ? ' alert-comfirm-modal' : ''}`,
    content: props.content,
    onOk() {
      if (props.onOk) props.onOk();
    },
    onCancel() {
      if (props.onCancel) props.onCancel();
    },
  });
};

export const successPopup = ({ ...props }: Iprops) => {
  confirm({
    title: props.title,
    width: '387px',
    content: props.content,
    className: `confirmCustom ${props.className}`,
    cancelButtonProps: { style: { display: 'none' } },
    onOk() {
      if (props.onOk) props.onOk();
    },
  });
};
